import { useEffect, useState } from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { AttachFile, Delete } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '../../1-primative';
import { Card, IconButton } from '../../2-component';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const FETCH_FILE_DOCUMENT_URL = gql`
query fetchFileDocumentDownloadUrl($fileDocumentId:ObjectID!) {
  fetchFileDocument(fileDocumentId: $fileDocumentId) {
    fileDocument {
      id
      downloadUrl
      fileName
    }
  }
}`;

export const DELETE_FILE_DOCUMENT = gql`
  mutation deleteFileDocument($fileDocumentId: ObjectID!) {
    deleteFileDocument(input: { fileDocumentId: $fileDocumentId }) {
      fileDocument {
        id
      }
    }
  }
`;

interface AttachmentProps {
  fileDocumentId: string;
  onDelete: () => void;
}

export function Attachment({ fileDocumentId, onDelete }: AttachmentProps) {
  const [doc, setDoc] = useState<any>({});
  const { data, loading } = useQuery(FETCH_FILE_DOCUMENT_URL, {
    variables: { fileDocumentId },
    fetchPolicy: 'no-cache',
    skip: !fileDocumentId,
  });

  const [fetchFileDocumentUrl] = useLazyQuery(FETCH_FILE_DOCUMENT_URL, {
    variables: { fileDocumentId },
    fetchPolicy: 'network-only',
    // we need setTimeout to open the url in Safari
    onCompleted: (dataDoc) => setTimeout(() => window.open(dataDoc?.fetchFileDocument?.fileDocument.downloadUrl || '', '_blank')),
  });
  const { sys } = useThemeTokens();

  useEffect(() => {
    if (data?.fetchFileDocument?.fileDocument) {
      setDoc(data.fetchFileDocument.fileDocument);
    }
  }, [data]);

  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT, {
    variables: { fileDocumentId },
    onCompleted: async () => {
      onDelete();
    },
  });

  return (
    <>
      {loading ? (
        <Skeleton variant='rectangular' width='100%' height='56px' sx={{ borderRadius: sys.borderRadius.md, mt: 1 }} />
      ) : (
        <Card sx={{ p: 1, mt: 1 }} hover onClick={fetchFileDocumentUrl}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box display='flex' alignItems='center'>
              <AttachFile />
              <Typography variant='bodySmall' ml={1}>{doc.fileName}</Typography>
            </Box>
            <IconButton onClick={(e: any) => {
              e.stopPropagation();
              deleteFileDocument();
            }}>
              <Delete />
            </IconButton>
          </Box>
        </Card>
      )}
    </>
  );
}
