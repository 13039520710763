import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { translateBackend } from 'assets/i18n/config';
import { debounce, round } from 'lodash';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { isNull, isUndefined } from 'lodash/fp';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, Box, SubAccountSelect, AmountField, TextField, SelectField, MenuItem, AccountTypeSelect,
  DateField, PageObjectType, RadioGroup, Stack, Radio,
} from '../../../..';
import { BankAccountSelectField } from '../../../../3-pattern/bankAccountSelectField/bankAccountSelect';
import { BankSelectField } from '../../../../3-pattern/bankSelectField/bankSelect';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { validateTransfer, ValidateTransferResponse } from '../../../../accountRestrictions';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { TransferErrorBanner } from '../../../../transferErrorBanner';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { DepositTransferContext } from '../../../depositWorkflow/depositWorkflow';
import { WorkflowContext } from '../../workflowCompletion';
import { updateContextTransfer } from '../../../../../util/updateWorkflowContext';
import { Alert } from '../../../../2-component/alert/alert';
import { addBusinessDays, isBusinessDayAndAllowWeekend } from '../../../../../util/businessDays';
import { AccountTypes } from '../../../../../interfaces';
import { FeatureFlagTypes } from '../../../../../interfaces/organizationFeatureFlag';
import { HoldingSelectField } from '../../../../3-pattern/holdingSelectField/holdingSelect';

const LOCKED_IN_ACCOUNTS = [AccountTypes.LIRA, AccountTypes.LRSP, AccountTypes.RLSP];
const INCOME_FUND_ACCOUNTS = [AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL, AccountTypes.LIF, AccountTypes.PRIF, AccountTypes.LRIF, AccountTypes.RLIF];
const DEFAULT_SUPPORT_URL = 'https://onevest.zendesk.com/hc/en-us/requests/new';

interface DepositAccountConfigInterface {
  [key: string]: { endOfPriorFiscalYear: Dayjs };
}

const iraEndOfPriorFiscalYear = dayjs().date(15).month(3).endOf('day');

export const depositAccountConfig: DepositAccountConfigInterface = {
  USA_INH_IRA: { endOfPriorFiscalYear: iraEndOfPriorFiscalYear },
  USA_INH_RT_IRA: { endOfPriorFiscalYear: iraEndOfPriorFiscalYear },
  USA_IRA: { endOfPriorFiscalYear: iraEndOfPriorFiscalYear },
  USA_RO_IRA: { endOfPriorFiscalYear: iraEndOfPriorFiscalYear },
  USA_RT_IRA: { endOfPriorFiscalYear: iraEndOfPriorFiscalYear },
  USA_SEP_IRA: { endOfPriorFiscalYear: iraEndOfPriorFiscalYear },
};

export const showFiscalYear = (accountType: string) => {
  const today = dayjs();
  const firstDayOfYear = dayjs().startOf('year');
  const config = depositAccountConfig[accountType];

  if (config) {
    return today.isBefore(config.endOfPriorFiscalYear) && today.isAfter(firstDayOfYear);
  }
  return false;
};

export const FETCH_TOTAL_TRANSFERS = gql`
  query fetchTransfers($input: FetchTransfersInput!) {
    fetchTransfers(input: $input) { totalCount }
  }
`;

export const FETCH_USER_LOCALIZATION = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user { id countryOfTaxResidence physicalAddress { country } }
    }
  }
`;

export const DepositForm = ({
  options, onNext, dummyDeposit, setDummyDeposit, stepLoading,
}: { options: any; onNext: any; dummyDeposit?: any; setDummyDeposit?: any, stepLoading?: boolean }) => {
  const { t } = useTranslation('workflowCompletions');
  const { sys } = useThemeTokens();
  const { userId: paramsUserId } = useParams();
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const { activeEntity, activeOrganization, userContext } = useContext(UserContext);
  const { transferData, setTransferData } = useContext(DepositTransferContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);

  const [focused, setFocused] = useState<string[]>([]);
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const [starting, setStarting] = useState<'now' | 'futureDate'>('now');
  const [totalTransfers, setTotalTransfers] = useState<number | null>(null);
  const [dateHasChanged, setDateHasChanged] = useState(false);
  const [validateErrorAmounts, setValidateErrorAmounts] = useState({ hasError: false, errorMessage: '' });

  /**
   * Note: In the Household view, particularly when navigating with "full navigation", the "userId" is derived from the selected subAccount,
   * as the "userId" is not available through the parameters or the activeEntity.
   */
  const userIdFromSubAccount = transferData?.subAccount?.account?.user?.id || workflowData.subAccount?.account?.user?.id;
  const userId = paramsUserId ?? activeEntity?.id ?? userIdFromSubAccount;

  const { data: localizationData } = useQuery(FETCH_USER_LOCALIZATION, { variables: { userId }, skip: !userId });
  const [fetchTotal] = useLazyQuery(FETCH_TOTAL_TRANSFERS);

  const enableInKind: boolean = activeOrganization.availableFeatureFlags?.includes(FeatureFlagTypes.IN_KIND_TRANSFERS) ?? false;
  const applicableData = dummyDeposit ?? (Object.keys(transferData).length !== 0 ? transferData : workflowData);
  const setApplicationContext = setDummyDeposit ?? (Object.keys(transferData).length !== 0 ? setTransferData : undefined);
  const setWorkflowCompletionContext = Object.keys(transferData).length === 0 ? setWorkflowData : undefined;

  const minRecurringDeposit = activeOrganization.minRecurringDepositCents;
  const minAmountDeposit = activeOrganization.minDepositCents;
  const minInitialDeposit = activeOrganization.minInitialDepositCents;

  const hyperLinkColor = sys.color.negativeOutline;
  const supportUrl = userContext.organization?.supportUrl || DEFAULT_SUPPORT_URL;
  const currentYear = dayjs().year();
  const buttonValidationAmountCents: boolean = transferData.amountCents === 0;

  const transferDate = applicableData?.scheduledDate;
  const delayDays = options?.transferDelay?.number;
  const transferCountry = applicableData?.bankAccount?.bankAccountTransitPhysicalAddress?.country
    || localizationData?.fetchUser?.user?.physicalAddress?.country
    || localizationData?.fetchUser?.user?.countryOfTaxResidence
    || activeOrganization.applicableLocalization.countries[0];

  const clearValidateErrorAmountsState = () => setValidateErrorAmounts({ hasError: false, errorMessage: '' });

  const handleTotalTransfers = (subAccount: any) => {
    fetchTotal({
      variables: { input: { filter: { subAccountId: subAccount.id } } },
      onCompleted: (data: any) => {
        setTotalTransfers(data.fetchTransfers?.totalCount);
        checkValidationErrors(data.fetchTransfers?.totalCount);
      },
    });
  };

  const isInitiatedByRepresentative = () => permissions?.includes('write:transfer_obo_clients') && !permissions?.includes('write:transfer_basic');

  const confirmTransferDelay = ({ scheduledDate, days, country }: { scheduledDate: Date; days: number; country: string }) => {
    const today = new Date();
    const minTransferDate = addBusinessDays(today, days, country);

    return {
      dateIsAfter: dayjs(scheduledDate).isSame(minTransferDate, 'day') || dayjs(scheduledDate).isAfter(minTransferDate),
      minTransferDate: dayjs(minTransferDate).format('MM/DD/YYYY'),
    };
  };

  const isEft = applicableData?.type === 'EFT';
  const isScheduled = starting !== 'now';
  const transferDelayCheck = confirmTransferDelay({ scheduledDate: transferDate, days: delayDays, country: transferCountry });
  const invalidTransferDelayCheck = (isEft && isScheduled && options?.transferDelay?.enabled
    && (!transferDelayCheck.dateIsAfter || !isBusinessDayAndAllowWeekend({ date: dayjs(transferDate).utc().toDate(), country: transferCountry })));

  const scheduledDateError = (!transferDate && options?.scheduledDate?.required !== 'NOT_REQUIRED')
    || !isBusinessDayAndAllowWeekend({ date: dayjs(transferDate).utc().toDate(), country: transferCountry })
    || (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter);

  const scheduledDateErrorMessage = () => {
    if ((options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter)) {
      const date = dayjs(transferDelayCheck.minTransferDate).format('MM/DD/YYYY');
      return t('bankAccountTransferCreation.transferDelayError', { date });
    }
    return t('bankAccountTransferCreation.enterAValidDate');
  };

  const checkValidationErrors = (transfersCount: number | null) => {
    if (applicableData?.type !== 'EFT') return;

    const isRecurringDeposit = applicableData?.schedule !== 'ONE_TIME';
    const isLessThanMinRecurringDeposit = isRecurringDeposit && !!minRecurringDeposit && applicableData?.amountCents < minRecurringDeposit;

    if (isLessThanMinRecurringDeposit) {
      const errorMessage = `${t('components:transferModal.minRecurringDepositAmount')} $${minRecurringDeposit / 100}`;
      setValidateErrorAmounts({ hasError: true, errorMessage });
      return;
    }

    if (isNull(transfersCount)) return;

    const isFirstDeposit = transfersCount === 0;
    const isLessThanMinInitialDeposit = !isRecurringDeposit && isFirstDeposit && !!minInitialDeposit && applicableData?.amountCents < minInitialDeposit;
    const isLessThanMinDeposit = !isRecurringDeposit && !isFirstDeposit && !!minAmountDeposit && applicableData?.amountCents < minAmountDeposit;

    if (isLessThanMinInitialDeposit) {
      const errorMessage = `${t('components:transferModal.minInitialDeposit')} $${minInitialDeposit / 100}`;
      setValidateErrorAmounts({ hasError: true, errorMessage });
      return;
    }

    if (isLessThanMinDeposit) {
      const errorMessage = `${t('components:transferModal.minAmountDeposit')} $${minAmountDeposit / 100}`;
      setValidateErrorAmounts({ hasError: true, errorMessage });
      return;
    }

    clearValidateErrorAmountsState();
  };

  useEffect(() => {
    if (isNull(totalTransfers) && applicableData?.subAccount?.id) {
      handleTotalTransfers(applicableData?.subAccount);
      return;
    }

    if (!isUndefined(applicableData?.amountCents)) {
      checkValidationErrors(totalTransfers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicableData?.amountCents]);

  const defaultScheduleOption = options?.editDefaultScheduleFrequency && options?.defaultScheduleOption ? options?.defaultScheduleOption : undefined;
  const isNotComingFromSubAccount = options?.subAccount?.enabled && applicableData?.objectType !== PageObjectType.SUB_ACCOUNT;
  const disabled = buttonValidationAmountCents || disableForm || validateErrorAmounts.hasError;

  const handleValue = (value: Record<string, any>) => updateContextTransfer({
    value: { ...applicableData, ...value },
    existingContext: applicableData,
    setApplicationContext,
    setWorkflowCompletionContext,
  });

  useEffect(() => {
    if (applicableData?.schedule !== 'ONE_TIME') {
      handleValue({ fiscalYear: undefined, schedule: applicableData?.schedule ?? defaultScheduleOption });
    }
    if (applicableData.schedule === 'ONE_TIME' && starting === 'now') {
      handleValue({ scheduledDate: undefined });
    }
    if (['EXTERNAL_TRANSFER', 'INTERNAL_TRANSFER'].includes(applicableData?.type)) {
      // removing validations scheduledDate, schedule from EXTERNAL_TRANSFER and INTERNAL_TRANSFER
      handleValue({ scheduledDate: dayjs().isBefore(dayjs(), 'day'), schedule: 'ONE_TIME' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicableData?.schedule, starting, options]);

  const submit = () => {
    const fields = invalidFields(
      {
        ...options,
        bankAccount: { ...options.bankAccount, requiredIf: (data: any) => data?.type === 'EFT' },
        fromSubAccount: { ...options.fromSubAccount, requiredIf: (data: any) => data?.type === 'INTERNAL_TRANSFER' },
        institution: { ...options.institution, requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER' },
        accountNumber: { ...options.accountNumber, requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER' },
        accountType: { ...options.accountType, requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER' },
        transferType: { ...options.transferType, requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER' },
        scheduledDate: { ...options.scheduledDate, requiredIf: (data: any) => data?.schedule !== 'ONE_TIME' || starting === 'futureDate' },
        ...(options.holdings ? { holdings: { ...options.holdings, requiredIf: (data: any) => data?.type === 'EFT' } } : {}),
      },
      applicableData,
    );

    if (fields.length > 0 || invalidTransferDelayCheck) {
      setFocused([...fields]);
      return;
    }
    const transferValidation: ValidateTransferResponse = validateTransfer({
      accountTo: applicableData?.subAccount?.account?.type ?? '',
      transferType: applicableData?.type === 'EFT' ? 'electronicFundsTransferIn' : applicableData?.type === 'EXTERNAL_TRANSFER' ? 'externalTransfer' : 'internalTransferTo',
      accountFrom: applicableData?.fromSubAccount?.account?.type,
      canOverrideTransferRestrictions: permissions.includes('write:override_transfer_restrictions'),
    });

    if (!transferValidation.isValid) {
      showToast({ message: transferValidation.message, severity: 'error' });
      return;
    }
    if (fields.length === 0) {
      onNext();
    }
  };

  const subAccountFilter = () => {
    switch (applicableData?.objectType) {
      case PageObjectType.INDIVIDUAL:
        return { userId: applicableData?.objectId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: applicableData?.objectId };
      case PageObjectType.ACCOUNT:
        return { accountId: applicableData?.objectId };
      case PageObjectType.GOAL:
        return { goalId: applicableData?.objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupId: applicableData?.objectId };
      default:
        return { userId };
    }
  };

  const handleEftValidation = (e: any) => {
    const isFormDisabled = [...LOCKED_IN_ACCOUNTS, ...INCOME_FUND_ACCOUNTS].includes(e.account.type) && applicableData?.type === 'EFT';
    setDisableForm(isFormDisabled);
  };

  const handleInternalTransferFromValidation = (e: any) => {
    const isFormDisabledForAccount = [...LOCKED_IN_ACCOUNTS, ...INCOME_FUND_ACCOUNTS].includes(e.account.type) && applicableData?.type === 'INTERNAL_TRANSFER';
    const isFormDisabledForSubAccount = [...LOCKED_IN_ACCOUNTS, ...INCOME_FUND_ACCOUNTS].includes(applicableData?.subAccount?.account?.type);

    const isFormDisabled = isFormDisabledForAccount || isFormDisabledForSubAccount;
    setDisableForm(isFormDisabled);
  };

  const handleInternalTransferToValidation = (e: any) => {
    const isFormDisabledForAccount = [...LOCKED_IN_ACCOUNTS, ...INCOME_FUND_ACCOUNTS].includes(e.account.type) && applicableData?.type === 'INTERNAL_TRANSFER';
    const isFormDisabledForSubAccount = [...LOCKED_IN_ACCOUNTS, ...INCOME_FUND_ACCOUNTS].includes(applicableData?.fromSubAccount?.account?.type);

    const isFormDisabled = isFormDisabledForAccount || isFormDisabledForSubAccount;
    setDisableForm(isFormDisabled);
  };

  const getErrorBanner = () => {
    if (LOCKED_IN_ACCOUNTS.includes(applicableData?.subAccount?.account?.type) && applicableData?.type === 'EFT') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositEftLockedInAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    if (INCOME_FUND_ACCOUNTS.includes(applicableData?.subAccount?.account?.type) && applicableData?.type === 'EFT') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositEftIncomeFundsAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    if ([...LOCKED_IN_ACCOUNTS, ...INCOME_FUND_ACCOUNTS].includes(applicableData?.fromSubAccount?.account?.type) && applicableData?.type === 'INTERNAL_TRANSFER') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositInternalTransferFromAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    if ([...LOCKED_IN_ACCOUNTS, ...INCOME_FUND_ACCOUNTS].includes(applicableData?.subAccount?.account?.type) && applicableData?.type === 'INTERNAL_TRANSFER') {
      return <TransferErrorBanner showHeader={false} sys={sys} htmlString={t('transfer:depositInternalTransferToAccountError', { color: hyperLinkColor, supportUrl })} />;
    }
    return <></>;
  };

  return (
    <Form>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>

      {applicableData?.type === 'EFT' && (
        <>
          {options?.subAccount?.enabled && applicableData?.objectType !== PageObjectType.SUB_ACCOUNT && (
            <SubAccountSelect
              omitAny
              sx={{ mb: 2 }}
              filter={subAccountFilter()}
              dataTestId='deposit-form-subaccount-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => {
                handleEftValidation(e);
                handleValue({ subAccount: e });
                handleTotalTransfers(e);
              }}
              selectedSubAccount={applicableData?.subAccount}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!applicableData?.subAccount?.id && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.bankAccount?.enabled && (
            <BankAccountSelectField
              userId={userId ?? ''}
              sx={{ mb: 2 }}
              disabled={!userId}
              setBankAccount={(e: any) => handleValue({ bankAccount: e })}
              bankAccount={applicableData?.bankAccount}
              onBlur={() => setFocused([...focused, 'bankAccount'])}
              error={!applicableData?.bankAccount?.id && focused.includes('bankAccount') && options?.bankAccount?.required !== 'NOT_REQUIRED'}
              options={options}
            />
          )}
          {options?.holdings?.enabled && (
            <HoldingSelectField
              userId={userId ?? ''}
              sx={{ mb: 2 }}
              disabled={!applicableData?.subAccount?.account?.id}
              label={translateBackend(options?.holdings?.label)}
              setAccountHolding={(e: any) => handleValue({ holdings: e })}
              onBlur={() => setFocused([...focused, 'holdings'])}
              selectedSubAccount={applicableData?.subAccount}
              selectedHolding={applicableData?.holdings}
              error={!applicableData?.holdings?.financialProduct?.cusip && focused.includes('holdings') && options?.holdings?.required !== 'NOT_REQUIRED'}
              options={options}
            />
          )}
          {options?.fiscalYear?.enabled && applicableData?.schedule === 'ONE_TIME' && showFiscalYear(applicableData?.subAccount?.account?.type ?? '') && (
            <SelectField
              fullWidth
              sx={{ mb: 2 }}
              testId='fiscal-year-select'
              value={applicableData?.fiscalYear}
              label={translateBackend(options?.fiscalYear?.label)}
              onChange={(e: any) => handleValue({ fiscalYear: e.target.value })}
            >
              <MenuItem data-testid='fiscal-year-select-last-year' value={currentYear - 1}>{currentYear - 1}</MenuItem>
              <MenuItem data-testid='fiscal-year-select-current-year' value={currentYear}>{currentYear}</MenuItem>
            </SelectField>
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              useFormatAmountValueCents
              sx={{ mb: 2 }}
              testId='deposit-form-amount-cents'
              label={translateBackend(options?.amountCents?.label)}
              setAmount={(e: any) => handleValue({ amountCents: round(parseFloat(e) * 100, 2) })}
              amount={applicableData?.amountCents || 0}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={
                (!applicableData?.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED') || (applicableData?.amountCents && validateErrorAmounts.hasError)
              }
              errorText={validateErrorAmounts.hasError ? validateErrorAmounts.errorMessage : undefined}
            />
          )}
          {options?.schedule?.enabled && (
            <SelectField
              fullWidth
              sx={{ mb: 2 }}
              testId='schedule-select'
              value={applicableData?.schedule ?? defaultScheduleOption}
              label={translateBackend(options?.schedule?.label)}
              onChange={(e: any) => handleValue({ schedule: e.target.value })}
              onBlur={() => setFocused([...focused, 'schedule'])}
              error={!applicableData?.schedule && focused.includes('schedule') && options?.schedule?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid='schedule-select-one-time' value='ONE_TIME'>{t('schedule.ONE_TIME')}</MenuItem>
              <MenuItem data-testid='schedule-select-weekly' value='WEEKLY'>{t('schedule.WEEKLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-bi-weekly' value='BI_WEEKLY'>{t('schedule.BI_WEEKLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-semi-monthly' value='SEMI_MONTHLY'>{t('schedule.SEMI_MONTHLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-monthly' value='MONTHLY'>{t('schedule.MONTHLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-quarterly' value='QUARTERLY'>{t('schedule.QUARTERLY')}</MenuItem>
            </SelectField>
          )}
          {options?.scheduledDate?.enabled && applicableData?.schedule !== 'ONE_TIME' && (
            <DateField
              fullWidth
              sx={{ mb: 2 }}
              dataTestId='scheduled-date'
              onChange={(date: any) => handleValue({ scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              onAccept={(date: any) => {
                setDateHasChanged(true);
                if (dayjs(date).isBefore(dayjs(), 'day')) {
                  showToast({ severity: 'error', message: t('bankAccountTransferCreation.enterAValidDate') });
                  handleValue({ scheduledDate: undefined });
                } else if (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter) {
                  handleValue({ scheduledDate: undefined });
                } else {
                  handleValue({ scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
                }
              }}
              label={translateBackend(options?.scheduledDate?.label)}
              minDate={options?.transferDelay?.enabled ? transferDelayCheck.minTransferDate : dayjs().add(1, 'day')}
              shouldDisableDate={(date: any) => !isBusinessDayAndAllowWeekend({ date: dayjs(date).utc().toDate(), country: transferCountry })}
              value={applicableData?.scheduledDate}
              error={scheduledDateError && (dateHasChanged || focused.includes('scheduledDate'))}
              errorText={scheduledDateErrorMessage()}
            />
          )}
          {(options?.allowOneTimeTransfersFutureDate || options?.allowOneTimeTransfersNow) && applicableData?.schedule === 'ONE_TIME' && (
            <RadioGroup testId='deposit-form-starting' label={t('components:transferModal.starting')} sx={{ pb: 0 }} value={starting} onChange={(e: any) => setStarting(e.target.value)}>
              <Stack direction={'row'}>
                {!isInitiatedByRepresentative() && options?.allowOneTimeTransfersNow && (
                  <Radio testId='deposit-form-starting-now' value='now' label={t('components:transferModal.startingOptions.now')} />
                )}
                {options?.allowOneTimeTransfersFutureDate && (
                  <Radio testId='deposit-form-starting-future' value='futureDate' label={t('components:transferModal.startingOptions.futureDate')} />
                )}
              </Stack>
            </RadioGroup>
          )}
          {starting === 'futureDate' && applicableData?.schedule === 'ONE_TIME' && (
            <DateField
              fullWidth
              sx={{ mb: 2 }}
              dataTestId='scheduled-date'
              onChange={(date: any) => handleValue({ scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              onAccept={(date: any) => {
                setDateHasChanged(true);
                if (dayjs(date).isBefore(dayjs(), 'day')) {
                  showToast({ severity: 'error', message: t('bankAccountTransferCreation.enterAValidDate') });
                  handleValue({ scheduledDate: undefined });
                } else if (options?.transferDelay?.enabled && !transferDelayCheck.dateIsAfter) {
                  handleValue({ scheduledDate: undefined });
                } else {
                  handleValue({ scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') });
                }
              }}
              label={translateBackend(options?.scheduledDate?.label)}
              minDate={options?.transferDelay?.enabled ? transferDelayCheck.minTransferDate : dayjs().add(1, 'day')}
              shouldDisableDate={(date: any) => !isBusinessDayAndAllowWeekend({ date: dayjs(date).utc().toDate(), country: transferCountry })}
              value={applicableData?.scheduledDate}
              error={scheduledDateError && (dateHasChanged || focused.includes('scheduledDate'))}
              errorText={scheduledDateErrorMessage()}
            />
          )}
        </>
      )}

      {options?.transferDelay?.enabled && ((applicableData.schedule === 'ONE_TIME' && starting !== 'now') || applicableData.schedule !== 'ONE_TIME') && (
        <Alert severity='info' data-testid='transfer-delay-alert'>{translateBackend(options?.transferDelayDescription)}</Alert>
      )}

      {applicableData?.type === 'INTERNAL_TRANSFER' && (
        <>
          {options?.fromSubAccount?.enabled && (
            <SubAccountSelect
              omitAny
              sx={{ mb: 2 }}
              userId={userId}
              dataTestId='deposit-form-from-sub-account-select'
              label={translateBackend(options?.fromSubAccount?.label)}
              // if this page is navigated from the subAccount page. The subAccount its coming from should have its
              // id assigned to the subAccountsToExclude, so it is not shown in the list of subAccount from, since it is
              // the subAccount money would be transferred into subAccountTo
              subAccountsToExclude={!isNotComingFromSubAccount ? [applicableData?.subAccount?.id] : []}
              onSubAccountSelect={(e: any) => {
                handleInternalTransferFromValidation(e);
                if (applicableData?.subAccount?.id === e.id) {
                  handleValue({ subAccount: undefined, fromSubAccount: e });
                } else {
                  handleValue({ fromSubAccount: e });
                }
              }}
              selectedSubAccount={applicableData?.fromSubAccount}
              onBlur={() => setFocused([...focused, 'fromSubAccount'])}
              error={!applicableData?.fromSubAccount && focused.includes('fromSubAccount') && options?.fromSubAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {isNotComingFromSubAccount && (
            <SubAccountSelect
              omitAny
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              dataTestId='deposit-form-sub-account-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => {
                handleInternalTransferToValidation(e);
                if (applicableData?.fromSubAccount?.id === e.id) {
                  handleValue({ fromSubAccount: undefined, subAccount: e });
                } else {
                  handleValue({ subAccount: e });
                }
              }}
              selectedSubAccount={applicableData?.subAccount}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={(!applicableData?.subAccount || Object.keys(applicableData.subAccount).length === 0) && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              useFormatAmountValueCents
              sx={{ mb: 2 }}
              testId='deposit-form-amount-cents'
              label={translateBackend(options?.amountCents?.label)}
              setAmount={(e: any) => handleValue({ amountCents: e ? round(parseFloat(e) * 100, 2) : undefined })}
              amount={applicableData?.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={!applicableData?.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED'}
            />
          )}
          {applicableData?.fromSubAccount?.statistics?.marketValueCents && applicableData?.amountCents > (applicableData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97 ? (
            <Typography variant='labelSmall' color='error' mt={1}>{t('depositReview.amountExceeds')}</Typography>
          ) : (
            <></>
          )}
        </>
      )}

      {applicableData?.type === 'EXTERNAL_TRANSFER' && (
        <>
          {options?.subAccount?.enabled && applicableData?.objectType !== PageObjectType.SUB_ACCOUNT && (
            <SubAccountSelect
              omitAny
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              dataTestId='deposit-form-sub-account-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => handleValue({ subAccount: e })}
              selectedSubAccount={applicableData?.subAccount}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={(!applicableData?.subAccount || Object.keys(applicableData?.subAccount).length === 0) && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.institution?.enabled && (
            <BankSelectField
              fullWidth
              sx={{ mb: 2 }}
              label={translateBackend(options?.institution?.label)}
              setInstitution={(e: any) => handleValue({ institution: e })}
              institution={applicableData?.institution}
              onBlur={() => setFocused([...focused, 'institution'])}
              error={(!applicableData?.institution || Object.keys(applicableData.institution).length === 0) && focused.includes('institution') && options?.institution?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.accountNumber?.enabled && (
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              testId='deposit-form-account-number'
              label={translateBackend(options?.accountNumber?.label)}
              onChange={(e: any) => handleValue({ accountNumber: e.target.value })}
              value={applicableData?.accountNumber}
              onBlur={() => setFocused([...focused, 'accountNumber'])}
              error={!applicableData?.accountNumber && focused.includes('accountNumber') && options?.accountNumber?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.accountType?.enabled && (
            <AccountTypeSelect
              size='medium'
              label={translateBackend(options?.accountType?.label)}
              onChange={(e: any) => handleValue({ accountType: e })}
              value={applicableData?.accountType}
              onBlur={() => setFocused([...focused, 'institution'])}
              error={!applicableData?.accountType && focused.includes('accountType') && options?.accountType?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.transferType?.enabled && (
            <SelectField
              fullWidth
              sx={{ mb: 2, mt: 2 }}
              testId='transfer-type-select'
              label={translateBackend(options?.transferType?.label)}
              onChange={(e: any) => handleValue({ transferType: e.target.value })}
              value={applicableData?.transferType}
              onBlur={() => setFocused([...focused, 'transferType'])}
              error={!applicableData?.transferType && focused.includes('transferType') && options?.transferType?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid='transfer-type-select-all-cash' value='ALL_IN_CASH'>{t('depositReview.ALL_IN_CASH')}</MenuItem>
              <MenuItem data-testid='transfer-type-select-partial-cash' value='PARTIAL_CASH'>{t('depositReview.PARTIAL_CASH')}</MenuItem>
              {enableInKind && <MenuItem data-testid='transfer-type-select-all-in-kind' value='ALL_IN_KIND'>{t('depositReview.ALL_IN_KIND')}</MenuItem>}
            </SelectField>
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              useFormatAmountValueCents
              testId='deposit-form-amount-cents'
              label={translateBackend(options?.amountCents?.label)}
              setAmount={(e: any) => handleValue({ amountCents: e ? round(parseFloat(e) * 100, 2) : undefined })}
              amount={applicableData?.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={!applicableData?.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED'}
            />
          )}
          {applicableData?.fromSubAccount?.statistics?.marketValueCents && applicableData?.amountCents > (applicableData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97 ? (
            <Typography variant='labelSmall' color='error' mt={1}>{t('depositReview.amountExceeds')}</Typography>
          ) : (
            <></>
          )}
        </>
      )}

      {getErrorBanner()}
      <Box display='flex' justifyContent='end'>
        <Button dataTestId='deposit-form-continue-button' label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} disabled={disabled} onClick={debounce(() => submit(), 250)}/>
      </Box>
    </Form>
  );
};

export default DepositForm;
