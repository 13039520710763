/* eslint-disable no-param-reassign */
import {
  Dispatch, ForwardedRef, forwardRef, SetStateAction, useEffect, useRef,
} from 'react';
import Quill, { QuillOptions } from 'quill';
import * as QuillTableUI from 'quill-table-ui';
import QuillImageResize from 'quill-resize-image';

Quill.register({ 'modules/tableUI': QuillTableUI.default }, true);
Quill.register('modules/imageResize', QuillImageResize);

export interface RichTextEditorArgs extends QuillOptions {
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  id?: string;
}

/**
 * Possible options for a Full Style Toolbar:
 *
 * const toolbarOptions = [
    [{ font: [] }, { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['table'],
    [{ script: 'super' }, { script: 'sub' }],
    [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['direction', { align: [] }],
    ['link', 'image', 'video', 'formula'],
    ['clean'],
  ];
 */
const RichTextEditor = forwardRef((props: RichTextEditorArgs, ref: ForwardedRef<any>) => {
  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['table'],
    [{ color: [] }, { background: [] }],
    [{ script: 'super' }, { script: 'sub' }],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }, { align: [] }],
    ['link', 'image'],
    ['clean'],
  ];

  const modules = {
    toolbar: toolbarOptions,
    table: true,
    tableUI: true,
    imageResize: true,
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const container: any = containerRef.current;
    const editorContainer = container?.appendChild(container?.ownerDocument.createElement('div'));
    const quill = new Quill(editorContainer, { theme: 'snow', modules, bounds: `#${props.id || 'richTextEditor'}` });

    if (ref) {
      (ref as any).current = quill;
    }

    if (props.value) {
      props.setValue(props.value);

      const delta = quill.clipboard.convert({ html: props.value });
      quill.setContents(delta, 'silent');
    }

    const handleTooltipLinkClick = () => {
      const tooltipLink = container.querySelector('.ql-tooltip > a.ql-preview');
      if (tooltipLink) {
        tooltipLink.setAttribute('target', '_blank');
      }
    };

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      props.setValue(quill.root.innerHTML);
      handleTooltipLinkClick();
    });

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      props.setValue(quill.root.innerHTML);
      handleTooltipLinkClick();
    });

    return () => {
      container.innerHTML = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={props.id ?? 'richTextEditor'} ref={containerRef}></div>;
});

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;
