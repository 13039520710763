import { useTranslation } from 'react-i18next';
import {
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Box, Grid } from '@mui/material';
import {
  Button, Form, GenericState, TextField, Typography,
} from 'ovComponents';
import { getBackendLanguage } from 'assets/i18n/config';
import { useMutation } from '@apollo/client';
import { ovAnalyticsEvents } from '../../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../../providers/analyticsProvider';
import { AssetClassBranchesSelect } from '../../../../../3-pattern/assetClassBranchesSelect/assetClassBranchesSelect';
import { UserContext } from '../../../../../../providers/userContextProvider';
import { AssetClassBranch, CreateModelPortfolioOptions } from '../createModelPortfolio';
import { CREATE_PORTFOLIO_TEMPLATE, UPDATE_PORTFOLIO_TEMPLATE, TRANSITION_PORTFOLIO_TEMPLATE } from '../queries';

interface PortfolioTemplate {
  id: string;
  name: string;
  translatedName: { [key: string]: string };
  translatedTemplateDescription: { [key: string]: string };
  assetClassBranches: AssetClassBranch[];
  state: GenericState;
  createdAt: string;
  updatedAt: string;
}

interface CreatePortfolioTemplateProps {
  options: CreateModelPortfolioOptions['createTemplate'];
  loading: boolean;
  workflowCompletion?: any;
  onComplete: (template: PortfolioTemplate) => void;
  onSaveForLater: (template: PortfolioTemplate) => void;
}

enum PortfolioTransition {
  activate = 'activate',
  draft = 'draft',
}

export const CreatePortfolioTemplate = ({
  options,
  loading,
  workflowCompletion,
  onComplete,
  onSaveForLater,
}: CreatePortfolioTemplateProps) => {
  const { t } = useTranslation(['shared', 'modelPortfolios']);
  const [focused, setFocused] = useState<string[]>([]);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const currentLanguage = getBackendLanguage();
  const { activeOrganization } = useContext(UserContext);
  const [portfolioTemplate, setPortfolioTemplate] = useState<PortfolioTemplate>({
    id: '',
    name: '',
    translatedName: { [currentLanguage]: '' },
    translatedTemplateDescription: { [currentLanguage]: '' },
    assetClassBranches: [
      {
        id: '',
        assetClass: {
          id: '',
          key: '',
          name: '',
        },
        targetPercentage: undefined,
        color: 'supportOne',
      },
    ],
    state: GenericState.DRAFT,
    createdAt: '',
    updatedAt: '',
  });

  const validateTemplate = useCallback(() => {
    const requiredFields: string[] = [];

    if (options.templateName.required === 'ERROR' && !portfolioTemplate?.name.trim()) {
      requiredFields.push('templateName');
    }

    if (options.templateDescription.required === 'ERROR' && !portfolioTemplate?.translatedTemplateDescription[currentLanguage]?.trim()) {
      requiredFields.push('templateDescription');
    }

    if (!portfolioTemplate?.assetClassBranches?.length) {
      requiredFields.push('assetClassBranches');
      return { isValid: false, requiredFields };
    }

    // Validate minimum number of branches
    if (portfolioTemplate.assetClassBranches.length < 1) {
      requiredFields.push('minimumBranches');
      return { isValid: false, requiredFields };
    }

    // Validate each branch has valid asset class and percentage
    const invalidBranches = portfolioTemplate.assetClassBranches.some((branch) => {
      const hasValidAssetClass = branch.assetClass?.id && branch.assetClass?.id.trim() !== '';
      const hasValidPercentage = typeof branch.targetPercentage === 'number'
        && branch.targetPercentage >= 0
        && branch.targetPercentage <= 100;
      return !hasValidAssetClass || !hasValidPercentage;
    });

    if (invalidBranches) {
      requiredFields.push('invalidBranches');
      return { isValid: false, requiredFields };
    }

    const assetClassIds = portfolioTemplate.assetClassBranches.map((branch) => branch.assetClass.id);
    const uniqueAssetClassIds = new Set(assetClassIds);
    if (assetClassIds.length !== uniqueAssetClassIds.size) {
      requiredFields.push('duplicateAssetClasses');
      return { isValid: false, requiredFields };
    }

    const totalPercentage = portfolioTemplate.assetClassBranches.reduce(
      (sum, branch) => sum + (branch.targetPercentage || 0),
      0,
    );
    if (totalPercentage !== 100) {
      requiredFields.push('totalPercentage');
      return { isValid: false, requiredFields };
    }

    return { isValid: requiredFields.length === 0, requiredFields };
  }, [options.templateName.required, options.templateDescription.required, portfolioTemplate, currentLanguage]);

  const [createPortfolioTemplate] = useMutation(CREATE_PORTFOLIO_TEMPLATE);
  const [updatePortfolioTemplate] = useMutation(UPDATE_PORTFOLIO_TEMPLATE);
  const [transitionPortfolioTemplate] = useMutation(TRANSITION_PORTFOLIO_TEMPLATE);

  const isValid = useMemo(() => validateTemplate().isValid, [validateTemplate]);

  const createAndUpdateTemplate = async (transition: PortfolioTransition) => {
    const createResult = await createPortfolioTemplate({
      variables: {
        input: {
          name: portfolioTemplate?.name,
          translatedName: {
            [currentLanguage]: portfolioTemplate?.translatedName[currentLanguage],
          },
          translatedTemplateDescription: {
            [currentLanguage]: portfolioTemplate?.translatedTemplateDescription[currentLanguage],
          },
          organizationId: activeOrganization?.id,
        },
      },
    });

    const templateId = createResult.data.createPortfolioTemplate.portfolioTemplate.id;

    await updatePortfolioTemplate({
      variables: {
        input: {
          portfolioTemplateId: templateId,
          assetClassBranches: portfolioTemplate?.assetClassBranches.map((branch) => ({
            targetPercentage: branch.targetPercentage || 0,
            assetClassId: branch.assetClass.id,
          })),
        },
      },
    });

    const transitionResult = await transitionPortfolioTemplate({
      variables: {
        input: {
          portfolioTemplateId: templateId,
          transition,
        },
      },
    });

    return transitionResult.data.transitionPortfolioTemplate.portfolioTemplate;
  };

  const submit = async () => {
    const validationResult = validateTemplate();
    if (!validationResult.isValid) {
      setFocused((prev: string[]) => [...prev, ...validationResult.requiredFields]);
      return;
    }

    try {
      sendAnalytic(ovAnalyticsEvents.workflowsCreateModelPortfolioContinueButtonSelect, {
        workflowStepTitle: options.label[currentLanguage],
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });

      const finalTemplate = await createAndUpdateTemplate(PortfolioTransition.activate);
      onComplete(finalTemplate);
    } catch (error) {
      // Error is handled in the parent component
    }
  };

  const handleSaveForLater = async () => {
    const validationResult = validateTemplate();
    if (!validationResult.isValid) {
      setFocused((prev: string[]) => [...prev, ...validationResult.requiredFields]);
      return;
    }

    try {
      const finalTemplate = await createAndUpdateTemplate(PortfolioTransition.activate);
      setPortfolioTemplate(finalTemplate);
      onSaveForLater(finalTemplate);
    } catch (error) {
      // Error is handled in the parent component
    }
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant="displayLarge" sx={{
        mt: 1,
        mb: 2,
        fontWeight: 400,
        fontSize: '40px',
        fontFamily: 'Tiempos Text',
      }}>
        {options.label[currentLanguage]}
        <Typography variant="bodyLarge" sx={{ mt: 1 }}>
          {options.description[currentLanguage]}
        </Typography>
      </Typography>

      <Grid container spacing={2}>
        {options.templateName.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="template-name"
              label={options.templateName.label[currentLanguage]}
              value={portfolioTemplate?.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPortfolioTemplate({
                ...portfolioTemplate,
                name: e.target.value || '',
                translatedName: { [currentLanguage]: e.target.value || '' },
              })}
              onBlur={() => setFocused([...focused, 'templateName'])}
              error={options.templateName.required === 'ERROR' && !portfolioTemplate?.name && focused.includes('templateName')}
              disabled={loading}
              fullWidth
              required={options.templateName.required === 'ERROR'}
            />
          </Grid>
        )}

        {options.templateDescription.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="template-description"
              label={options.templateDescription.label[currentLanguage]}
              value={portfolioTemplate?.translatedTemplateDescription[currentLanguage]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPortfolioTemplate({
                ...portfolioTemplate,
                translatedTemplateDescription: { [currentLanguage]: e.target.value || '' },
              })}
              onBlur={() => setFocused([...focused, 'templateDescription'])}
              disabled={loading}
              fullWidth
              multiline
              rows={3}
              required={options.templateDescription.required === 'ERROR'}
            />
          </Grid>
        )}

        {options.assetClassAllocationEditor.enabled && (
          <Grid item xs={12}>
            <AssetClassBranchesSelect
              assetClassBranches={portfolioTemplate?.assetClassBranches}
              setAssetClassBranches={(branches: AssetClassBranch[]) => setPortfolioTemplate({
                ...portfolioTemplate,
                assetClassBranches: branches,
              })}
            />
          </Grid>
        )}
      </Grid>

      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Button
          dataTestId="save-for-later-template-button"
          label={options.buttonSaveForLater.label[currentLanguage] || t('saveForLater')}
          onClick={handleSaveForLater}
          disabled={loading || !isValid}
          variant="tonal"
          sx={{ mt: 3 }}
        />
        <Button
          dataTestId="create-and-use-template-button"
          label={t('modelPortfolios:createAndUseTemplate')}
          disabled={loading || !isValid}
          sx={{ mt: 3, textAlign: 'center' }}
          type="submit"
        />
      </Box>
    </Form>
  );
};
