import {
  Add, Edit, RemoveCircleOutline, CheckCircle, ArrowForwardIosSharp,
} from '@mui/icons-material';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import { kebabCase } from 'lodash';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Typography } from '../../1-primative';
import { IconButton, Menu, MenuItem } from '../../2-component';
import { useSelectionTileTokens } from '../selectionTile/selectionTile.tokens';
import { ConvertAffiliate } from '../../4-module/workflowCompletion/subSteps/editAccount/components/convertAffiliate';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { OrganizationUserAccessTypes } from '../../../interfaces/organizationUser';
import { Affiliation } from '../../../interfaces';

type CompletionBoxState = 'todo' | 'complete' | 'error' | 'next';
type CompletionBoxType = 'checkbox' | 'radio' | 'tile' | 'completion';

export const CompletionBox = ({
  state,
  title,
  onRemove,
  onAdd,
  onEdit,
  children,
  variant = 'edit',
  edittable = true,
  selected,
  infoTooltip,
  type,
  testId,
  error,
  errorText,
  hideDelete = false,
  affiliate,
  options,
  accountData,
  refetch,
}: {
  state: CompletionBoxState;
  title: string;
  onRemove?: () => void;
  onAdd?: () => void;
  onEdit?: () => void;
  children?: any;
  variant?: 'edit' | 'view';
  edittable?: boolean;
  type?: CompletionBoxType;
  selected?: boolean;
  infoTooltip?: string;
  testId?: string;
  error?: boolean;
  errorText?: string;
  hideDelete?: boolean;
  options?: any;
  accountData?: any;
  refetch?: () => void;
  affiliate?: Affiliation,
}) => {
  const { comp, sys } = useThemeTokens(useSelectionTileTokens());
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openConvertAffiliate, setOpenConvertAffiliate] = useState(false);
  const { userContext } = useContext(UserContext);

  // Invite user menu will visible only for OrganizationUserAccessTypes.ORGANIZATION users
  const canCovertAffiliate = affiliate && permissions.includes('write:affiliate_conversion')
    && affiliate?.user?.affiliateOnly
    && userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION
    && options?.enableConvertedAffiiateOnboarding;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const borderColor = () => {
    if (error) return sys.color.negative;
    if (variant === 'view') return undefined;
    switch (state) {
      case 'todo':
        return sys.color.outlineVariant;
      case 'complete':
        return sys.color.positive;
      case 'error':
        return sys.color.negative;
      default:
        return sys.color.outlineVariant;
    }
  };
  const displayMenueOptions = accountData && affiliate && ['complete'].includes(state);
  return (
    <>
      <Box
        data-testid={testId ? `${testId}-${kebabCase(title)}` : 'completion-box'}
        display='flex'
        borderRadius={sys.borderRadius.xl}
        border={`1px solid ${borderColor()}`}
        pt={1}
        pl={3}
        pr={2}
        pb={2}
        flexDirection='column'
        mb={2}
        sx={{
          transition: 'background-color 0.3s ease-in-out',
          cursor: (state === 'todo' || type === 'completion') && variant === 'edit' ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: (state === 'todo' || type === 'completion') && variant === 'edit' ? sys.color.background : 'default',
          },
          background: selected ? comp.selectionTile.hoverBackgroundColor : sys.color.surface,
          boxShadow: variant === 'view' ? sys.elevation.surface : undefined,
        }}
        onClick={(state === 'todo' && variant === 'edit') || state === 'next' ? onAdd : undefined}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center' minHeight={40}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box display='flex' flexDirection='row' alignItems={'center'}>
              <Typography data-testid={testId ? `${testId}-title-${kebabCase(title)}` : `completion-box-title-${kebabCase(title)}`} variant='titleMedium' sx={{ mr: '4px' }}>
                {title}
                {infoTooltip && <InfoDialog information={infoTooltip} />}
              </Typography>
            </Box>
            {state === 'complete' && <CheckCircle sx={{ color: sys.color.positive, fontSize: '16px', ml: 0.5 }} />}
          </Box>
          <Box>
            {state === 'todo' && edittable && (
              <IconButton disableRipple={variant === 'edit'} onClick={variant === 'edit' ? undefined : onAdd} size='medium'>
                <Add data-testid={testId ? `${testId}-add-button` : 'add-button'} sx={{ color: sys.color.onSurfaceVariant }} />
              </IconButton>
            )}
            {!accountData && state === 'complete' && onEdit && edittable && (
              <IconButton onClick={onEdit} size='small' sx={{ height: '26px' }}>
                <Edit data-testid={testId ? `${testId}-edit-button` : 'edit-button'} sx={{ fontSize: '16px' }} />
              </IconButton>
            )}
            {!accountData && state === 'complete' && onRemove && edittable && !hideDelete && (
              <IconButton onClick={onRemove} size='small' sx={{ height: '26px' }}>
                <RemoveCircleOutline data-testid={testId ? `${testId}-remove-button` : 'remove-button'} sx={{ fontSize: '16px' }} />
              </IconButton>
            )}
            {displayMenueOptions && (
              <IconButton
                sx={{ marginLeft: 1 }}
                size='small'
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(e);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            {state === 'next' && <ArrowForwardIosSharp data-testid={testId ? `${testId}-next-button` : 'next-button'} sx={{ fontSize: '16px', color: sys.color.onSurfaceVariant }} />}
          </Box>
        </Box>
        {children}
      </Box>
      {error && (
        <Typography variant='labelSmall' sx={{ position: 'relative', top: '-10px' }} style={{ color: sys.color.negative }}>
          {errorText}
        </Typography>
      )}

      <Menu
        anchorEl={anchorEl}
        id='affiliate-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {displayMenueOptions && onEdit && edittable && (
          <MenuItem data-testid={testId ? `${testId}-edit-button` : 'edit-button'} key='edit-menu-item' onClick={onEdit}>
            <IconButton size='small' sx={{ marginRight: 1 }}>
              <EditIcon />
            </IconButton>
            {t('affiliateConversion.edit')}
          </MenuItem>
        )}

        {displayMenueOptions && onRemove && edittable && !hideDelete && (
          <MenuItem data-testid={testId ? `${testId}-remove-button` : 'remove-button'} key='delete-menu-item' onClick={onRemove}>
            <IconButton size='small' sx={{ marginRight: 1 }}>
              <DeleteIcon />
            </IconButton>
            {t('affiliateConversion.delete')}
          </MenuItem>
        )}

        {canCovertAffiliate && (
          <MenuItem
            key='invite'
            data-testid='affiliate-invite'
            onClick={() => {
              setOpenConvertAffiliate(true);
            }}
          >
            <IconButton size='small' sx={{ marginRight: 1 }}>
              <PersonIcon />
            </IconButton>
            {t('affiliateConversion.inviteUser')}
          </MenuItem>
        )}
      </Menu>
      {displayMenueOptions && <ConvertAffiliate
        refetch={refetch}
        options={options}
        accountData={accountData}
        setOpenConvertAffiliate={setOpenConvertAffiliate}
        openConvertAffiliate={openConvertAffiliate}
        currentAffiliate={affiliate}
      />}
    </>
  );
};
