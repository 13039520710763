import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isEmpty, kebabCase, omit } from 'lodash/fp';
import {
  Button, Form, MenuItem, SelectField, TextField,
  TranslatableTextField,
} from '../../../../2-component';
import { RoleProfileSelect } from '../../../../3-pattern/roleProfileSelect/roleProfileSelect';
import { NotificationGroupSelect } from '../../../../3-pattern/notificationGroupSelect/notificationGroupSelect';
import { Box } from '../../../../1-primative';
import { UserContext } from '../../../../../providers/userContextProvider';
import { defaultNotificationChannels, getNotificationChannels } from './notificationGroups';
import {
  NotificationChannel, TranslatedString, TriggerEventTypes, TriggerObjectTypes,
} from '../../../../../interfaces';
import NotificationChannelsDefinitions from './notificationChannelsDefinitions';
import { getBackendLanguage } from '../../../../../assets/i18n/config';

const FETCH_NOTIFICATION_DEFINITION = gql`
  query fetchNotificationDefinition($notificationDefinitionId: ObjectID!) {
    fetchNotificationDefinition(notificationDefinitionId: $notificationDefinitionId) {
      notificationDefinition {
        id
        name
        notificationGroup {
          id
          name { en fr }
        }
        titleTemplate {
          en fr
        }
        bodyTemplate {
          en fr
        }
        linkTemplate
        linkText {
          en fr
        }
        roleProfiles {
          id
        }
        triggerObjectType
        triggerEventType
        notificationChannels {
          type
          value
          setDefaultState
          allowEditByClient
        }
      }
    }
  }
`;

const CREATE_NOTIFICATION_DEFINITION = gql`
  mutation createNotificationDefinition($input: CreateNotificationDefinitionInput!) {
    createNotificationDefinition(input: $input) {
      notificationDefinition {
        id
      }
    }
  }
`;

const UPDATE_NOTIFICATION_DEFINITION = gql`
  mutation updateNotificationDefinition($input: UpdateNotificationDefinitionInput!) {
    updateNotificationDefinition(input: $input) {
      notificationDefinition {
        id
      }
    }
  }
`;

const DELETE_NOTIFICATION_DEFINITION = gql`
  mutation deleteNotificationDefinition($input: DeleteNotificationDefinitionInput!) {
    deleteNotificationDefinition(input: $input)
  }
`;

const TRIGGER_OBJECT_TYPES = [
  TriggerObjectTypes.USER,
  TriggerObjectTypes.ACCOUNT_STATEMENT,
  TriggerObjectTypes.TRANSFER,
  TriggerObjectTypes.SCHEDULED_TRANSFER,
];

const mapTriggerEvent: { [key in TriggerObjectTypes]?: TriggerEventTypes[] } = {
  [TriggerObjectTypes.USER]: [
    TriggerEventTypes.ACTIVE,
    TriggerEventTypes.INACTIVE,
    TriggerEventTypes.FROZEN,
    TriggerEventTypes.PERSONAL_INFORMATION_UPDATED,
    TriggerEventTypes.PERSONAL_ACCOUNT_INFORMATION_UPDATED,
    TriggerEventTypes.LOGIN_CREDENTIALS_UPDATED,
    TriggerEventTypes.USER_ID_CHECK_UPDATED,
    TriggerEventTypes.SUITABILITY_SCORE_UPDATED,
    TriggerEventTypes.SUITABILITY_REVIEW_DUE,
    TriggerEventTypes.SUITABILITY_REVIEW_OVERDUE,
  ],
  [TriggerObjectTypes.ACCOUNT_STATEMENT]: [
    TriggerEventTypes.RECONCILED,
    TriggerEventTypes.AWAITING_REVIEW,
  ],
  [TriggerObjectTypes.TRANSFER]: [
    TriggerEventTypes.INITIATED,
    TriggerEventTypes.READY,
    TriggerEventTypes.REQUESTED,
    TriggerEventTypes.PROCESSING,
    TriggerEventTypes.RECONCILED,
    TriggerEventTypes.FAILED,
    TriggerEventTypes.CANCELED,
  ],
  [TriggerObjectTypes.SCHEDULED_TRANSFER]: [
    TriggerEventTypes.SIGNED,
    TriggerEventTypes.RESIGNED,
    TriggerEventTypes.CANCELED,
  ],
};

const baseTranslatedString: TranslatedString = { en: '', fr: '' };

export const NotificationDefinitionForm = ({
  afterSubmit, action, triggerEvent = false, fetch = false, baseNotificationDefinition = {}, onRemove,
}: {
  afterSubmit: (id?: string) => void, action: 'create' | 'edit', triggerEvent?: boolean, fetch?: boolean, baseNotificationDefinition?: any, onRemove?: () => void,
}) => {
  const [notificationDefinition, setNotificationDefinition] = useState({
    ...baseNotificationDefinition,
    notificationChannels: baseNotificationDefinition?.notificationChannels ?? defaultNotificationChannels,
    roleProfileIds: baseNotificationDefinition.roleProfiles?.map((roleProfile: any) => roleProfile.id) || [],
  });
  const [focused, setFocused] = useState<string[]>([]);
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('orgSettings');

  const validate = () => {
    const invalidFields: string[] = [];
    if (!notificationDefinition.name) invalidFields.push('name');
    if (!notificationDefinition.titleTemplate?.en) invalidFields.push('titleTemplate');
    if (!notificationDefinition.bodyTemplate?.en) invalidFields.push('bodyTemplate');
    if (!notificationDefinition.notificationGroup?.id) invalidFields.push('notificationGroupId');
    if (!notificationDefinition.triggerObjectType) invalidFields.push('triggerObjectType');
    if (triggerEvent && !notificationDefinition.triggerEventType) invalidFields.push('triggerEventType');
    if ((notificationDefinition.roleProfileIds ?? []).length === 0) invalidFields.push('roleProfileIds');

    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  useQuery(FETCH_NOTIFICATION_DEFINITION, {
    variables: {
      notificationDefinitionId: baseNotificationDefinition.id,
    },
    fetchPolicy: 'no-cache',
    skip: !fetch || !baseNotificationDefinition.id,
    onCompleted: (d: any) => {
      const fetchedNotificationDefinition = d?.fetchNotificationDefinition?.notificationDefinition;
      setNotificationDefinition({
        ...fetchedNotificationDefinition,
        notificationChannels: getNotificationChannels(fetchedNotificationDefinition.notificationChannels),
        roleProfileIds: fetchedNotificationDefinition.roleProfiles?.map((roleProfile: any) => roleProfile.id) || [],
      });
    },
  });

  const [createND] = useMutation(CREATE_NOTIFICATION_DEFINITION, {
    onCompleted: (d: any) => {
      afterSubmit(d.createNotificationDefinition.notificationDefinition.id);
    },
    variables: {
      input: {
        name: notificationDefinition.name,
        notificationGroupId: notificationDefinition.notificationGroup?.id,
        titleTemplate: omit(['__typename'], notificationDefinition.titleTemplate),
        bodyTemplate: omit(['__typename'], notificationDefinition.bodyTemplate),
        linkTemplate: notificationDefinition.linkTemplate,
        linkText: notificationDefinition.linkText?.en ? omit(['__typename'], notificationDefinition.linkText) : undefined,
        roleProfileIds: notificationDefinition.roleProfileIds,
        triggerObjectType: notificationDefinition.triggerObjectType,
        triggerEventType: triggerEvent ? notificationDefinition.triggerEventType : undefined,
        organizationId: activeOrganization.id,
        notificationChannels: notificationDefinition.notificationChannels,
      },
    },
  });

  const [updateND] = useMutation(UPDATE_NOTIFICATION_DEFINITION, {
    onCompleted: (d: any) => {
      afterSubmit(d.updateNotificationDefinition.notificationDefinition.id);
    },
    variables: {
      input: {
        notificationDefinitionId: notificationDefinition.id,
        name: notificationDefinition.name,
        notificationGroupId: notificationDefinition.notificationGroup?.id,
        titleTemplate: omit(['__typename'], notificationDefinition.titleTemplate),
        bodyTemplate: omit(['__typename'], notificationDefinition.bodyTemplate),
        linkTemplate: notificationDefinition.linkTemplate,
        linkText: notificationDefinition.linkText?.en ? omit(['__typename'], notificationDefinition.linkText) : undefined,
        roleProfileIds: notificationDefinition.roleProfileIds,
        triggerObjectType: notificationDefinition.triggerObjectType,
        triggerEventType: triggerEvent ? notificationDefinition.triggerEventType : undefined,
        notificationChannels: notificationDefinition.notificationChannels,
      },
    },
  });

  const [deleteND] = useMutation(DELETE_NOTIFICATION_DEFINITION, {
    onCompleted: () => {
      if (onRemove) onRemove();
    },
    variables: {
      input: {
        notificationDefinitionId: notificationDefinition.id,
      },
    },
  });

  const onSubmit = () => {
    if (!validate()) return;
    if (action === 'create') {
      createND();
    } else {
      updateND();
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <TextField
        label={t('notificationDefinitionModal.name')}
        value={notificationDefinition.name}
        onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, name: e.target.value })}
        sx={{ mb: 2 }}
        fullWidth
        onBlur={() => setFocused([...focused, 'name'])}
        error={!notificationDefinition.name && focused.includes('name')}
        testId='notification-definition-name'
      />
      <NotificationGroupSelect
        sx={{ mb: 2 }}
        label={t('notificationDefinitionModal.notificationGroup')}
        onBlur={() => setFocused([...focused, 'notificationGroupId'])}
        error={!notificationDefinition.notificationGroup?.id && focused.includes('notificationGroupId')}
        selectedNotificationGroup={notificationDefinition.notificationGroup}
        onNotificationGroupSelect={(value: any) => setNotificationDefinition({
          ...notificationDefinition,
          notificationGroup: value,
          ...(isEmpty(baseNotificationDefinition) ? { notificationChannels: getNotificationChannels(value.notificationChannels) } : {}),
        })}
        testId='notification-definition-notification-group'
      />
      <TranslatableTextField
        fullWidth
        label={t('notificationDefinitionModal.titleTemplate')}
        value={notificationDefinition.titleTemplate || baseTranslatedString}
        onChange={(value) => setNotificationDefinition({ ...notificationDefinition, titleTemplate: value })}
        sx={{ mb: 2 }}
        fallbackLanguage={getBackendLanguage()}
        onBlur={() => setFocused([...focused, 'titleTemplate'])}
        error={!notificationDefinition.titleTemplate?.en && focused.includes('titleTemplate')}
        testId='notification-definition-title-template'
      />
      <TranslatableTextField
        fullWidth
        label={t('notificationDefinitionModal.bodyTemplate')}
        value={notificationDefinition.bodyTemplate || baseTranslatedString}
        onChange={(value) => setNotificationDefinition({ ...notificationDefinition, bodyTemplate: value })}
        sx={{ mb: 2 }}
        fallbackLanguage={getBackendLanguage()}
        onBlur={() => setFocused([...focused, 'bodyTemplate'])}
        error={!notificationDefinition.bodyTemplate?.en && focused.includes('bodyTemplate')}
        testId='notification-definition-body-template'
      />
      <TextField
        label={t('notificationDefinitionModal.linkTemplate')}
        value={notificationDefinition.linkTemplate}
        onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, linkTemplate: e.target.value })}
        sx={{ mb: 2 }}
        fullWidth
        testId='notification-definition-link-template'
      />
      <TranslatableTextField
        fullWidth
        label={t('notificationDefinitionModal.linkText')}
        value={notificationDefinition.linkText || baseTranslatedString}
        onChange={(value) => setNotificationDefinition({ ...notificationDefinition, linkText: value })}
        sx={{ mb: 2 }}
        fallbackLanguage={getBackendLanguage()}
        testId='notification-definition-link-text'
      />
      <SelectField
        onChange={(e: any) => setNotificationDefinition({
          ...notificationDefinition,
          triggerObjectType: e.target.value,
          triggerEventType: undefined,
        })}
        label={t('notificationDefinitionModal.triggerObjectType.title')}
        fullWidth
        sx={{ mb: 2 }}
        value={notificationDefinition.triggerObjectType}
        onBlur={() => setFocused([...focused, 'triggerObjectType'])}
        error={!notificationDefinition.triggerObjectType && focused.includes('triggerObjectType')}
        testId='notification-definition-trigger-object-type'
      >
        {TRIGGER_OBJECT_TYPES.map((elem) => (
          <MenuItem key={elem} value={elem} data-testid={`notification-definition-trigger-object-type-item-${kebabCase(elem)}`}>{t(`notificationDefinitionModal.triggerObjectType.${elem}`)}</MenuItem>
        ))}
      </SelectField>
      {triggerEvent && (
        <SelectField
          onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, triggerEventType: e.target.value })}
          label={t('notificationDefinitionModal.triggerEventType.title')}
          fullWidth
          sx={{ mb: 2 }}
          disabled={!notificationDefinition.triggerObjectType}
          value={notificationDefinition.triggerEventType}
          onBlur={() => setFocused([...focused, 'triggerEventType'])}
          error={!notificationDefinition.triggerEventType && focused.includes('triggerEventType')}
          testId='notification-definition-trigger-event-type'
        >
          {(mapTriggerEvent[notificationDefinition.triggerObjectType as TriggerObjectTypes] ?? []).map((elem) => (
            <MenuItem key={elem} value={elem} data-testid={`notification-definition-trigger-event-type-item-${kebabCase(elem)}`}>{t(`notificationDefinitionModal.triggerEventType.${elem}`)}</MenuItem>
          ))}
        </SelectField>
      )}
      <RoleProfileSelect
        label={t('notificationDefinitionModal.roleProfiles')}
        onBlur={() => setFocused([...focused, 'roleProfileIds'])}
        error={(notificationDefinition.roleProfileIds ?? []).length === 0 && focused.includes('roleProfileIds')}
        selectedRoleProfileIds={notificationDefinition.roleProfileIds || []}
        onChange={(value: any[]) => setNotificationDefinition({ ...notificationDefinition, roleProfileIds: value })}
        testId='notification-definition-role-profile'
      />
      {(notificationDefinition?.notificationChannels ?? defaultNotificationChannels).map((notificationChannel: NotificationChannel, index: number) => (
        <Box key={notificationChannel.type} sx={{ mt: 3 }}>
          <NotificationChannelsDefinitions
            index={index}
            notificationChannel={notificationChannel}
            notificationObject={notificationDefinition}
            setNotificationObject={setNotificationDefinition}
          />
        </Box>
      ))}
      <Box display='flex' justifyContent='flex-end' mt={2}>
        { action === 'edit' ? (
          <Button label={t('notificationDefinitionModal.remove')} onClick={deleteND} variant='tonal' color='destructive' sx={{ mr: 1 }} dataTestId='remove-button' />
        ) : (<></>)}
        <Button type='submit' label={t(`notificationDefinitionModal.${action}`)} dataTestId={`${action}-button`} />
      </Box>
    </Form>
  );
};
