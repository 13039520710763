import { useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { isUndefined, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { PieChart } from '../../2-component/pieChart/pieChart';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Card, CardContent } from '../../2-component';
import { AssetClassBranch } from '../../4-module/workflowCompletion/subSteps/createModelPortfolio/createModelPortfolio';

interface AssetClassGroup {
  totalPercentage: number;
  name: string;
  color: string;
}

interface PieChartTemplatesProps {
  assetClassBranches: AssetClassBranch[];
  variant?: 'elevated' | 'outlined' | 'none';
  templateName?: string;
}

const DEFAULT_BORDER_RADIUS = '4px';
const DEFAULT_COLOR = '#FFFFFF';

const ensureString = (value: unknown, defaultValue: string): string => {
  if (typeof value === 'string') {
    return value;
  }
  return defaultValue;
};

export const PieChartTemplates = ({
  assetClassBranches = [],
  variant = 'none',
}: PieChartTemplatesProps) => {
  const [activeItem, setActiveItem] = useState<AssetClassGroup | null>(null);
  const { ref, sys } = useThemeTokens();
  const { t } = useTranslation(['shared', 'modelPortfolios']);

  const colors = useMemo(() => ({
    supportOne: ensureString(ref.palette.supportOne50, DEFAULT_COLOR),
    supportTwo: ensureString(ref.palette.supportTwo50, DEFAULT_COLOR),
    supportThree: ensureString(ref.palette.supportThree50, DEFAULT_COLOR),
    supportFour: ensureString(ref.palette.supportFour50, DEFAULT_COLOR),
    supportFive: ensureString(ref.palette.supportFive50, DEFAULT_COLOR),
    supportSix: ensureString(ref.palette.supportSix50, DEFAULT_COLOR),
  }), [ref.palette]);

  const colorKeys = useMemo(() => Object.keys(colors), [colors]);

  const assetClassData = useMemo(() => assetClassBranches.reduce((acc: AssetClassGroup[], branch: AssetClassBranch, index: number) => {
    const colorKey = colorKeys[index % colorKeys.length];
    acc.push({
      totalPercentage: branch.targetPercentage || 0,
      name: branch.assetClass.name,
      color: colorKey,
    });
    return acc;
  }, []).sort((a, b) => b.totalPercentage - a.totalPercentage), [assetClassBranches, colorKeys]);

  const pieChartDataAndColors = useMemo(() => {
    const totalPercentage = assetClassData.reduce((acc: number, x: AssetClassGroup) => acc + Number(x.totalPercentage), 0);
    const remainingPercentage = Math.max(0, 100 - totalPercentage);

    const pieChartData = [
      ...assetClassData.map((x: AssetClassGroup) => Number(x.totalPercentage)),
      remainingPercentage,
    ];

    const pieChartColors = [
      ...assetClassData.map((x: AssetClassGroup) => colors[x.color as keyof typeof colors]),
      sys.color.surfaceContainer,
    ];

    return { pieChartData, pieChartColors };
  }, [assetClassData, colors, sys.color.surfaceContainer]);

  const dataState = useMemo(() => {
    const hasData = !isEmpty(assetClassBranches);
    return { hasData };
  }, [assetClassBranches]);

  return (
    <Card variant={variant} sx={{ borderRadius: 'radius-md', border: '1px solid #DCDDE0' }}>
      <CardContent sx={{ p: '16px !important' }}>
        <Grid container spacing={1} sx={{ alignItems: 'center', pl: 1 }}>
          <Grid item sx={{ width: '176px', height: '176px' }}>
            <Box position='relative' sx={{ height: 176, width: 176 }}>
              <PieChart
                datasets={dataState.hasData ? [{
                  label: '',
                  data: pieChartDataAndColors.pieChartData,
                }] : [{
                  label: '',
                  data: [100],
                }]}
                labels={dataState.hasData ? assetClassData.map((x: AssetClassGroup) => x.name) : ['Empty']}
                onHover={(index: number | undefined) => {
                  setActiveItem(null);
                  if (dataState.hasData && !isUndefined(index) && index < assetClassData.length) {
                    setActiveItem(assetClassData[index]);
                  }
                }}
                customColors={dataState.hasData ? pieChartDataAndColors.pieChartColors : [sys.color.surfaceContainer || '']}
                cutoutPercentage={80}
              />
              {/* TODO: Add this back in if we want to show the template name and percentage in the center of the piechart */}
              {/* <Box
                textAlign='center'
                position='absolute'
                left='50%'
                top='50%'
                sx={{ transform: 'translate(-50%,-50%)' }}
              >
                {!dataState.hasData ? (
                  <>
                    <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                      {templateName}
                    </Typography>
                    <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                      0.00%
                    </Typography>
                  </>
                ) : activeItem ? (
                  <>
                    <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                      {activeItem.name}
                    </Typography>
                    <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                      {`${Number(activeItem.totalPercentage).toFixed(2)}%`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                      {templateName}
                    </Typography>
                    <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                      {`${assetClassData.reduce((acc: number, x: AssetClassGroup) => acc + Number(x.totalPercentage), 0).toFixed(2)}%`}
                    </Typography>
                  </>
                )}
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs sx={{ width: '248px', height: '200px', alignContent: 'center' }}>
            {dataState.hasData ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                pl: 5,
                maxHeight: '200px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: sys.color.surfaceContainer,
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: sys.color.surfaceContainer,
                  borderRadius: '4px',
                },
              }}>
                {assetClassData.map((item: AssetClassGroup, i: number) => {
                  const isActive = activeItem?.name === item.name;
                  const isLast = i + 1 === assetClassData.length;
                  const color = ensureString(colors[item.color as keyof typeof colors], colors.supportOne);
                  const surfaceContainerColor = ensureString(sys.color.surfaceContainer, DEFAULT_COLOR);
                  const surfaceColor = ensureString(sys.color.surface, DEFAULT_COLOR);
                  const borderRadius = ensureString(sys.borderRadius.md, DEFAULT_BORDER_RADIUS);

                  return (
                    <Box
                      key={item.name}
                      onMouseEnter={() => setActiveItem(item)}
                      onMouseLeave={() => setActiveItem(null)}
                      display='flex'
                      justifyContent='space-between'
                      alignItems='right'
                      sx={{
                        borderBottom: !isActive && !isLast ? `2px solid ${surfaceContainerColor}` : `2px solid ${surfaceColor}`,
                        py: 0.5,
                        px: 1,
                        backgroundColor: isActive ? surfaceContainerColor : 'none',
                        borderRadius: isActive ? borderRadius : '0px',
                      }}
                    >
                      <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
                        <Box sx={{
                          borderRadius: '10px',
                          height: '8px',
                          width: '8px',
                          backgroundColor: color,
                          mr: 1,
                        }} />
                        <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{item.name}</Typography>
                      </Box>
                      <Box display='flex' alignItems='center' gap={2}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                          {`${Number(item.totalPercentage || 0).toFixed(2)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <>
                {[...Array(5)].map((_, index) => (
                  <Box
                    key={`empty-asset-class-${index}`}
                    display='flex'
                    justifyContent='space-between'
                    alignItems='left'
                    sx={{
                      position: 'relative',
                      py: 1,
                      px: 1,
                      pl: 5,
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '40px',
                        right: 0,
                        height: '2px',
                        backgroundColor: index === 4 ? 'transparent' : sys.color.surfaceContainer,
                      },
                    }}
                  >
                    <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
                      <Box sx={{
                        borderRadius: '10px',
                        height: '8px',
                        width: '8px',
                        backgroundColor: sys.color.surfaceContainer,
                        mr: 1,
                      }} />
                      <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                        {t('modelPortfolios:modelPortfolioTable.assetClass')} {(index + 1).toString().padStart(2, '0')}
                      </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap={2}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                        --%
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
