import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FETCH_FILE_DOCUMENT, FETCH_FILE_DOCUMENTS, FETCH_ROLE_PROFILES, UPDATE_FILE_DOCUMENT,
} from '../documents.queries';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import {
  Button,
  Dialog, DialogContent, DialogFooter, DialogTitle, EmptyStateHeaderTitle, Pagination, Switch, TextField,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { UploadDocument } from './uploadDocument';
import { useLocalization } from '../../../../../util/useLocalization';
import { PageObjectType } from '../../../../5-page';
import { FileDocument, FileDocumentObjectTypes, FileDocumentPermissionTypes } from '../../../../../interfaces/fileDocument';
import { DeleteFileDocumentModal, RoleProfileSelect } from '../../../../3-pattern';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';

export const UploadedDocuments = ({
  objectId, documentTypes, open, setOpen, objectType, userId, isDeletable, filters, options,
}: {
  objectId: string, documentTypes: string[], open: boolean, setOpen: (o: boolean) => void, objectType?: PageObjectType, userId?: string,
  isDeletable?: boolean
  filters?: { searchText?: string, dateStart?: string, dateEnd?: string }, options?: any,
}) => {
  const { t } = useTranslation(['document', 'shared']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { localizedDate } = useLocalization();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [shareWithEveryone, setShareWithEveryone] = useState(true);
  const [sharedWithRoleProfiles, setSharedWithRoleProfiles] = useState<string[]>([]);
  const [allRoleProfiles, setAllRoleProfiles] = useState<string[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [name, setName] = useState('');
  const [selectedFileDocument, setSelectedFileDocument] = useState<FileDocument | undefined>();
  const [selectedFileDocumentId, setSelectedFileDocumentId] = useState('');
  const { sys } = useThemeTokens();
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const docSharedWithRoleProfiles = (selectedFileDocument?.sharedWithRoleProfiles?.map((rp) => rp.id) ?? []).sort();
    const hasAllRoleProfiles = JSON.stringify(docSharedWithRoleProfiles) === JSON.stringify(allRoleProfiles);
    setShareWithEveryone(
      (!!selectedFileDocument?.sharedClient && (selectedFileDocument?.sharedWithRoleProfiles ?? []).length === 0 && selectedFileDocument?.permissionType === FileDocumentPermissionTypes.PUBLIC)
      || hasAllRoleProfiles,
    );
    setSharedWithRoleProfiles(docSharedWithRoleProfiles);
    setName(selectedFileDocument?.name ?? selectedFileDocument?.fileName ?? '');
  }, [selectedFileDocument, allRoleProfiles]);

  const mapObjectType: { [key in PageObjectType]?: any } = {
    ACCOUNT: { userIds: userId ? [userId] : undefined, objectIds: [objectId] },
    INDIVIDUAL: { userIds: [objectId] },
    NON_INDIVIDUAL: { userIds: [objectId] },
    HOUSEHOLD: { objectTypes: [FileDocumentObjectTypes.CLIENTGROUP], objectIds: [objectId] },
  };

  const useUserFilter = filters && (filters.searchText || filters.dateStart || filters.dateEnd);
  const userFilter: any = {};
  if (useUserFilter) {
    if (filters.dateStart) {
      userFilter.createdAtStart = filters.dateStart;
    }
    if (filters.dateEnd) {
      userFilter.createdAtEnd = filters.dateEnd;
    }
    if (filters.searchText) {
      userFilter.searchText = filters.searchText;
    }
  }

  useEffect(() => {
    // Reset page when filters are changed
    setPage(1);
  }, [filters?.searchText, filters?.dateStart, filters?.dateEnd]);

  const { data, loading, refetch } = useQuery(FETCH_FILE_DOCUMENTS, {
    variables: {
      input: {
        filter: {
          ...userFilter,
          ...(objectType && mapObjectType[objectType]),
          types: documentTypes,
        },
        pagination: {
          offSet: (page - 1) * 15,
          perPage: 15,
        },
      },
    },
  });

  const [fetchFileDocument] = useLazyQuery(FETCH_FILE_DOCUMENT, { fetchPolicy: 'network-only' });

  const onEditModalClose = () => {
    setShowEditModal(false);
    setAllowUpdate(false);
    setSelectedFileDocument(undefined);
  };

  useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: { perPage: 1000 },
      },
    },
    onCompleted: (rpData) => {
      const roleProfiles = rpData?.fetchRoleProfiles?.roleProfiles?.map((roleProfile: any) => roleProfile.id).sort();
      setAllRoleProfiles(roleProfiles);
    },
  });

  const [updateFileDocument] = useMutation(UPDATE_FILE_DOCUMENT, {
    variables: {
      input: {
        fileDocumentId: selectedFileDocument?.id,
        ...(options?.allowEditName ? { name } : {}),
        ...(options?.enableFileAccessManagement
          ? {
            sharedWithRoleProfiles,
            ...(!shareWithEveryone && sharedWithRoleProfiles.length === 0 ? {
              permissionType: FileDocumentPermissionTypes.PRIVATE,
            } : { permissionType: FileDocumentPermissionTypes.PUBLIC }),
          }
          : {}
        ),
      },
    },
    onCompleted: () => {
      onEditModalClose();
      refetch();
    },
  });

  if (loading) {
    return (
        <>
      <Box display={'flex'} gap={2} pt={2}>
        <Skeleton width='80%' height='24px' />
        <Skeleton width='20%' height='24px'/>
      </Box>
      <Box display={'flex'} gap={2} pt={2}>
        <Skeleton width='80%' height='24px' />
        <Skeleton width='20%' height='24px'/>
      </Box>
      <Box display={'flex'} gap={2} pt={2}>
        <Skeleton width='80%' height='24px' />
        <Skeleton width='20%' height='24px'/>
      </Box>
      </>
    );
  }

  if (!data) return <></>;

  return (
    <Box width='100%'>
      <UploadDocument open={open} setOpen={setOpen} refetch={refetch} objectId={objectId} objectType={objectType} userId={userId} options={options} />
      {(data?.fetchFileDocuments?.totalCount ?? 0) === 0 && (
        <EmptyStateHeaderTitle sx={{ mt: 2 }} title={useUserFilter ? t('document:noDocumentsFiltered') : t('document:noDocuments')} />
      )}
      {data.fetchFileDocuments.fileDocuments.map((document: any) => (
        <Box
          key={document.id}
          display='flex'
          pl={2}
          pt={1}
          pb={1}
          pr={2}
          justifyContent='space-between'
          alignItems='center'
          sx={{
            borderRadius: sys.borderRadius.md,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: sys.color.surfaceContainerVariant,
            },
          }}
          onClick={options?.allowEditName || options?.enableFileAccessManagement
            ? (e) => {
              setSelectedFileDocument(document);
              setShowEditModal(true);
            }
            : undefined
          }
        >
          <Box width='100%' display='flex'>
            <Typography variant='bodyMedium' sx={{ minWidth: '120px', color: sys.color.onSurfaceVariant }}>
              {localizedDate(document.uploadedAt)}
            </Typography>
            <Typography variant='bodyMedium'>{document.name ?? document.fileName}</Typography>
          </Box>
          {isDeletable && (
            <DeleteIcon
              sx={{ color: sys.color.onSurfaceVariant }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedFileDocumentId(document.id);
                setShowDeleteConfirmation(true);
              }}
            />
          )}
          <OpenInNew
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              fetchFileDocument({
                variables: { fileDocumentId: document.id },
                // we need setTimeout to open the url in Safari
                onCompleted: (dataDoc) => setTimeout(() => window.open(dataDoc.fetchFileDocument.fileDocument.downloadUrl, '_blank')),
              });
            }}
            sx={{ color: sys.color.onSurfaceVariant }}
          />
        </Box>
      ))}
      {
        (data?.fetchFileDocuments?.totalCount ?? 0) > 0 && (
          <Box display='flex' justifyContent='end' mt={2}>
            <Pagination page={page} onChange={(e: any, newPage: number) => setPage(newPage)} count={Math.ceil(data.fetchFileDocuments.totalCount / 15)} size='small' />
          </Box>
        )
      }
      <DeleteFileDocumentModal
        fileDocumentId={selectedFileDocumentId}
        handleClose={() => setShowDeleteConfirmation(false)}
        open={showDeleteConfirmation}
        refetch={refetch}
      />
      <Dialog
        open={showEditModal}
        fullWidth
        onClose={onEditModalClose}
      >
        <DialogTitle>{t('document:editDocumentModal.title')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('document:editDocumentModal.documentName')}
            fullWidth value={name}
            disabled={!options?.allowEditName}
            onChange={(e: any) => {
              setName(e.target.value.trimStart());
              setAllowUpdate(true);
            }}
          />
          <Switch
            checked={shareWithEveryone}
            label={t('document:addDocumentModal.shareDocumentWithEveryone')}
            sx={{ mt: 2 }}
            disabled={!options?.enableFileAccessManagement}
            onChange={(e) => {
              if (e) {
                setSharedWithRoleProfiles(allRoleProfiles);
              } else {
                setSharedWithRoleProfiles([]);
              }
              setShareWithEveryone(e);
              setAllowUpdate(true);
            }}
          />
          {!shareWithEveryone && permissions.includes('read:role_profiles') && (
            <Box marginTop={2}>
              <RoleProfileSelect
                label={t('document:addDocumentModal.grantAccessDocument')}
                selectedRoleProfileIds={sharedWithRoleProfiles}
                disabled={!options?.enableFileAccessManagement}
                onChange={(value: any[]) => {
                  setSharedWithRoleProfiles(value);
                  setAllowUpdate(true);
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogFooter>
          <Button
            label={t('shared:update')}
            disabled={!allowUpdate}
            onClick={updateFileDocument}
          />
        </DialogFooter>
      </Dialog>
    </Box>
  );
};
