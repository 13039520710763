import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Code, Download, Upload } from '@mui/icons-material';
import {
  Alert,
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../../2-component';
import { Box, Typography } from '../../../1-primative';
import { BetaBadge } from '../../../3-pattern';
import { UserContext } from '../../../../providers/userContextProvider';
import { translateBackend } from '../../../../assets/i18n/config';

const FETCH_WORKFLOWS = gql`
  query fetchWorkflows($input: FetchWorkflowsInput!) {
    fetchWorkflows(input: $input) {
      workflows {
        id  
      }
    }
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields($input: FetchCustomFieldsInput!) {
    fetchCustomFields(input: $input) {
      customFields {
        id
      }
    }
  }
`;

const FETCH_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      roleProfiles {
        id
      }
    }
  }
`;

export const EditCode = ({
  workflow, setWorkflow,
}: {
  workflow: any, setWorkflow: (workflow: any) => void,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'shared']);
  const { activeOrganization } = useContext(UserContext);
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ severity: 'error' | 'warning' | 'success', message: string, issues?: string[] } | null>(null);
  const [availableCustomFieldIds, setAvailableCustomFieldIds] = useState<string[]>([]);
  const [availableRoleProfileIds, setAvailableRoleProfileIds] = useState<string[]>([]);
  const [availableWorkflowIds, setAvailableWorkflowIds] = useState<string[]>([]);

  const { data, loading } = useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization?.id,
        },
        pagination: {
          perPage: 1000,
        },
      },
    },
  });

  const { data: workflowData, loading: workflowLoading } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization?.id,
        },
        pagination: {
          perPage: 1000,
        },
      },
    },
  });

  const { data: customFieldsData, loading: customFieldsLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization?.id,
        },
        pagination: {
          perPage: 1000,
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const { roleProfiles } = data.fetchRoleProfiles;
      const roleProfileIds = roleProfiles.map((w: any) => w.id);
      setAvailableRoleProfileIds(roleProfileIds);
    }
  }, [data]);

  useEffect(() => {
    if (workflowData) {
      const { workflows } = workflowData.fetchWorkflows;
      const workflowIds = workflows.map((w: any) => w.id);
      setAvailableWorkflowIds(workflowIds);
    }
  }, [workflowData]);

  useEffect(() => {
    if (customFieldsData) {
      const { customFields } = customFieldsData.fetchCustomFields;
      const customFieldIds = customFields.map((customField: any) => customField.id);
      setAvailableCustomFieldIds(customFieldIds);
    }
  }, [customFieldsData]);

  const cleanPastedWorkflow = async () => {
    const rawPastedWorkflow = await navigator.clipboard.readText();
    const pastedWorkflow = JSON.parse(rawPastedWorkflow);
    setAlert(null);

    const pc = [...pastedWorkflow];
    const issues: string[] = [];
    pc.forEach((step: any, i: number) => {
      step.subSteps.forEach((subStep: any, j: number) => {
        if (subStep.options.goalWorkflow && !availableWorkflowIds.includes(subStep.options.goalWorkflow)) {
          delete pc[i].subSteps[j].goalWorkflow;
          issues.push(t('goalWorkflowNotFound', { subStep: t(`subStepTypes.${subStep.type}`), step: translateBackend(step.name) }) as string);
        }
        if (subStep.rolesCompleteableBy.some((r: string) => !availableRoleProfileIds.includes(r))) {
          delete pc[i].subSteps[j].rolesCompleteableBy;
          issues.push(t('roleProfileNotFound', { subStep: t(`subStepTypes.${subStep.type}`), step: translateBackend(step.name) }));
        }
        Object.keys(subStep.options).forEach((key:string) => {
          if (subStep.options[key].customField) {
            if (!availableCustomFieldIds.includes(subStep.options[key].customField.id)) {
              if (subStep.options[key].enabled) {
                issues.push(
                  t('customFieldNotFound', {
                    subStep: t(`subStepTypes.${subStep.type}`),
                    step: translateBackend(step.name),
                    customField: translateBackend(subStep.options[key].customField.translatedName),
                  }),
                );
              }
              delete pc[i].subSteps[j].options[key];
            }
          }
        });
      });
    });

    setWorkflow(pc);
    if (issues.length > 0) {
      setAlert({ severity: 'warning', message: t('workflowIssues'), issues });
    } else {
      setAlert({ severity: 'success', message: t('workflowUpdated') });
    }
  };

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <IconButton onClick={() => setOpen(true)}><Code /></IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle onClose={() => setOpen(false)}>
          <Box display='flex' alignItems='center' gap={1}>
            {t('editCode')}
            <BetaBadge />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant='labelLarge'>{t('pasteWarning')}</Typography>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 2, mt: 2,
          }}>
            <Button label={t('copy')} onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(workflow));
              setAlert({ severity: 'success', message: t('shared:copiedToClipboard') });
            }} leadingIcon={Upload} variant='tonal'/>
            <Button label={t('paste')} onClick={async () => cleanPastedWorkflow()} leadingIcon={Download} disabled={loading || customFieldsLoading || workflowLoading} />
          </Box>
          {
            alert && (
              <Alert severity={alert.severity} sx={{ mt: 2 }}>
                <Typography weight='bold' variant='bodyMedium'>{alert.message}</Typography>
                {
                  alert.issues && alert.issues.map((issue) => (
                    <Typography key={issue} variant='bodyMedium'><li>{issue}</li></Typography>
                  ))
                }
              </Alert>
            )
          }
        </DialogContent>
        <DialogFooter>
          <Button label={t('shared:done')} onClick={() => setOpen(false)} />
        </DialogFooter>
      </Dialog>
    </Box>
  );
};
