import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TextField, Typography } from 'ovComponents';
import { AssetClassSelect } from '../assetClassSelect/assetClassSelect';
import { AssetClassBranch } from '../../4-module/workflowCompletion/subSteps/createModelPortfolio/createModelPortfolio';

export const AssetClassBranchesSelect = (
  {
    assetClassBranches,
    setAssetClassBranches,
  }: {
    assetClassBranches: AssetClassBranch[],
    setAssetClassBranches: (branches: AssetClassBranch[]) => void,
  },
) => {
  const { t } = useTranslation(['modelPortfolios']);

  const handleAssetClassChange = (id: string) => (event: any) => {
    setAssetClassBranches(assetClassBranches.map((branch) => (
      branch.id === id
        ? { ...branch, assetClass: { id: event.target.value, key: '', name: '' } }
        : branch
    )));
  };

  const handlePercentageChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    // Only allow numbers and empty string
    if (value === '' || (/^\d*\.?\d*$/.test(value) && parseFloat(value) <= 100)) {
      setAssetClassBranches(assetClassBranches.map((branch) => (
        branch.id === id ? { ...branch, targetPercentage: value === '' ? undefined : parseFloat(value) } : branch
      )));
    }
  };

  const addBranch = () => {
    const newBranch = {
      id: String(Date.now()),
      assetClass: { id: '', key: '', name: '' },
      targetPercentage: undefined,
    };
    setAssetClassBranches([...assetClassBranches, newBranch as AssetClassBranch]);
  };

  const removeBranch = (id: string) => {
    setAssetClassBranches(assetClassBranches.filter((branch) => branch.id !== id));
  };

  const usedAssetClasses = assetClassBranches.map((branch) => branch.assetClass?.id).filter(Boolean);

  return (
    <Box border="1px solid" borderColor="divider" borderRadius={1} p={2}>
      {assetClassBranches.map((branch, index) => (
        <Grid container spacing={2} key={branch.id} sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <AssetClassSelect
              leadingIcon="search"
              value={branch.assetClass?.id}
              setAssetClass={handleAssetClassChange(branch.id)}
              label="Asset Class"
              filteredAllocations={usedAssetClasses}
              omitAny={true}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Target %"
              value={branch.targetPercentage}
              onChange={handlePercentageChange(branch.id)}
              type="number"
              fullWidth
              required
              error={branch.targetPercentage === 0 || branch.targetPercentage === undefined || branch.targetPercentage > 100}
              trailingIcon="percent"
            />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              alignItems: branch.targetPercentage === 0 || branch.targetPercentage === undefined || branch.targetPercentage > 100
                ? 'center'
                : 'flex-end',
              mb: branch.targetPercentage === 0 || branch.targetPercentage === undefined || branch.targetPercentage > 100 ? 0 : 0.5,
            }}
          >
            {assetClassBranches.length > 1 && (
              <IconButton
                onClick={() => removeBranch(branch.id)}
                size="small"
                aria-label="delete asset class"
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 500,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8,
            },
            color: 'primary.main',
            pb: 2,
            display: 'inline-block', // This is to prevent the typography from taking up the full width of the container
          }}
          onClick={addBranch}
        >
          {t('addAssetClassBranch')}
        </Typography>
    </Box>
  );
};
