import { getBackendLanguage } from 'assets/i18n/config';
import { TextField } from 'ovComponents/2-component';
import { useState } from 'react';
import { useGlobalToast } from '../../../providers/globalToastProvider';

export const ValidatedTextField = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => {
  const { showToast } = useGlobalToast();

  const [valid, setValid] = useState(true);

  return <TextField
      label={option.label[getBackendLanguage()]}
      value={option.value}
      fullWidth
      sx={{ mb: 2 }}
      error={!valid}
      errorText={option.options?.validation?.message[getBackendLanguage()]}
      onChange={(e: any) => {
        let isValid = true;
        try {
          const regexp = new RegExp(option.options.validation.regex);
          isValid = regexp.test(e.target.value);
        } catch (error) {
          if (error instanceof SyntaxError) {
            showToast({
              message: 'Text field validation is not configured correctly',
              severity: 'error',
            });
          } else {
            throw error;
          }
        }
        const newOptions = [...options];
        newOptions[i] = { ...option, value: e.target.value, valid: isValid };
        setOptions(newOptions);
        setValid(isValid);
      }}
    />;
};
