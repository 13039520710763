import {
  Table, TableBody, TableCell, TableHead, TableRow,
  TableHeadCell,
  Box,
  IconButton,
  Badge,
  RefBox,
  NumberField,
  Typography,
} from 'ovComponents';
import { translateBackend, getBackendLanguage } from 'assets/i18n/config';
import { useTranslation } from 'react-i18next';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  useEffect, useState, useRef,
} from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided, DraggableStateSnapshot, DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { SecuritySelect } from '../securitySelect/securitySelect';
import { TranslatedString } from '../../../interfaces/shared';

export interface Security {
  id: string;
  financialProductId: string;
  name: string;
  translatedName: TranslatedString;
  assetClass: string;
  primaryAssetClass?: {
    translatedName: TranslatedString;
  };
  ticker: string;
  taxRank: number;
  targetPercentage: number | null;
  color: 'supportOne' | 'supportTwo' | 'supportThree' | 'supportFour' | 'supportFive' | 'supportSix';
}

interface TranslatedLabel {
  en: string;
  fr: string;
}

interface ColumnConfig {
  enabled: boolean;
  label: TranslatedLabel;
}

interface SecurityTableColumns {
  security: ColumnConfig;
  assetClass: ColumnConfig;
  taxRank: ColumnConfig;
  targetPercentage: ColumnConfig;
  buttonRemoveSecurity: {
    enabled: boolean;
  };
}

interface SecurityTableOptions {
  securityAllocationChart: {
    columns: [SecurityTableColumns];
  };
}

interface SecuritiesSelectionTableProps {
  securities: Security[];
  options?: SecurityTableOptions;
  onRemoveSecurity?: (index: number) => void;
  onAddSecurity?: () => void;
  onUpdateSecurity?: (index: number, newSecurity: Security) => void;
  onUpdateTaxRank?: (index: number, value: number) => void;
  onUpdateTargetPercentage?: (index: number, value: number) => void;
  onReorderSecurities?: (securities: Security[]) => void;
}

const COLUMNS = {
  SECURITY: {
    width: '50%',
    key: 'security',
  },
  ASSET_CLASS: {
    width: '20%',
    key: 'assetClass',
  },
  TAX_RANK: {
    width: '15%',
    key: 'taxRank',
  },
  TARGET_PERCENTAGE: {
    width: '15%',
    key: 'targetPercentage',
  },
} as const;

const getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: 0.5,
  margin: 0,
  background: 'white',
  ...draggableStyle,
  height: '50px',
  width: '800px',
});

const SecurityCell: React.FC<{
  security: Security;
  index: number;
  isEditing: boolean;
  dragHandleProps: any;
  onEdit: () => void;
  onUpdate: (security: any) => void;
}> = ({
  security, index, isEditing, dragHandleProps, onEdit, onUpdate,
}) => (
    <TableCell
      width={COLUMNS.SECURITY.width}
      align="left"
      onClick={onEdit}
      sx={{
        cursor: 'pointer',
        typography: 'body1',
        fontSize: '16px',
        padding: 0.5,
        pl: 2,
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Box {...dragHandleProps} className="hover-element">
          <DragIndicatorIcon sx={{ color: 'action.disabled' }} />
        </Box>
        {isEditing ? (
          <RefBox
            sx={{ width: '250px' }}
            onClick={(e) => e.stopPropagation()}
          >
            <SecuritySelect
              value={security.financialProductId}
              label=""
              setSecurity={(newSecurity) => onUpdate(newSecurity)}
            />
          </RefBox>
        ) : (
          <Box sx={{
            '&:hover': {
              color: 'primary.main',
            },
            flexGrow: 1,
            width: '200px',
            fontFamily: 'Interphases Pro',
            fontSize: '14px',
            fontWeight: 400,
          }}>
            {security?.translatedName?.[getBackendLanguage()] || ''}
          </Box>
        )}
      </Box>
    </TableCell>
);

const TableHeaders: React.FC<{ options: any }> = ({ options }) => (
  <TableHead>
    <TableRow sx={{
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      position: 'relative',
    }}>
      {Object.values(COLUMNS).map((column, index) => {
        const columnConfig = options?.securityAllocationChart?.columns?.[0]?.[column.key];
        if (!columnConfig?.enabled) return null;

        const isFirst = index === 0;
        const isLast = index === Object.values(COLUMNS).length - 1;

        return (
          <TableHeadCell
            key={column.key}
            width={column.width}
            backgroundColor="#FFFFFF"
            textColor="#000000"
            center={column.key !== 'security' && column.key !== 'assetClass'}
            sx={{
              '& .MuiTypography-root': {
                fontSize: '14px',
                fontFamily: 'Interphases Pro',
                fontWeight: 600,
              },
              ...(column.key === 'security' || column.key === 'assetClass' ? { textAlign: 'left', pl: 2 } : {}),
              ...(column.key === 'security' ? { pl: 6 } : {}),
              ...(isFirst ? { borderTopLeftRadius: '10px' } : {}),
              ...(isLast ? { borderTopRightRadius: '10px' } : {}),
            }}
          >
            {translateBackend(columnConfig.label)}
          </TableHeadCell>
        );
      })}
    </TableRow>
    <TableRow sx={{ height: '1px' }}>
      <TableCell
        colSpan={Object.keys(COLUMNS).length}
        sx={{
          padding: 0,
          height: '1px',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      />
    </TableRow>
  </TableHead>
);

export const SecuritiesSelectionTable = ({
  securities = [],
  options,
  onRemoveSecurity,
  onAddSecurity,
  onUpdateSecurity,
  onUpdateTaxRank,
  onUpdateTargetPercentage,
  onReorderSecurities,
}: SecuritiesSelectionTableProps) => {
  const { t } = useTranslation(['shared', 'modelPortfolios']);
  const [securityEditingIndex, setSecurityEditingIndex] = useState<number | null>(null);
  const [targetPercentageEditingIndex, setTargetPercentageEditingIndex] = useState<number | null>(null);
  const [taxRankEditingIndex, setTaxRankEditingIndex] = useState<number | null>(null);
  const [currentTaxRankValue, setCurrentTaxRankValue] = useState<string>('');
  const selectSecurityRef = useRef<HTMLDivElement>(null);
  const selectTargetPercentageRef = useRef<HTMLDivElement>(null);
  const selectTaxRankRef = useRef<HTMLDivElement>(null);

  const handleSecurityUpdate = (index: number, newSecurity: any) => {
    if (newSecurity && onUpdateSecurity && newSecurity !== 'ANY') {
      const updatedSecurity = {
        ...securities[index],
        financialProductId: newSecurity.id,
        name: newSecurity.translatedName[getBackendLanguage()],
        translatedName: newSecurity.translatedName,
        assetClass: newSecurity.primaryAssetClass?.translatedName[getBackendLanguage()] || '',
        primaryAssetClass: newSecurity.primaryAssetClass,
        ticker: newSecurity.ticker,
      };

      onUpdateSecurity(index, updatedSecurity);
      setSecurityEditingIndex(null);
    }
  };

  const handleTaxRankUpdate = (index: number, value: number) => {
    if (value !== null) {
      if (value <= 0) {
        return;
      }

      const existingIndex = securities.findIndex((s) => s.taxRank === value);

      if (existingIndex !== -1 && existingIndex !== index) {
        const updatedSecurities = [...securities];
        const currentSecurity = { ...updatedSecurities[index] };
        const existingSecurity = { ...updatedSecurities[existingIndex] };

        updatedSecurities[existingIndex] = { ...currentSecurity, taxRank: value };
        updatedSecurities[index] = { ...existingSecurity, taxRank: currentSecurity.taxRank };

        onReorderSecurities?.(updatedSecurities);
      } else {
        onUpdateTaxRank?.(index, value);
      }
    }
  };

  const handleTaxRankFocus = (index: number) => {
    setTaxRankEditingIndex(index);
    setCurrentTaxRankValue(securities[index].taxRank.toString());
  };

  const handleTaxRankBlur = (index: number) => {
    const value = Number(currentTaxRankValue);
    handleTaxRankUpdate(index, value);
    setTaxRankEditingIndex(null);
    setCurrentTaxRankValue('');
  };

  const handleTargetPercentageUpdate = (index: number, value: number) => {
    if (value !== null) {
      onUpdateTargetPercentage?.(index, value);
    }
  };

  const handleAddSecurity = () => {
    if (onAddSecurity) {
      setSecurityEditingIndex(securities.length);
      setTaxRankEditingIndex(null);
      setTargetPercentageEditingIndex(securities.length);
      onAddSecurity();
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !onReorderSecurities) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    // Create a new array without modifying the original
    const updatedSecurities = [...securities];
    const [movedSecurity] = updatedSecurities.splice(sourceIndex, 1);
    updatedSecurities.splice(destinationIndex, 0, movedSecurity);

    // Create the final array with all properties and update tax ranks
    const finalSecurities = updatedSecurities.map((security, index) => ({
      ...security,
      taxRank: index + 1, // Simply assign sequential tax ranks based on new position
    }));

    // Use setTimeout to ensure the drag animation completes first
    setTimeout(() => {
      onReorderSecurities(finalSecurities);
    }, 0);
  };

  const handleTargetPercentageFocus = (index: number) => {
    setTargetPercentageEditingIndex(index);
  };

  const handleTaxRankKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleTaxRankBlur(index);
    } else if (e.key === 'Tab') {
      handleTaxRankBlur(index);
      handleTargetPercentageFocus(index);
      e.preventDefault();
    }
  };

  const handleTargetPercentageKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setTargetPercentageEditingIndex(null);
    } else if (e.key === 'Tab') {
      setTargetPercentageEditingIndex(null);
      // If there's a next row, focus its tax rank
      if (index < securities.length - 1) {
        handleTaxRankFocus(index + 1);
      }
      e.preventDefault();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      const menuElement = document.querySelector('.MuiMenu-paper');
      const numberFieldElements = document.querySelectorAll('.MuiInputBase-root');

      const isClickOnNumberField = Array.from(numberFieldElements).some((el) => el.contains(target));
      if (isClickOnNumberField) return;

      if (securityEditingIndex !== null) {
        const isClickInside = selectSecurityRef.current?.contains(target) || menuElement?.contains(target);
        if (!isClickInside) {
          setSecurityEditingIndex(null);
        }
      }

      if (taxRankEditingIndex !== null) {
        if (!selectTaxRankRef.current?.contains(target)) {
          setTaxRankEditingIndex(null);
        }
      }

      if (targetPercentageEditingIndex !== null) {
        if (!selectTargetPercentageRef.current?.contains(target)) {
          setTargetPercentageEditingIndex(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [securityEditingIndex, taxRankEditingIndex, targetPercentageEditingIndex]);

  const totalPercentage = securities.reduce((sum: number, security) => sum + (Number(security.targetPercentage) || 0), 0).toFixed(2);

  const renderTableBody = (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
    <TableBody
      {...provided.droppableProps}
      ref={provided.innerRef}
      sx={{ overflow: 'visible' }}
    >
      {securities.map((security, index) => (
        <Draggable
          key={security.id || `security-${index}`}
          draggableId={security.id || `security-${index}`}
          index={index}
        >
          {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
            <TableRow
              ref={dragProvided.innerRef}
              {...dragProvided.draggableProps}
              style={getItemStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style)}
              showHoverElements
              hover
            >
              <SecurityCell
                security={security}
                index={index}
                isEditing={securityEditingIndex === index}
                dragHandleProps={dragProvided.dragHandleProps}
                onEdit={() => setSecurityEditingIndex(index)}
                onUpdate={(newSecurity) => handleSecurityUpdate(index, newSecurity)}
              />
              <TableCell width={COLUMNS.ASSET_CLASS.width} align="left" sx={{
                textAlign: 'left',
                typography: 'body1',
                fontSize: '14px',
                padding: 0.5,
                pl: 2,
              }}>
                <Badge
                  label={security.primaryAssetClass?.translatedName?.[getBackendLanguage()] || security.assetClass}
                  color={security.color}
                />
              </TableCell>
              {/* Tax Rank */}
              <TableCell width={COLUMNS.TAX_RANK.width} align="center" sx={{
                textAlign: 'center', typography: 'body1', fontSize: '14px', padding: 0.5,
              }} onClick={() => handleTaxRankFocus(index)}>
                {taxRankEditingIndex === index ? (
                  <RefBox ref={selectTaxRankRef}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <NumberField
                      number={currentTaxRankValue}
                      setNumber={setCurrentTaxRankValue}
                      onBlur={() => handleTaxRankBlur(index)}
                      InputProps={{
                        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => handleTaxRankKeyDown(index, e),
                        onFocus: (e: React.FocusEvent<HTMLInputElement>) => e.target.select(),
                      }}
                      sx={{ width: '100%', size: 'small' }}
                      label=""
                      textSize="14px"
                      showArrows={false}
                      textAlign="center"
                    />
                  </RefBox>
                ) : (
                  <Box sx={{
                    '&:hover': {
                      color: 'primary.main',
                    },
                    alignItems: 'left',
                    fontFamily: 'Interphases Pro',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}>
                    {security?.taxRank || ''}
                  </Box>
                )}
              </TableCell>
              {/* Target Percentage */}
              <TableCell
                width={COLUMNS.TARGET_PERCENTAGE.width}
                align="center"
                onClick={() => handleTargetPercentageFocus(index)}
                sx={{
                  textAlign: 'center',
                  position: 'relative',
                  px: 2,
                  typography: 'body1',
                  fontSize: '14px',
                  fontWeight: 400,
                  padding: 0.5,
                }}
              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                  <Box sx={{ flexGrow: 1 }}>
                    {targetPercentageEditingIndex === index ? (
                      <RefBox
                        ref={selectTargetPercentageRef}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <NumberField
                          number={security.targetPercentage?.toString() || ''}
                          setNumber={(value) => handleTargetPercentageUpdate(index, Number(value))}
                          InputProps={{
                            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => handleTargetPercentageKeyDown(index, e),
                            onFocus: (e: React.FocusEvent<HTMLInputElement>) => e.target.select(),
                          }}
                          sx={{ width: '100%' }}
                          decimalPlaces={2}
                          label=""
                          trailingIcon="percent"
                          showArrows={false}
                          textSize="14px"
                          textAlign="center"
                        />
                      </RefBox>
                    ) : (
                      <Box sx={{
                        '&:hover': {
                          color: 'primary.main',
                        },
                        alignItems: 'right',
                        fontFamily: 'Interphases Pro',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}>
                        {security.targetPercentage?.toFixed(2) || ''}
                      </Box>
                    )}
                  </Box>
                  {options?.securityAllocationChart?.columns?.[0]?.buttonRemoveSecurity?.enabled && onRemoveSecurity && targetPercentageEditingIndex !== index && (
                    <IconButton
                      className="hover-element"
                      onClick={() => onRemoveSecurity(index)}
                      size="small"
                      sx={{ p: 0.5 }}
                    >
                      <RemoveCircleOutlineIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </Draggable>
      ))}
      {provided.placeholder}
      <TableRow>
        <TableCell>
          {onAddSecurity && (
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                },
                color: 'primary.main',
                display: 'inline-block', // This is to prevent the typography from taking up the full width of the container
              }}
              onClick={handleAddSecurity}
            >
              {t('modelPortfolios:addSecurity')}
            </Typography>
          )}
        </TableCell>
        <TableCell colSpan={3} />
      </TableRow>
      <TableRow>
        <TableCell width={COLUMNS.SECURITY.width} sx={{
          '& .MuiTypography-root': {
            fontSize: '14px',
            fontFamily: 'Interphases Pro',
            fontWeight: 500,
          },
          borderBottomLeftRadius: '10px',
        }}><strong>Total</strong></TableCell>
        <TableCell width={COLUMNS.ASSET_CLASS.width} />
        <TableCell width={COLUMNS.TAX_RANK.width} />
        <TableCell width={COLUMNS.TARGET_PERCENTAGE.width} sx={{
          '& .MuiTypography-root': {
            fontSize: '16px',
            fontFamily: 'Interphases Pro',
            fontWeight: 600,
          },
          borderBottomRightRadius: '10px',
        }}>
          <Box sx={{ color: Number(totalPercentage) !== 100 ? 'error.main' : 'inherit' }}>
            <strong>{`${totalPercentage}%`}</strong>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box sx={{
        overflow: 'visible',
        '& .MuiTable-root': {
          overflow: 'visible',
        },
      }}>
        <Table
          sx={{
            minWidth: 650,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: '10px',
            overflow: 'visible',
            borderCollapse: 'inherit',
            position: 'relative',
            zIndex: 1,
            '& .MuiTableHead-root': {
              position: 'relative',
              zIndex: 2,
            },
          }}
          aria-label="table"
        >
          <TableHeaders options={options} />
          <Droppable droppableId="securities-table">
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => renderTableBody(provided, snapshot)}
          </Droppable>
        </Table>
      </Box>
    </DragDropContext>
  );
};

export default SecuritiesSelectionTable;
