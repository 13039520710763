import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  gql,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import Persona, { Client } from 'persona';
import { useGlobalToast } from 'providers/globalToastProvider';
import { InquiryError } from 'persona/dist/lib/interfaces';
import { FETCH_IDS } from 'pages/client/components/iDs';
import { fileUploader, FileUploaderProps } from 'util/fileUploader';
import { FileDocumentObjectTypes, FileDocumentTypes } from 'interfaces/fileDocument';
import { FETCH_DOCUMENTS } from 'pages/client/components/documents';
import { useTranslation } from 'react-i18next';
import { ComplianceStates, User } from '../../../../../interfaces';
import { IdVerificationVisual } from './idVerification.visual';
import { FETCH_INTEGRATIONS } from '../../../../../pages/devSettings/components/integrations';
import { UserContext } from '../../../../../providers/userContextProvider';
import { WorkflowCompletionContext } from '../../workflowCompletion.visual';
import { useCountrySpecificBackendIdDocumentTypes } from '../../../../../interfaces/IdVerification';

dayjs.extend(utc);

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        physicalAddress {
          province
        }
        latestIdVerification {
          id
          documentExpiryDate
          documentID
          documentIssuingJurisdication
          documentType
          methodOfIDVerification
          verifiedDate
          userName
          secondaryDocumentIssuingJurisdication
          secondaryDocumentType
          secondaryDocumentExpiryDate
          secondaryDocumentID
          secondaryDocumentUserName
          creditFileSource
        }
      }
    }
  }
`;

const FETCH_USER_FOR_PERSONA = gql`
  query fetchUserForPersona($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        physicalAddress {
          province
          jurisdiction
        }
        iDVerified
        iDCheckCompleted
        iDCheckRetrigger
        firstName
        middleName
        lastName
        dateOfBirth
        primaryEmail
        phone
        language
        organization { id externalProvider { idVerificationProvider { templateId } } }
      }
    }
  }
`;

const CREATE_ID = gql`
  mutation createUserIDVerificationInformation($input: CreateUserIDVerificationInformationInput!) {
    createUserIDVerificationInformation(input: $input) {
      idVerification {
        id
      }
    }
  }
`;

const UPDATE_ID = gql`
  mutation updateUserIDVerificationInformation($input: UpdateUserIDVerificationInformationInput!) {
    updateUserIDVerificationInformation(input: $input) {
      idVerification {
        id
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const IdVerification = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const { showToast } = useGlobalToast();
  const graphqlClient = useApolloClient();
  const { i18n } = useTranslation();
  const { activeOrganization } = useContext(UserContext);
  const [idData, setIdData] = useState<any>({
    idVerificationId: undefined,
    documentExpiryDate: null,
    documentID: null,
    documentIssuingJurisdication: null,
    documentType: null,
    methodOfIDVerification: null,
    verifiedDate: null,
    userName: null,
    secondaryDocumentIssuingJurisdication: undefined,
    secondaryDocumentType: undefined,
    secondaryDocumentExpiryDate: undefined,
    secondaryDocumentID: undefined,
    creditFileSource: undefined,
  });
  const { idDocumentTypes, loading: idDocTypesLoading } = useCountrySpecificBackendIdDocumentTypes();

  const [documentData, setDocumentData] = useState<any>({
    documentOnefile: null,
    documentTwoFile: null,
    documentName: null,
    secondaryDocumentName: null,
  });
  const { setHasQuebecAddress } = useContext(WorkflowCompletionContext);

  const query = options.manualVerification ? FETCH_USER : FETCH_USER_FOR_PERSONA;
  const { data, loading } = useQuery(query, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const [personaTemplateId, setPersonaTemplateId] = useState('');
  const { loading: integrationIsLoading } = useQuery(FETCH_INTEGRATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          type: 'ID_VERIFICATION',
        },
      },
    },
    onCompleted: (response): void => {
      const personaConfig: any = response.fetchIntegrations
        .integrations.find((integration: any) => integration.provider === 'PERSONA');
      if (personaConfig) {
        setPersonaTemplateId(personaConfig.configuration.templateId || process.env.REACT_APP_PERSONA_TEMPLATE_ID || '');
      } else {
        setPersonaTemplateId(process.env.REACT_APP_PERSONA_TEMPLATE_ID || '');
      }
    },
    onError: (): void => {
      setPersonaTemplateId(process.env.REACT_APP_PERSONA_TEMPLATE_ID || '');
    },
  });
  const [personaFields, setPersonaFields] = useState({
    nameFirst: '',
    nameMiddle: '',
    nameLast: '',
    birthdate: '',
    emailAddress: '',
    phoneNumber: '',
  });

  const doDocumentUpload = async (verificationId: string, document: any) => {
    const fileUploadProps: FileUploaderProps = {
      file: document.file,
      createFileInput: {
        objectType: FileDocumentObjectTypes.USER,
        objectId: userId,
        userId,
        fileName: document.file?.name,
        type: FileDocumentTypes.ID_VERIFICATION,
        name: document.documentName,
        mediaType: document.file?.type,
        permissionType: 'PUBLIC',
        sourceType: document?.secondaryDocumentType ?? 'ID_DOCUMENT',
        sourceId: verificationId,
      },
      onSuccess: () => {
        showToast({ severity: 'success', message: `ID document uploaded successfully: ${document.file.name}` });
      },
      refetchQueries: [
        FETCH_DOCUMENTS,
        FETCH_IDS,
      ],
      apolloClient: graphqlClient,
    };

    await fileUploader(fileUploadProps);
  };

  const [createdId, { loading: createIdLoading }] = useMutation(CREATE_ID, {
    onCompleted: async (res: any) => {
      const cratedId = res?.createUserIDVerificationInformation?.idVerification?.id || '';
      if (idData.methodOfIDVerification !== 'DUAL_METHOD' && documentData.documentOnefile) {
        await doDocumentUpload(cratedId, {
          file: documentData.documentOnefile,
          documentName: documentData.documentName,
        });
      }
      if (idData.methodOfIDVerification === 'DUAL_METHOD' && documentData.documentOnefile && documentData.documentTwofile) {
        await doDocumentUpload(cratedId, {
          file: documentData.documentOnefile,
          documentName: documentData.documentName,
        });
        await doDocumentUpload(cratedId, {
          file: documentData.documentTwofile,
          documentName: documentData.secondaryDocumentName,
        });
      }
      setHasQuebecAddress(false);
      onNext();
    },
    variables: {
      input: {
        ...idData,
        verifiedDate: dayjs().utc().format('YYYY-MM-DD'),
        userId,
      },
    },
  });

  const [updateId, { loading: updateIdLoading }] = useMutation(UPDATE_ID, {
    onCompleted: () => {
      setHasQuebecAddress(false);
      onNext();
    },
    variables: {
      input: {
        ...idData,
      },
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setHasQuebecAddress(false);
      onNext();
    },
  });

  const onPersonaComplete = ({ inquiryId, status }: { inquiryId: string; status: string }) => {
    updateUser({
      variables: {
        input: {
          userId,
          iDCheckCompleted: true,
          iDCheckRetrigger: false,
          ...(!['completed', 'pending'].includes(status) ? {
            iDCheckRetrigger: true,
            complianceState: ComplianceStates.NEEDS_REVIEW,
            complianceIssueSource: `Persona was not initialized on their device -- Persona sdk logs: ${inquiryId}`,
          } : {}),
        },
      },
    });
  };

  const onPersonaError = (error: InquiryError) => {
    updateUser({
      variables: {
        input: {
          userId,
          iDCheckCompleted: true,
          iDCheckRetrigger: true,
          complianceState: ComplianceStates.NEEDS_REVIEW,
          complianceIssueSource: `Persona was not initialized on their device -- Persona sdk logs: ${error.code}`,
        },
      },
    });
  };

  const continueFunc = () => {
    if (idData.idVerificationId) {
      updateId();
    } else {
      createdId();
    }
  };

  useEffect(() => {
    if (data) {
      const userData: User = data.fetchUser.user;
      setHasQuebecAddress(userData.physicalAddress?.province === 'QC' || userData.physicalAddress?.jurisdiction === 'CA_QC');
      if (userData.latestIdVerification && options.manualVerification) {
        setIdData({
          idVerificationId: userData.latestIdVerification?.id || undefined,
          documentExpiryDate: userData.latestIdVerification?.documentExpiryDate || undefined,
          documentID: userData.latestIdVerification?.documentID || undefined,
          documentIssuingJurisdication: userData.latestIdVerification?.documentIssuingJurisdication || undefined,
          documentType: userData.latestIdVerification?.documentType || undefined,
          methodOfIDVerification: userData.latestIdVerification?.methodOfIDVerification || undefined,
          verifiedDate: userData.latestIdVerification?.verifiedDate || undefined,
          userName: userData.latestIdVerification?.userName || undefined,
          secondaryDocumentIssuingJurisdication: userData.latestIdVerification?.secondaryDocumentIssuingJurisdication || undefined,
          secondaryDocumentType: userData.latestIdVerification?.secondaryDocumentType || undefined,
          secondaryDocumentExpiryDate: userData.latestIdVerification?.secondaryDocumentExpiryDate || undefined,
          secondaryDocumentID: userData.latestIdVerification?.secondaryDocumentID || undefined,
          creditFileSource: userData.latestIdVerification?.creditFileSource || undefined,
          secondaryDocumentUserName: userData.latestIdVerification?.secondaryDocumentUserName || undefined,
        });
      } else if (userData && options.automatedVerification) {
        setPersonaFields({
          nameFirst: userData.firstName ?? '',
          nameMiddle: userData.middleName ?? '',
          nameLast: userData.lastName ?? '',
          birthdate: userData.dateOfBirth ? dayjs(userData.dateOfBirth).utc().format('YYYY-MM-DD') : '',
          emailAddress: userData.primaryEmail ?? '',
          phoneNumber: userData.phone ?? '',
        });
        const iDCheckCompleted = userData.iDCheckCompleted ?? false;
        const iDCheckRetrigger = userData.iDCheckRetrigger ?? false;
        if (iDCheckCompleted && !iDCheckRetrigger) {
          setHasQuebecAddress(false);
          // skip Persona flow
          onNext();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const openPersona = (): void => {
    const client: Client = new Persona.Client({
      templateId: personaTemplateId,
      environment: process.env.REACT_APP_ENV === 'production' ? 'production' : 'sandbox',
      referenceId: userId,
      language: i18n.language,
      fields: personaFields,
      onReady: () => client.open(),
      onComplete: onPersonaComplete,
      onError: onPersonaError,
    });
  };

  return (
    <IdVerificationVisual
      options={options}
      idDocumentTypes={idDocumentTypes}
      idData={idData}
      updateId={setIdData}
      continueFunc={continueFunc}
      openPersona={openPersona}
      documentData={documentData}
      setDocumentData={setDocumentData}
      loading={loading || createIdLoading || updateIdLoading || stepLoading || integrationIsLoading || idDocTypesLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default IdVerification;
