/* eslint-disable react-hooks/exhaustive-deps */
import { translateBackend } from 'assets/i18n/config';
import {
  Checkbox,
  Grid, MenuItem, SelectField, Stack, Switch, TranslatableTextField, Typography,
} from 'ovComponents';
import { Alert } from '@mui/material';
import { CountryCodes } from '@onevesthq/ov-enums';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../../providers/userContextProvider';
import { getCountryName } from '../../resources';
import { TaxIdTypes, TranslatedString } from '../../../interfaces';

export const CountrySpecificTaxIdEdit = ({
  option, options, setOptions, i, type,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number, type?: string,
}) => {
  const { t } = useTranslation('workflowCompletions');
  const { activeOrganization } = useContext(UserContext);

  const { countries } = activeOrganization.applicableLocalization;

  useEffect(() => {
    const newOptions = [...options];
    newOptions[i].value = { ...option.value, label: { en: '_' } }; // There's no 'label' option but backends needs one
    setOptions(newOptions);
  }, []);

  const allowCanada = countries.includes(CountryCodes.CA);
  const allowUSA = countries.includes(CountryCodes.US);

  const generalDisable = !(allowCanada || allowUSA || option.value?.enabled);

  const longTaxIdLabel = (theType: TaxIdTypes) => `${t(`taxId:${theType}`)} (${t(`taxId:abbreviated.${theType}`)})`;

  const setCustomLabelString = (forType: TaxIdTypes, newValue: TranslatedString) => {
    const newOptions = [...options];
    newOptions[i].value = { ...option.value, labelByType: { ...option.value.labelByType, [forType]: newValue } };
    setOptions(newOptions);
  };

  // Turned "Custom Labels" off? Reset all strings to default.
  useEffect(() => {
    if (option.value.customLabels === false) {
      const newOptions = [...options];
      newOptions[i].value = { ...option.value, labelByType: option.default.labelByType };
      setOptions(newOptions);
    }
  }, [option.value.customLabels]);

  return (
    <>
      <Grid container display='flex' flexDirection='row' sx={{ mb: 2 }} justifyContent='space-between' spacing={1} alignItems='end'>
        <Grid item xs={6}>
          <Typography variant='titleMedium' sx={{ my: '0.5em' }}>
            {translateBackend(option.label)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SelectField
            label='Required'
            value={option.value.required}
            disabled={!option.value?.enabled || generalDisable}
            fullWidth
            onChange={(e: any) => {
              const newOptions = [...options];
              newOptions[i].value = { ...option.value, required: e.target.value };
              setOptions(newOptions);
            }}
          >
            <MenuItem value='ERROR'>Error</MenuItem>
            <MenuItem value='WARNING'>Warning</MenuItem>
            <MenuItem value='NOT_REQUIRED'>Not Required</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={2} justifyContent='center' display='flex' sx={{ mt: 2 }}>
          <Switch sx={{ mb: 1 }}
            checked={option.value?.enabled}
            onChange={(e: any) => {
              const newOptions = [...options];
              newOptions[i].value = { ...option.value, enabled: e };
              setOptions(newOptions);
            }}
            disabled={generalDisable}
          />
        </Grid>

        {option.value?.enabled && allowUSA && (
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Checkbox
                size='small'
                label={t('residencyInformation.allowITIN')}
                checked={option.value.allowITIN}
                onChange={(onoff: boolean) => {
                  const newOptions = [...options];
                  newOptions[i].value = { ...option.value, allowITIN: onoff };
                  setOptions(newOptions);
                }}
              />
              <Checkbox
                size='small'
                label={t('residencyInformation.allowITINni')}
                checked={option.value.allowITINni}
                onChange={(onoff: boolean) => {
                  const newOptions = [...options];
                  newOptions[i].value = { ...option.value, allowITINni: onoff };
                  setOptions(newOptions);
                }}
              />
            </Stack>
          </Grid>
        )}

        {generalDisable && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              <Typography variant='labelSmall'>{t('residencyInformation.taxIdOnlyApplicableIn')}</Typography>
            </Alert>
          </Grid>
        )}

        { option.value?.enabled && <>
          <Grid item xs={12}>
            <Checkbox
              size='small'
              label={t('residencyInformation.customTaxIdLabelsEnable')}
              checked={option.value.customLabels}
              onChange={(onoff: boolean) => {
                const newOptions = [...options];
                newOptions[i].value = { ...option.value, customLabels: onoff };
                setOptions(newOptions);
              }}
            />
          </Grid>
          { option.value.customLabels && <>
            {allowCanada && <>
              <Grid item xs={12}>
                <TranslatableTextField
                  label={t('residencyInformation.customTaxIdLabelfor', { type: 'SIN' })}
                  value={option.value.labelByType?.SIN ?? option.default.labelByType?.SIN}
                  fullWidth
                  onChange={(value) => setCustomLabelString(TaxIdTypes.SIN, value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TranslatableTextField
                  label={t('residencyInformation.customTaxIdLabelfor', { type: 'BN' })}
                  value={option.value.labelByType?.BN ?? option.default.labelByType?.BN}
                  fullWidth
                  onChange={(value) => setCustomLabelString(TaxIdTypes.BN, value)}
                />
              </Grid>
            </>}
            {allowUSA && <>
              <Grid item xs={12}>
                <TranslatableTextField
                  label={t('residencyInformation.customTaxIdLabelfor', { type: 'SSN' })}
                  value={option.value.labelByType?.SSN ?? option.default.labelByType?.SSN}
                  fullWidth
                  onChange={(value) => setCustomLabelString(TaxIdTypes.SSN, value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TranslatableTextField
                  label={t('residencyInformation.customTaxIdLabelfor', { type: 'ITIN' })}
                  value={option.value.labelByType?.ITIN ?? option.default.labelByType?.ITIN}
                  fullWidth
                  onChange={(value) => setCustomLabelString(TaxIdTypes.ITIN, value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TranslatableTextField
                  label={t('residencyInformation.customTaxIdLabelfor', { type: 'EIN' })}
                  value={option.value.labelByType?.EIN ?? option.default.labelByType?.EIN}
                  fullWidth
                  onChange={(value) => setCustomLabelString(TaxIdTypes.EIN, value)}
                />
              </Grid>
            </>}
          </>}
        </>
        }

        {option.value?.enabled && (
          <Grid item xs={12}>
            {allowCanada && (
              <Alert severity='info'>
                {allowUSA && <b>{getCountryName('CA')}:&nbsp;</b>}
                {t('residencyInformation.taxIdExplanationIndividuals1', { type: longTaxIdLabel(TaxIdTypes.SIN) })}. <br />
                {t('residencyInformation.taxIdExplanationNonIndividuals', { type: longTaxIdLabel(TaxIdTypes.BN) })}.
              </Alert>
            )}
            {allowUSA && (
              <Alert severity='info'>
                {allowCanada && <b>{getCountryName('US')}:&nbsp;</b>}
                {option.value.allowITIN
                  ? t('residencyInformation.taxIdExplanationIndividuals2', { type1: longTaxIdLabel(TaxIdTypes.SSN), type2: longTaxIdLabel(TaxIdTypes.ITIN) })
                  : t('residencyInformation.taxIdExplanationIndividuals1', { type: longTaxIdLabel(TaxIdTypes.SSN) })
                }.
                <br />
                {option.value.allowITINni
                  ? t('residencyInformation.taxIdExplanationNonIndividuals3', { type1: longTaxIdLabel(TaxIdTypes.EIN), type2: longTaxIdLabel(TaxIdTypes.SSN), type3: longTaxIdLabel(TaxIdTypes.ITIN) })
                  : t('residencyInformation.taxIdExplanationNonIndividuals2', { type1: longTaxIdLabel(TaxIdTypes.EIN), type2: longTaxIdLabel(TaxIdTypes.SSN) })
                }.
              </Alert>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
