/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { union, remove, isEqual } from 'lodash/fp';
import { Link } from 'react-router-dom';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { useGlobalStats } from '../../../../providers/globalStatsHooks';
import { usePageState } from '../../../../util/usePageState';
import DownloadReconciliation from '../../../../pages/reconcilitation/components/downloadReconciliation';
import { generateClientNameString } from '../../../../util';
import { DateTime } from '../../../../components/misc/dateTime/dateTime';

import { Box, Skeleton } from '../../../1-primative';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TextField,
  MenuItem,
  Card,
  CardContent,
  TableHeadCell,
  Badge,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';
import { controlColumnStyles, controlWrapperStyles } from './activities';
import RunReconciliationModel from './runReconciliationModel';

export const FETCH_FLAGS_QUERY = `query fetchFlags($input: FetchFlagsInput!) {
  fetchFlags(input: $input) {
    flags {
      id
      objectId
      objectType
      type
      message
      details
      state
      createdAt
      organization {
        id
      }
      account {
        id
        custodianAccountNumber
        user {
          id
          firstName
          lastName
          entityName
        }
      }
    }
    totalCount
  }
}`;

const FETCH_FLAGS = gql`${FETCH_FLAGS_QUERY}`;

const TRANSITION_FLAG = gql`
  mutation transitionFlag($input: TransitionFlagInput!) {
    transitionFlag(input: $input) {
      flag {
        id
      }
    }
  }
`;

const BULK_TRANSITION_FLAGS = gql`
  mutation bulkTransitionFlags($input: BulkTransitionFlagsInput!) {
    bulkTransitionFlags(input: $input) {
      flags {
        id
      }
    }
  }
`;

const FETCH_FLAG_TYPES = gql`
  query fetchFlagTypes {
    __type(name: "FlagTypes") {
      enumValues { name }
    }
  }
`;

const isFilterActive = ({
  flagState,
  flagType,
}: {
  flagState: string,
  flagType: string | undefined,
}) => {
  if (flagState !== 'FLAGGED') return true;
  if (flagType !== undefined) return true;
  return false;
};

const Flags = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['reconciliation']);
  const { flagsRefetch } = useGlobalStats();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(25, 'pageSize');
  const [open, setOpen] = useState(false);
  const [activeFlag, setActiveFlag] = useState<any | null>(null);
  const [flagState, setFlagState] = usePageState('FLAGGED', 'flag');
  const [flagType, setFlagType] = usePageState<string | undefined>(undefined, 'type');
  const [selected, setSelected] = useState<string[]>([]);
  const [transitionFlag] = useMutation(TRANSITION_FLAG);
  const [bulkTransitionFlag, bulkOptions] = useMutation(BULK_TRANSITION_FLAGS);
  const [flagTypes, setFlagTypes] = useState([]);

  const queryFilter = {
    state: flagState,
    type: flagType,
    organizationId: activeOrganization.id,
  };

  const { loading, data, refetch, previousData } = useQuery(FETCH_FLAGS, {
    variables: {
      input: {
        filter: { ...queryFilter },
        pagination: {
          sortField: 'createdAt',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  useQuery(FETCH_FLAG_TYPES, {
    onCompleted: (newData) => {
      setFlagTypes(newData.__type.enumValues.map((enumValue: any) => enumValue.name));
    },
  });

  useEffect(() => function cleanupOnUnmount() {
    setPage(1);
    setPageSize(25);
    setFlagState('FLAGGED');
    setFlagType(undefined);
  }, []);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
          <FilterModal filterExists={isFilterActive({
            flagState,
            flagType,
          })}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                select
                value={flagType || 'any'}
                onChange={(e: any) => {
                  setFlagType(e.target.value !== 'any' ? e.target.value : undefined);
                  setPage(1);
                }}
                label={t('type')}
                fullWidth
              >
                <MenuItem key="any" value="any">{t('any')}</MenuItem>
                {flagTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </TextField>

              <TextField
                select
                value={flagState}
                onChange={(e: any) => {
                  setFlagState(e.target.value);
                  setPage(1);
                }}
                label={t('state')}
                fullWidth
              >
                <MenuItem value="FLAGGED">{t('flagState.FLAGGED')}</MenuItem>
                <MenuItem value="FIXED">{t('flagState.FIXED')}</MenuItem>
              </TextField>
            </Box>
          </FilterModal>

          {permissions.includes('read:api_exports') && (
            <DownloadReconciliation
              tab="flags"
              queryFilter={queryFilter}
            />
          )}
          <Button
            label={t('flagState.ALL_FIXED')}
            variant="filled"
            color="primary"
            disabled={isEqual(selected, []) || bulkOptions.loading}
            loading={bulkOptions.loading}
            onClick={async () => {
              await bulkTransitionFlag({
                variables: {
                  input: { flagIds: selected, transition: 'fixed' },
                },
              });
              refetch();
              flagsRefetch();
            }}
          />
          {permissions.includes('write:custodian_activity_basic') && (
            <Box>
              <RunReconciliationModel afterCreate={() => {}}/>
            </Box>
          )}
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Checkbox
                    checked={!loading && data?.fetchFlags?.flags?.length > 0
                      && isEqual(selected, data.fetchFlags.flags.map((x: any) => x.id))}
                  onChange={(checked: boolean) => {
                    if (!loading && data?.fetchFlags?.flags) {
                      setSelected(checked
                        ? data.fetchFlags.flags.map((x: any) => x.id)
                        : []);
                    }
                  }}
                  customStyle={{
                    margin: 0,
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
                </Box>
              </TableHeadCell>
              <TableHeadCell>{t('table.client')}</TableHeadCell>
              <TableHeadCell>{t('table.type')}</TableHeadCell>
              <TableHeadCell>{t('table.custodianAccountNumber')}</TableHeadCell>
              <TableHeadCell>{t('table.message')}</TableHeadCell>
              <TableHeadCell>{t('table.createdAt')}</TableHeadCell>
              <TableHeadCell>{t('table.state')}</TableHeadCell>
              <TableHeadCell right>{t('table.actions')}</TableHeadCell>
            </TableRow>

            {loading && !previousData && [...Array(pageSize)].map((_, i) => (
              <TableRow key={i}>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
              </TableRow>
            ))}

            {(data || previousData)?.fetchFlags?.flags?.map((flag: any) => {
              const userId = flag.account?.user?.id;
              const accountId = flag.account?.id;
              const custodianAccountNumber = flag.account?.custodianAccountNumber;
              const user = flag.account?.user;

              return (
                <TableRow
                  hover
                  pointer
                  key={flag.id}
                  onClick={() => {
                    setOpen(true);
                    setActiveFlag(flag);
                  }}
                  selected={activeFlag?.id === flag.id}
                >
                  <TableCell sx={controlColumnStyles}>
                    <div style={controlWrapperStyles}>
                      <Checkbox
                        checked={selected.includes(flag.id)}
                        onClick={(e: any) => e.stopPropagation()}
                        onChange={(checked: boolean) => {
                          setSelected(checked
                            ? union(selected, [flag.id])
                            : remove((x: string) => x === flag.id, selected));
                        }}
                        customStyle={{
                          margin: 0,
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    {user?.entityName || (user?.firstName && user?.lastName) ? (
                      <Link
                        to={`/clients/${userId}`}
                        target="_blank"
                        onClick={(e: any) => e.stopPropagation()}
                      >
                        {generateClientNameString(user, false, true)}
                      </Link>
                    ) : null}
                  </TableCell>
                  <TableCell><Badge label={flag.type} /></TableCell>
                  <TableCell>
                    <Link
                      to={`/clients/${userId}/account/${accountId}`}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {custodianAccountNumber || '-'}
                    </Link>
                  </TableCell>
                  <TableCell>{flag.message}</TableCell>
                  <TableCell><DateTime variant="subtitle3" date={flag.createdAt} /></TableCell>
                  <TableCell>
                    <Badge
                      label={flag.state}
                      color={flag.state === 'FLAGGED' ? 'negative' : 'positive'}
                      variant="text"
                    />
                  </TableCell>
                  <TableCell right>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        label={t('flagState.FIXED')}
                        variant="filled"
                        color="primary"
                        size="sm"
                        disabled={flag.state !== 'FLAGGED'}
                        onClick={async (e) => {
                          e.stopPropagation();
                          await transitionFlag({
                            variables: {
                              input: { flagId: flag.id, transition: 'fixed' },
                            },
                          });
                          refetch();
                          flagsRefetch();
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchFlags?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{activeFlag?.message}</DialogTitle>
        <DialogContent>
          {activeFlag?.details?.account?.user && (
            <Button
              label={`${t('goTo')} ${activeFlag.details.clientName}`}
              variant="filled"
              color="primary"
              fullWidth
              component={Link}
              target="_blank"
              to={`/clients/${activeFlag.details.account?.user?._id}`}
            />
          )}
          <pre>{JSON.stringify(activeFlag?.details, null, 2)}</pre>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Flags;
