import { IntegrationProvider, IntegrationType } from 'interfaces';
import { BulkUpsertRequestObjectTypes } from '../../../../../interfaces/bulkUpsertRequest';

export interface IntegrationConfigInterface {
  [key: string]: {
    scopeOptions?: string[],
    integration: {
      type: IntegrationType | string;
      provider: IntegrationProvider | string;
      configuration?: string[];
    }[];
  };
}

export const integrationConfig: IntegrationConfigInterface = {
  BANKING: {
    integration: [
      {
        type: IntegrationType.BANKING,
        provider: IntegrationProvider.FLINKS,
        configuration: ['clientIframeUrl', 'customerId', 'instanceId', 'enableNameMatching'],
      },
      {
        type: IntegrationType.BANKING,
        provider: IntegrationProvider.PLAID,
        configuration: ['customerId', 'plaidSecret', 'enableNameMatching'],
      },
    ],
  },
  ID_VERIFICATION: {
    integration: [
      {
        type: IntegrationType.ID_VERIFICATION,
        provider: IntegrationProvider.PERSONA,
        configuration: ['templateId', 'verificationPassedWebhookSecret', 'inquiryUpdateWebhookSecret'],
      },
    ],
  },
  ADDRESS: {
    integration: [
      {
        type: IntegrationType.ADDRESS,
        provider: IntegrationProvider.GOOGLE,
        configuration: ['apiKey'],
      },
      {
        type: IntegrationType.ADDRESS,
        provider: IntegrationProvider.CANADA_POST,
        configuration: ['apiKey'],
      },
    ],
  },
  MARKET_DATA: {
    integration: [
      {
        type: IntegrationType.MARKET_DATA,
        provider: IntegrationProvider.IEX_CLOUD,
        configuration: ['apiToken'],
      },
      {
        type: IntegrationType.MARKET_DATA,
        provider: IntegrationProvider.LSEG,
        configuration: ['widgetUsername', 'widgetName', 'widgetPrivateKey', 'widgetPublicKey', 'rdpUsername', 'rdpPassword', 'rdpClientId'],
      },
      {
        type: IntegrationType.MARKET_DATA,
        provider: IntegrationProvider.QUOTE_MEDIA,
        configuration: ['username', 'password', 'webmasterId'],
      },
    ],
  },
  FINANCIAL_DATA_WIDGETS: {
    integration: [
      {
        type: IntegrationType.FINANCIAL_DATA_WIDGETS,
        provider: IntegrationProvider.LSEG,
        configuration: ['privateKey', 'publicKey', 'username', 'name'],
      },
    ],
  },
  FORM: {
    integration: [
      {
        type: IntegrationType.FORM,
        provider: IntegrationProvider.ONESPAN,
        configuration: ['baseUrl', 'apiKey'],
      },
    ],
  },
  ANALYTICS: {
    integration: [
      {
        type: IntegrationType.ANALYTICS,
        provider: IntegrationProvider.FIREBASE,
        configuration: ['apiKey', 'authDomain', 'projectId', 'messagingSenderId', 'appId', 'measurementId'],
      },
      {
        type: IntegrationType.ANALYTICS,
        provider: IntegrationProvider.MIXPANEL,
        configuration: ['TBD'],
      },
    ],
  },
  DATA_INGESTION: {
    scopeOptions: Object.keys(BulkUpsertRequestObjectTypes),
    integration: [
      {
        type: IntegrationType.DATA_INGESTION,
        provider: IntegrationProvider.ATB,
      },
      {
        type: IntegrationType.DATA_INGESTION,
        provider: IntegrationProvider.ENVESTNET,
      },
    ],
  },
  STATISTICS: {
    scopeOptions: ['timeWeightedReturn', 'moneyWeightedReturn', 'simpleRateOfReturn', 'holdings', 'projectedIncome', 'historySnapshots', 'contributions'],
    integration: [
      {
        type: IntegrationType.STATISTICS,
        provider: IntegrationProvider.ATB,
      },
      {
        type: IntegrationType.STATISTICS,
        provider: IntegrationProvider.ENVESTNET,
      },
    ],
  },
  CHAT_AGENT: {
    integration: [
      {
        type: IntegrationType.CHAT_AGENT,
        provider: IntegrationProvider.ZENDESK,
        configuration: ['clientRoleKey', 'advisorRoleKey'],
      },
    ],
  },
};
