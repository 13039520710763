import { gql } from '@apollo/client';

export const FETCH_PORTFOLIO_TEMPLATES = gql`
  query fetchPortfolioTemplates($input: FetchPortfolioTemplatesInput!) {
    fetchPortfolioTemplates(input: $input) {
      portfolioTemplates {
        id
        assetClassBranches {
          id
          targetPercentage
          assetClass {
            id
            key
            name
          }
        }
        createdAt
        id
        name
        state
        translatedName {
          en
        }
        updatedAt
        translatedTemplateDescription {
          en
        }
      }
      totalCount
    }
  }
`;

export const CREATE_PORTFOLIO_TEMPLATE = gql`
  mutation createPortfolioTemplate($input: CreatePortfolioTemplateInput!) {
    createPortfolioTemplate(input: $input) {
      portfolioTemplate {
        assetClassBranches {
          assetClass {
            description
            id
            includeCash
            key
            name
            state
          }
          id
          targetPercentage
        }
        createdAt
        id
        name
        state
        translatedName {
          en
          fr
        }
        translatedTemplateDescription {
          en
          fr
        }
        updatedAt
      }
    }
  }
`;

export const UPDATE_PORTFOLIO_TEMPLATE = gql`
  mutation updatePortfolioTemplate($input: UpdatePortfolioTemplateInput!) {
    updatePortfolioTemplate(input: $input) {
      portfolioTemplate {
        id
        assetClassBranches {
          id
          targetPercentage
          assetClass {
            id
            key
            name
          }
        }
        createdAt
        id
        name
        state
        translatedName {
          en
        }
        updatedAt
        translatedTemplateDescription {
          en
        }
      }
    }
  }
`;

export const TRANSITION_PORTFOLIO_TEMPLATE = gql`
  mutation transitionPortfolioTemplate($input: TransitionPortfolioTemplateInput!) {
    transitionPortfolioTemplate(input: $input) {
      portfolioTemplate {
        id
        assetClassBranches {
          id
          targetPercentage
          assetClass {
            id
            key
            name
          }
        }
        createdAt
        id
        name
        state
        translatedName {
          en
        }
        updatedAt
        translatedTemplateDescription {
          en
        }
      }
    }
  }
`;
