import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { kebabCase } from 'lodash';

import {
  Button, Form, GenericState, SelectField, Typography,
} from 'ovComponents';
import { getBackendLanguage } from 'assets/i18n/config';
import { useQuery } from '@apollo/client';
import { PieChartTemplates } from '../../../../../3-pattern';
import { ovAnalyticsEvents } from '../../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../../providers/analyticsProvider';
import { PortfolioTemplate, CreateModelPortfolioOptions } from '../createModelPortfolio';
import { UserContext } from '../../../../../../providers/userContextProvider';
import { FETCH_PORTFOLIO_TEMPLATES } from '../queries';

interface SelectPortfolioTemplateProps {
  options: CreateModelPortfolioOptions['selectTemplate'];
  loading: boolean;
  continueFunc: () => void;
  workflowCompletion?: {
    workflow?: {
      id: string;
      name: string;
    };
    id: string;
    objectId: string;
    objectType: string;
  };
  selectedPortfolioTemplate: PortfolioTemplate | undefined;
  onTemplateSelect: (template: PortfolioTemplate) => void;
  onCreateNew: () => void;
}

export const SelectPortfolioTemplate = ({
  options,
  loading,
  continueFunc,
  workflowCompletion,
  selectedPortfolioTemplate,
  onTemplateSelect,
  onCreateNew,
}: SelectPortfolioTemplateProps) => {
  const { t } = useTranslation(['shared', 'modelPortfolios']);
  const [focused, setFocused] = useState<string[]>([]);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { activeOrganization } = useContext(UserContext);
  const [templateData, setTemplateData] = useState<PortfolioTemplate[]>([]);

  const { loading: templateLoading, refetch } = useQuery(FETCH_PORTFOLIO_TEMPLATES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization?.id || '',
          state: GenericState.ACTIVE,
        },
        pagination: {
          offset: 0,
          perPage: 25,
          sortDesc: true,
          sortField: 'createdAt',
          collation: {
            locale: 'en',
            strength: 1,
          },
        },
      },
    },
    skip: !activeOrganization?.id,
    onCompleted: (response) => {
      setTemplateData(response?.fetchPortfolioTemplates?.portfolioTemplates || []);
    },
  });

  const validate = () => {
    if (options.templateSelector.enabled && !selectedPortfolioTemplate) {
      setFocused(['templateName']);
      return false;
    }
    return true;
  };

  const submit = () => {
    if (validate()) {
      sendAnalytic(ovAnalyticsEvents.workflowsSelectPortfolioTemplateContinueButtonSelect, {
        workflowStepTitle: options.templateSelector.label[getBackendLanguage()],
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  const handleCreateNew = () => {
    sendAnalytic(ovAnalyticsEvents.workflowsSelectPortfolioTemplateCreateNewButtonSelect, {
      workflowStepTitle: options.templateSelector.label[getBackendLanguage()],
      workflowId: workflowCompletion?.workflow?.id,
      workflowName: workflowCompletion?.workflow?.name,
      activeWorkflowCompletionId: workflowCompletion?.id,
      objectId: workflowCompletion?.objectId,
      objectType: workflowCompletion?.objectType,
    });
    onCreateNew();
  };

  return (
    <Form onSubmit={submit}>
      {options.label[getBackendLanguage()] && (
        <Typography variant="displayLarge" sx={{
          mt: 1,
          mb: 2,
          fontWeight: 400,
          fontSize: '40px',
          fontFamily: 'Tiempos Text',
        }}>
          {options.label[getBackendLanguage()]}
          {options.description[getBackendLanguage()] && (
            <Typography variant="bodyLarge" sx={{ mt: 1 }}>
              {options.description[getBackendLanguage()]}
            </Typography>
          )}
        </Typography>
      )}

      <Grid container spacing={2}>
        {options.templateSelector.enabled && (
          <Grid item xs={12}>
            <SelectField
              testId="portfolio-template"
              onChange={(e: any) => {
                const selectedTemplate = templateData.find((template) => template.id === e.target.value);
                if (selectedTemplate) {
                  onTemplateSelect(selectedTemplate);
                }
                setFocused([]);
              }}
              onClick={() => refetch()}
              disabled={loading || templateLoading}
              label={options.templateSelector.label[getBackendLanguage()] || ''}
              placeholder={options.templateSelector.label[getBackendLanguage()] || ''}
              fullWidth
              value={selectedPortfolioTemplate?.id || ''}
              onBlur={() => setFocused([...focused, 'templateSelector'])}
              error={!selectedPortfolioTemplate && focused.includes('templateSelector')}
            >
              {templateData.map((template) => (
                <MenuItem
                  key={template.id}
                  value={template.id}
                  data-testid={`template-${kebabCase(template.translatedName[getBackendLanguage()] || '')}`}
                >
                  {template.translatedName[getBackendLanguage()]}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}

        {options.assetClassAllocation.enabled && (
          <Grid item xs={12}>
            <PieChartTemplates
              assetClassBranches={selectedPortfolioTemplate?.assetClassBranches || []}
              variant="outlined"
            />
          </Grid>
        )}
      </Grid>

      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Button
          dataTestId="create-new-template-button"
          label={t('modelPortfolios:createNewTemplate')}
          onClick={handleCreateNew}
          disabled={loading}
          variant="tonal"
          sx={{ mt: 3 }}
        />
        <Button
          dataTestId="select-portfolio-template-continue-button"
          label={t('continue')}
          disabled={loading || !selectedPortfolioTemplate}
          sx={{ mt: 3, textAlign: 'center' }}
          type="submit"
        />
      </Box>
    </Form>
  );
};
