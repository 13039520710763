/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import { gql, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import {
  Button, Typography, Form, SelectionTile, Box, Link,
} from '../../../..';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { UserContext } from '../../../../../providers/userContextProvider';
import { WorkflowContext } from '../../workflowCompletion';
import { updateContextTransfer } from '../../../../../util/updateWorkflowContext';
import { DepositTransferContext } from '../../../depositWorkflow/depositWorkflow';
import { FieldOptions, FormErrors, validateFields } from '../utils';

const FETCH_USER_FOR_PERSONA = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        physicalAddress {
          province
        }
        iDVerified
        iDCheckCompleted
      }
    }
  }
`;

export const DepositType = ({
  options, onNext, direction, dummyDeposit, setDummyDeposit, stepLoading,
}: {
  options: any, onNext: any, direction: 'FORWARD' | 'BACKWARD', dummyDeposit?: any, setDummyDeposit?: any, stepLoading?: boolean
}) => {
  const { t } = useTranslation('client');
  const { transferData, setTransferData } = useContext(DepositTransferContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const applicableData = dummyDeposit ?? (Object.keys(transferData).length !== 0 ? transferData : workflowData);
  const setApplicationContext = setDummyDeposit ?? (Object.keys(transferData).length !== 0 ? setTransferData : undefined);
  const setWorkflowCompletionContext = Object.keys(transferData).length === 0 ? setWorkflowData : undefined;
  const { activeEntity, userContext } = useContext(UserContext);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const { sys } = useThemeTokens();
  const fieldOptions: FieldOptions = useMemo(() => ({
    type: { required: true },
  }), []);

  const { data, loading } = useQuery(FETCH_USER_FOR_PERSONA, {
    skip: !activeEntity?.id,
    variables: { userId: activeEntity?.id },
    fetchPolicy: 'no-cache',
  });

  const showDepositBlockedBannerButton = () => {
    if ((loading || !data?.fetchUser)) return false;
    if (!options.enableFlowWithoutIdVerification) {
      return (data.fetchUser?.user?.iDVerified) === false;
    }
    return false;
  };

  const resetEFTFields = () => {
    updateContextTransfer({
      value: {
        ...applicableData,
        schedule: 'ONE_TIME',
        amountCents: 0,
        bankAccount: {},
        subAccount: {},
      },
      existingContext: applicableData,
      setApplicationContext,
      setWorkflowCompletionContext,
    });
  };
  const resetInternalTransferFields = () => {
    updateContextTransfer({
      value: {
        ...applicableData,
        fromSubAccount: {},
        amountCents: 0,
        subAccount: {},
      },
      existingContext: applicableData,
      setApplicationContext,
      setWorkflowCompletionContext,
    });
  };
  const resetExternalTransferFields = () => {
    updateContextTransfer({
      value: {
        ...applicableData,
        amountCents: 0,
        institution: {},
        subAccount: {},
        accountNumber: '',
        accountType: '',
        transferType: 'ALL_IN_CASH',
      },
      existingContext: applicableData,
      setApplicationContext,
      setWorkflowCompletionContext,
    });
  };

  useEffect(() => {
    if (direction === 'BACKWARD') {
      if (transferData.type === 'EFT') {
        resetEFTFields();
      } else if (transferData.type === 'INTERNAL_TRANSFER') {
        resetInternalTransferFields();
      } else {
        resetExternalTransferFields();
      }
    }
  }, [direction]);

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const validateData = {
      type: applicableData.type,
    };
    const newErrors = validateFields(fieldOptions, validateData, candidateFields);
    setErrors(newErrors);
    return newErrors;
  }, [fieldOptions, applicableData]);

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  const submit = () => {
    const formErrors = validate();

    if (formErrors) {
      setFocused(Object.keys(formErrors));
    }

    if (applicableData?.type && !formErrors && !showDepositBlockedBannerButton()) {
      onNext();
    }
  };

  return (
    <Form>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <SelectionTile
        testId='deposit-type'
        onChange={(e: any) => {
          updateContextTransfer({
            existingContext: applicableData,
            value: { ...applicableData, type: e.target.value },
            setApplicationContext,
            setWorkflowCompletionContext,
          });
        }}
        value={applicableData?.type}
        options={options?.options?.filter((x: any) => x.enabled)?.map((option: any) => (
          { value: option.value, label: translateBackend(option.label), subtitle: translateBackend(option.description) }
        ))}
        error={errors?.type?.active}
      />
      {
        showDepositBlockedBannerButton() && (
          <Box mt={sys.spacing.xl} bgcolor={sys.color.negativeVariant} padding={sys.spacing.xl} marginRight={sys.spacing.xl} borderRadius={sys.borderRadius.md}>
            <Typography variant="bodySmall" sx={{ color: sys.color.negativeOutline, textAlign: 'justify' }}>
              {t('transfer:verifyIdentity.1')}
              <Link
                target="_blank"
                sx={{ color: sys.color.negativeOutline, textDecorationColor: sys.color.negativeOutline }}
                href={userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new'}>
                {t('transfer:verifyIdentity.2')}
              </Link>
              {t('transfer:verifyIdentity.3')}
            </Typography>
          </Box>
        )
      }
      <Box display='flex' justifyContent='end'>
        <Button dataTestId='deposit-form-continue-button' disabled={loading || stepLoading} label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} onClick={debounce(() => submit(), 250)} />
      </Box>
    </Form>
  );
};

export default DepositType;
