import { useMutation, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import IntegrationsTypeSelect from './integrationsTypeSelect';
import IntegrationsProviderSelect from './integrationsProviderSelect';
import IntegrationsConfiguration from './integrationsConfiguration';
import { CurrentIntegrationType, IntegrationProvider, IntegrationType } from '../../../../../interfaces/integrations';
import { FETCH_INTEGRATIONS } from '../integrations';
import IntegrationScope from './integrationScope';
import { Box } from '../../../../1-primative';
import { FormModal } from '../../../../3-pattern';

export const UPDATE_INTEGRATION = gql`
  mutation updateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      integration {
        id
      }
    }
  }
`;

interface EditIntegrationProps {
  currentIntegration: CurrentIntegrationType;
  open: boolean;
  handleEditClose: () => void;
}

const EditIntegration = ({ currentIntegration, open, handleEditClose }: EditIntegrationProps) => {
  const { t } = useTranslation(['devSettings']);
  const { showToast } = useGlobalToast();
  const [editIntegration, setEditIntegration] = useState({
    configuration: currentIntegration.configuration,
    provider: currentIntegration.provider,
    type: currentIntegration.type,
    id: currentIntegration.id,
    state: currentIntegration.state,
    scope: currentIntegration.scope,
  });

  const [updateIntegration, { loading }] = useMutation(UPDATE_INTEGRATION, {
    variables: {
      input: {
        integrationId: currentIntegration.id,
        ...(editIntegration.configuration ? { configuration: editIntegration.configuration } : {}),
        scope: editIntegration.scope || [],
      },
    },
    refetchQueries: [FETCH_INTEGRATIONS],
  });

  useEffect(() => {
    setEditIntegration({
      configuration: currentIntegration.configuration,
      provider: currentIntegration.provider,
      type: currentIntegration.type,
      id: currentIntegration.id,
      state: currentIntegration.state,
      scope: currentIntegration.scope,
    });
  }, [currentIntegration]);

  const update = async () => {
    const response = await updateIntegration();
    if (response?.data) {
      showToast({ severity: 'success', message: t('integrationsModal.successToastMessage') });
      handleEditClose();
    }
  };

  const formCompleted = () => {
    const { provider, type, configuration } = editIntegration;

    if (!provider) {
      return true;
    }
    if (!type) {
      return true;
    }

    if (type === IntegrationType.BANKING && provider === IntegrationProvider.FLINKS) {
      if (!configuration.clientIframeUrl || !configuration.instanceId || !configuration.customerId) {
        return true;
      }
    }

    if (type === IntegrationType.ID_VERIFICATION && provider === IntegrationProvider.PERSONA) {
      if (!configuration.templateId || !configuration.verificationPassedWebhookSecret || !configuration.inquiryUpdateWebhookSecret) {
        return true;
      }
    }

    if (type === IntegrationType.ADDRESS && provider === IntegrationProvider.GOOGLE) {
      if (!configuration.apiKey) {
        return true;
      }
    }

    if (type === IntegrationType.ADDRESS && provider === IntegrationProvider.CANADA_POST) {
      if (!configuration.apiKey) {
        return true;
      }
    }

    if (type === IntegrationType.MARKET_DATA && provider === IntegrationProvider.IEX_CLOUD) {
      if (!configuration.apiToken) {
        return true;
      }
    }

    if (type === IntegrationType.MARKET_DATA && provider === IntegrationProvider.LSEG) {
      if (!configuration.widgetUsername || !configuration.widgetName || !configuration.widgetPrivateKey || !configuration.widgetPublicKey
        || !configuration.rdpUsername || !configuration.rdpPassword || !configuration.rdpClientId) {
        return true;
      }
    }

    if (type === IntegrationType.MARKET_DATA && provider === IntegrationProvider.QUOTE_MEDIA) {
      if (!configuration.username || !configuration.password || !configuration.webmasterId) {
        return true;
      }
    }

    if (type === IntegrationType.FORM && provider === IntegrationProvider.ONESPAN) {
      if (!configuration.baseUrl || !configuration.apiKey) {
        return true;
      }
    }

    if (type === IntegrationType.FINANCIAL_DATA_WIDGETS && provider === IntegrationProvider.LSEG) {
      if (!configuration.privateKey || !configuration.publicKey || !configuration.username || !configuration.name) {
        return true;
      }
    }

    if (type === IntegrationType.ANALYTICS && provider === IntegrationProvider.FIREBASE) {
      if (!configuration.apiKey || !configuration.authDomain || !configuration.projectId || !configuration.appId || !configuration.measurementId || !configuration.messagingSenderId) {
        return true;
      }
    }

    if (type === IntegrationType.ANALYTICS && provider === IntegrationProvider.MIXPANEL) {
      if (!configuration.TBD) {
        return true;
      }
    }

    if (type === IntegrationType.CHAT_AGENT && provider === IntegrationProvider.ZENDESK) {
      if (
        !configuration.clientRoleKey
        || !configuration.advisorRoleKey
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <FormModal
      disabled={formCompleted()}
      state={editIntegration}
      loading={loading}
      title={t('integrationsModal.editTitle')}
      onSubmit={update}
      open={open}
      handleClose={handleEditClose}
      formButton={t('shared:update')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <IntegrationsTypeSelect
          disabled={true}
          label={t('integrationsModal.type')}
          value={editIntegration.type ?? ''}
          setIntegrationType={(e: any) => setEditIntegration({ ...editIntegration, type: e.target.value })}
        />
        <IntegrationsProviderSelect
          disabled={true}
          label={t('integrationsModal.provider')}
          value={editIntegration.provider ?? ''}
          currentType={editIntegration.type}
          setIntegrationProvider={(event: any) => setEditIntegration({ ...editIntegration, provider: event.target.value })}
        />
        <IntegrationsConfiguration integration={editIntegration} setIntegrationConfiguration={(newConfig: any) => setEditIntegration({ ...editIntegration, configuration: newConfig })} />
        <IntegrationScope integration={editIntegration} setIntegrationScope={(newScope: string[]) => setEditIntegration({ ...editIntegration, scope: newScope })}></IntegrationScope>
      </Box>
    </FormModal>
  );
};

export default EditIntegration;
