import { SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../menuItem/menuItem';
import { TextField } from '../textField/textField';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useTextFieldTokens } from '../textField/textField.tokens';

interface SelectFieldProps {
  label: string,
  placeholder?: string,
  helpText?: string,
  error?: boolean,
  errorText?: string,
  success?: boolean,
  successText?: string,
  fullWidth?: boolean,
  disabled?: boolean,
  sx?: SxProps,
  children?: React.ReactNode[] | React.ReactNode,
  value?: any,
  onChange?: any,
  onFocus?: any,
  onBlur?: any,
  renderValue?: any,
  size?: 'small' | 'medium',
  clearable?: boolean,
  infoTooltip?: string,
  multiple?: boolean,
  lockMessage?: string,
  locked?: boolean,
  onClick?: any,
  testId?: string,
  required?: boolean,
  leadingIcon?: 'dollar' | 'percent' | 'search',
}

export const SelectField = ({
  label, helpText, error = false, errorText, success = false,
  successText, fullWidth, disabled = false, sx, infoTooltip,
  onChange, onFocus, onBlur, renderValue, placeholder, children, value, size, clearable,
  multiple, locked, lockMessage, onClick, testId, required, leadingIcon,
}: SelectFieldProps) => {
  const { t } = useTranslation('components');
  const tokens = useThemeTokens(useTextFieldTokens());
  const { comp } = tokens;
  return (
    <TextField
      select
      testId={testId ?? 'select-field'}
      data-testid={testId}
      multiple={multiple}
      size={size}
      label={label}
      infoTooltip={infoTooltip}
      placeholder={placeholder}
      helpText={helpText}
      error={error}
      errorText={errorText}
      success={success}
      successText={successText}
      fullWidth={fullWidth}
      disabled={disabled}
      sx={{ ...sx, minWidth: '96px', '.MuiSelect-select': { fontSize: size === 'small' ? '14px' : 'inherit' } }}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      renderValue={renderValue}
      locked={locked}
      lockMessage={lockMessage}
      onClick={onClick}
      required={required}
      menuTestId={`${testId}-menu`}
      leadingIcon={leadingIcon}
    >
      {clearable && <MenuItem data-testid='clear-selection' value=''>{t('any')}</MenuItem>}
      {React.Children.map(children, (child: React.ReactNode) => (
        React.isValidElement(child) ? React.cloneElement(
          child as React.ReactElement,
          { sx: { color: comp.textField.textColor, fontSize: comp.textField.fontSize, fontWeight: comp.textField.fontWeight } },
        ) : child
      ))}
      {/* {children} */}
    </TextField>
  );
};
