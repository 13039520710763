import { SxProps } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { translateBackend } from '../../../assets/i18n/config';
import { TranslatedString } from '../../../interfaces';
import { Typography } from '../../1-primative';
import { Alert } from '../../2-component';

interface ImplementationWarningOptions {
  label: TranslatedString
  default: {
    severity: 'warning' | 'error' | 'info'
  }
}

const iconSelection = (severity: string) => {
  switch (severity) {
    case 'info':
      return <InfoIcon/>;
    case 'warning':
      return <WarningIcon/>;
    case 'error':
      return <ErrorIcon/>;
    default:
      return <InfoIcon/>;
  }
};

export const ImplementationWarning = ({ options, alertSx, typographySx }: { options: ImplementationWarningOptions, alertSx?: SxProps, typographySx?: SxProps }) => (
<Alert icon={iconSelection(options.default?.severity)} variant='outlined' severity={options.default?.severity} sx={{
  display: 'flex', alignItems: 'top', my: 2, ...alertSx,
}}>
  <Typography variant='bodyMedium' sx={typographySx}>
    {translateBackend(options.label)}
  </Typography>
</Alert>
);
