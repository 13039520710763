import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash/fp';
import { useNavigate } from 'react-router-dom';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import AutoRenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHouseholdContext } from '../../../pages/household';
import { MemberTag } from '../../../pages/household/components/memberTag';
import { useClientContext } from '../../../pages/client';
import { Typography, Box } from '../../1-primative';
import { Card, Tooltip, CardContent } from '../../2-component';
import { CurrencyFlag, CurrencyIndicator } from '../../3-pattern';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { Account, AccountStates } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import { currencySymbol } from '../../../util/currency';
import { PageObjectType } from '../../5-page';
import { translateBackend } from '../../../assets/i18n/config';
import { showAccountNumber } from '../workflowCompletion/subSteps/utils';

const getSimplifiedState = (accountState: AccountStates) => {
  switch (accountState) {
    case AccountStates.ACTIVE:
      return 'ACTIVE';
    case AccountStates.FAILED:
    case AccountStates.INACTIVE:
    case AccountStates.CANCELED:
      return 'CLOSED';
    default:
      return 'PENDING';
  }
};

type Tag = {
  text: string,
  sequenceNumber: number | 'HOUSEHOLD'
};
export const ACCOUNT_ITEM_CARD_HEIGHT = '112px';
const AccountItem = ({
  account, tags, enableTooltip = true, useCustodianData, objectType, options, showSimplifiedState,
}: {
  account: Account, tags?: Tag[], objectId: string, enableTooltip?: boolean, useCustodianData?: boolean,
  objectType: PageObjectType, options?: any, showSimplifiedState?: boolean,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState<number | null>(null);
  const navigate = useNavigate();
  const { sys } = useThemeTokens();
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'accountTypes', 'accountsDetail', 'shared']);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();

  const statistics = useCustodianData ? account.custodianStatistics : account.statistics;
  // The simpleReturnAmount is actually coming in cents.
  // TODO: update history-service to return simpleReturnAmountCents and deprecate simpleReturnAmount
  const simpleReturnAmountCents = useCustodianData ? statistics?.simpleReturnAmountCents : statistics?.simpleReturnAmount;

  const getPercentageOfTotalAsset = (): number => {
    const totalMarketValueCents: number = objectType === PageObjectType.HOUSEHOLD ? householdContext?.totalMarketValueCents ?? 0 : clientContext?.totalMarketValueCents || 0;
    const percentage = (statistics?.marketValueCents ?? 0) / totalMarketValueCents;
    return Number.isNaN(percentage) ? 0 : percentage;
  };

  const isInactive = account.state === AccountStates.INACTIVE;
  const subAccounts = account.subAccounts?.filter((x) => x.state !== 'INACTIVE');
  const awaitingSubAccountCount: number = account.subAccounts?.filter((x) => x.state === 'AWAITING_APPROVAL').length ?? 0;

  const accountState = showSimplifiedState ? getSimplifiedState(account.state) : account.state;
  const numExternalHoldingsLinked = statistics?.holdings
    ? statistics.holdings.filter((x: any) => x.totalCents !== 0).length
    : 0;

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }

    // Added a resize event listener to update the width dynamically
    const handleResize = () => {
      if (cardRef.current) {
        setCardWidth(cardRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [cardRef]);
  return (
    <Card
      ref={cardRef}
      hover
      sx={{
        height: ACCOUNT_ITEM_CARD_HEIGHT,
        cursor: 'pointer',
        backgroundColor: isInactive ? sys.color.disabled : sys.color.surface,
        padding: '4px 16px 12px',
        boxSizing: 'border-box',
      }}
      onClick={() => {
        sendAnalytic(ovAnalyticsEvents.homepageAccountSelect);
        navigate(`account/${account.id}`);
      }}
    >
      <CardContent
        data-testid={`account-${account?.type}`}
        sx={{
          padding: '0px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box display="flex" justifyContent="end" sx={{
            position: 'relative', top: '6px', right: '-6px', height: '8px',
          }}>
            <Box display="flex" flexDirection="row" >
              {(awaitingSubAccountCount > 0 && enableTooltip) && (
                  <Tooltip title={t('goalsSummary.subAccountNeedsApproval', { count: awaitingSubAccountCount })}>
                    <Box height='8px' width='8px' borderRadius='50%' bgcolor={sys.color.negative} />
                  </Tooltip>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Tooltip
                  title={<Typography variant='trimmedSmall' color={sys.color.onPrimary}>{t(`accountTypes:${account.type}`)}</Typography>}
                  placement='top'
                >
                  <Typography
                    key={cardWidth}
                    variant='titleMedium'
                    sx={{
                      // 205 is the width of the element on the other side + the padding + the extra 32px
                      maxWidth: cardWidth ? `${cardWidth - 205}px` : '200px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >{t(`accountTypes:${account.type}`)}</Typography>
                </Tooltip>
                <Typography variant='bodySmall' display="inline-flex" ml={0.5} colorVariant='variant'>
                  {account.custodianAccountNumber ? (
                    showAccountNumber(
                      account.custodianAccountNumber,
                      options?.displayMaskedAccountNumber,
                      options?.displayAccountNumberWithDashes ? options?.displayAccountNumberWithDashesFormat : undefined,
                    )
                  ) : (
                      <>
                        <AutoRenewRoundedIcon sx={{ fontSize: '14px', verticalAlign: 'middle' }} />
                        {t(`accountsDetail:accountState.${accountState}`)}
                      </>
                  )}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" gap={0.5} mt={0.5}>
                {tags ? tags.map((tag, index) => (<MemberTag key={index} sequenceNumber={tag.sequenceNumber} name={tag.text} />)) : <Box height='16.5px' width={1} />}
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="end">
              <Box display='flex' gap={1} alignItems='center'>
                {(options.displayAccountCurrency && account?.baseCurrency) && <CurrencyFlag baseCurrency={account.baseCurrency} />}
                <CurrencyIndicator statistics={statistics} useExternalStatisticsEnabled={useCustodianData} />
                <Typography variant='titleMedium'>
                  {
                    !isNil(statistics?.marketValueCents)
                      ? formatMoneyValue(statistics?.marketValueCents ?? 0, currencySymbol[statistics?.currency ?? CurrencyCodes.CAD])
                      : (
                        <>
                          <Tooltip title={`${t('shared:errroStateAccountTitle')}. ${t('shared:errorStateSubText')}`} placement="top">
                            <Box display="flex" alignItems="center">
                              <InfoOutlinedIcon
                                data-testid="info-icon"
                                sx={{
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                  marginRight: '6px',
                                }}
                              />
                              <span>{t('shared:amountUnavailable')}</span>
                            </Box>
                          </Tooltip>
                        </>
                      )
                  }
                </Typography>
              </Box>
              {(isNil(options.showSimpleRateOfReturn) || options.showSimpleRateOfReturn) && (
                  <Typography
                      variant='bodySmall'
                      color={isInactive ? sys.color.onDisabled : (simpleReturnAmountCents || 0) >= 0 ? sys.color.positive : sys.color.negative}
                  >
                    {`${formatMoneyValue(simpleReturnAmountCents ?? 0, currencySymbol[statistics?.currency ?? CurrencyCodes.CAD])} (${formatPercentValue(statistics?.simpleReturnPercent ?? 0)})`}
                  </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent={(isNil(options.showLinkedSubAccounts) || options.showLinkedSubAccounts || options.showExternalHoldings || isNil(options.showExternalHoldings))
              ? 'space-between' : 'flex-end'}
          >
            {(options.showLinkedSubAccounts === true || isNil(options.showLinkedSubAccounts)) && (
              <Typography variant='bodySmall' sx={{ color: isInactive ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
                {translateBackend(options.linkedSubAccounts)
                  ? `${subAccounts?.length ?? 0} ${translateBackend(options.linkedSubAccounts)}`
                  : t('accountsSummary.subAccountsLinked', { count: subAccounts?.length ?? 0 })}
              </Typography>
            )}
            {(options.showExternalHoldings === true) && (
              <Typography variant='bodySmall' sx={{ color: isInactive ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
                {translateBackend(options.linkedExternalHoldings)
                  ? `${numExternalHoldingsLinked ?? 0} ${translateBackend(options.linkedExternalHoldings)}`
                  : t('accountsSummary.externalHoldingsLinked', { count: numExternalHoldingsLinked ?? 0 })}
              </Typography>
            )}
            <Typography variant='bodySmall' sx={{ color: isInactive ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
              {t('accountsSummary.ofTotalAssets', { percentage: formatPercentValue(getPercentageOfTotalAsset(), 0) })}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountItem;
