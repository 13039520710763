import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import Flags from './components/flags';
import Jobs from './components/jobs';
import Activities from './components/activities';
import { SecondaryTabs } from '../../3-pattern';

const Reconciliation = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['reconciliation']);

  const availableTabs = [
    {
      id: 'flags',
      label: t('tabs.flags'),
      permission: 'read:flags',
      component: <Flags />,
    },
    {
      id: 'activities',
      label: t('tabs.activities'),
      permission: 'read:custodian_activity_basic',
      component: <Activities />,
    },
    {
      id: 'jobs',
      label: t('tabs.jobs'),
      permission: 'read:recon_jobs ', // Added the space deliberately to match the exact permission name "read:recon_jobs "
      component: <Jobs />,
    },
  ].filter((availableTab) => permissions.includes(availableTab.permission));

  const [tab, setTab] = usePageState(availableTabs[0]?.id || 'flags', 'tab');

  if (availableTabs.length === 0) return null;
  return (
    <>
      <SecondaryTabs tabs={availableTabs.map((tabItem) => ({
        label: tabItem.label,
        icon: '',
        key: tabItem.id,
      }))}
      activeTab={tab} setActiveTab={(ta) => {
        setTab(ta.toString());
      }} />
      { tab === 'flags' && <Flags />}
      { tab === 'activities' && <Activities />}
      { tab === 'jobs' && <Jobs />}
    </>
  );
};

export default Reconciliation;
