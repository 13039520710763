import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../../../2-component';
import { Box, Typography } from '../../../../../1-primative';
import { ConfirmationModal, OrganizationSelect } from '../../../../../3-pattern';
import { useGlobalToast } from '../../../../../../providers/globalToastProvider';
import { ClientGroup, Organization } from '../../../../../../interfaces';

const UPDATE_USER_ORGANIZATION = gql`
  mutation updateUserOrganization($userId: ObjectID!, $newOrganizationId: ObjectID!) {
    updateUserOrganization(input: { userId: $userId, newOrganizationId: $newOrganizationId }) {
      user { lastName firstName organization { name } }
    }
  }
`;

export const EditClientOrganization = ({
  userId, userHouseholds, clientName, currentOrganization, onClose, open,
}:{
  userId: string, userHouseholds?: ClientGroup[], clientName: string, currentOrganization: Organization,
  open: boolean, onClose: () => void
}) => {
  const { t } = useTranslation('client');
  const { showToast } = useGlobalToast();
  const [organizationId, setOrganizationId] = useState<string>(currentOrganization.id);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [updateUserOrganizationMutation] = useMutation(UPDATE_USER_ORGANIZATION, {
    variables: { userId, newOrganizationId: organizationId },
  });

  const doUpdateUser = async () => {
    setConfirmationModalOpen(false);
    await updateUserOrganizationMutation();
    onClose();
    showToast({ severity: 'info', message: t('editClientOrganization.updatedToastMessage', { clientName }) });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} >
        <DialogTitle>{t('editClientOrganization.moveClientToAnotherOrganization')}</DialogTitle>
        <DialogContent>
          {(userHouseholds !== undefined && userHouseholds.length > 0) ? (
            <Typography>{t('editClientOrganization.cannotMoveHouseholdMembers')}</Typography>
          ) : (
            <Box display='flex' flexDirection='column' gap={1}>
              <Typography variant='bodyMedium'>{t('editClientOrganization.currentOrganization')}: {currentOrganization.name}</Typography>
              <Typography variant='bodyMedium'>{t('details.clientName')}: {clientName}</Typography>
              <OrganizationSelect value={organizationId} onChange={(e) => setOrganizationId(e)} label={t('editClientOrganization.selectOrganization')} />
            </Box>
          )}
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end'>
            {(userHouseholds !== undefined && userHouseholds.length > 0) ? (
              <Button label={t('shared:proceed')} onClick={onClose} />
            ) : (
              <Button label={t('shared:save')} onClick={() => setConfirmationModalOpen(true)} />
            )}
          </Box>
        </DialogFooter>
      </Dialog>
      <ConfirmationModal
        open={confirmationModalOpen}
        title={t('editClientOrganization.confirmationTitle')}
        bodyText={t('editClientOrganization.confirmationSubtitle')}
        confirmButtonLabel={t('shared:yes')}
        cancelButtonLabel={t('shared:no')}
        onCancel={() => setConfirmationModalOpen(false)}
        onConfirm={doUpdateUser}
      />
    </>
  );
};
