import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  OrganizationUserEntityRelationTypes, User,
  OrganizationUserAccessTypes,
} from 'interfaces';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { entityName } from '../../../../../../util';
import {
  Box, Grid, Icon, Typography,
} from '../../../../../1-primative';
import {
  Button, Dialog, Form, MenuItem, SelectField,
  Segment,
  SegmentedControl,
  SegmentValue,
  TextField,
  Switch,
} from '../../../../../2-component';
import { UserContext, usePermissions } from '../../../../../../providers/userContextProvider';
import { translateBackend } from '../../../../../../assets/i18n/config';
import { CREATE_WORKFLOW_COMPLETION } from '../../../../../../components/layout/client/clientSummary';
import { ConfirmationModal } from '../../../../../3-pattern';
import { UPDATE_AFFILIATION } from '../..';
import { ClientSelect } from '../../../../../../components/inputs/clientSelect';
import { ADD_ENTITY_TO_ORG_USER } from '../../../../widgets/accessToEntity/accessToEntity.queries';

enum GrantAccessActions {
  CREATE = 'createNewUser',
  INVITE = 'inviteUser',
}

const FETCH_ROLES = gql`
query fetchRoles($input: FetchRolesInput!) {
  fetchRoles(input: $input) {
    roles {
      id
      translatedName {
        en
        fr
      }
      organization {
        name
      }
    }
  }
}
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        affiliateOnly
      }
    }
  }
`;

const CREATE_ORGANIZATION_USER = gql`
mutation createOrganizationUser($input: CreateOrganizationUserInput!) {
  createOrganizationUser(input: $input) {
    organizationUser {
      id
    }
  }
}
`;

const TRANSITION_USER = gql`
  mutation transitionUser($input: TransitionUserInput!) {
    transitionUser(input: $input) {
      user {
        id
        state
      }
    }
  }
`;

export const ConvertAffiliate = ({
  entity,
  openConvertAffiliate,
  setOpenConvertAffiliate,
  currentAffiliate,
  accountData,
  options,
  refetch,
}: {
  entity?: User,
  openConvertAffiliate: boolean,
  setOpenConvertAffiliate: any,
  currentAffiliate: any,
  accountData?: any
  options?: any,
  refetch?: () => void;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [grantAccessAction, setGrantAccessAction] = useState<GrantAccessActions>(GrantAccessActions.CREATE);
  const emptyUser = {
    firstName: currentAffiliate?.user?.firstName ?? '',
    lastName: currentAffiliate?.user?.lastName ?? '',
    email: currentAffiliate?.user?.primaryEmail ?? '',
    organizationId: activeOrganization.id ?? '',
    roleId: '',
    autoInviteUser: true,
  };
  const [updateUser] = useMutation(UPDATE_USER);
  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION);
  const [updateAffiliations] = useMutation(UPDATE_AFFILIATION);
  const [transitionUser] = useMutation(TRANSITION_USER);
  const [addEntityToOrganizationUser] = useMutation(ADD_ENTITY_TO_ORG_USER, { onCompleted: refetch });
  const [organizationUser, setOrganizationUser] = useState(emptyUser);
  const [newOrgUserRelation, setNewOrgUserRelation] = useState(OrganizationUserEntityRelationTypes.OWNER);
  const [affiliateConversionConfirmation, setAffiliateConversionConfirmation] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [entityUser, setEntityUser] = useState<User | undefined>();
  const canCreateAndInvite = ['write:organization_users', 'write:invite_organization_users'].some((elem) => permissions.includes(elem));

  const handleClose = () => {
    setOpenConvertAffiliate(false);
    setOrganizationUser(emptyUser);
    setNewOrgUserRelation(OrganizationUserEntityRelationTypes.OWNER);
    setEmailError(false);
  };

  const { loading, data } = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: { organizationId: organizationUser.organizationId || undefined },
        pagination: { perPage: 50 },
      },
    },
  });

  useEffect(() => {
    if (!organizationUser.organizationId) setOrganizationUser({ ...organizationUser, roleId: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUser.organizationId]);

  const [createOrganizationUser] = useMutation(CREATE_ORGANIZATION_USER, {
    variables: {
      input: {
        ...organizationUser,
        accessType: OrganizationUserAccessTypes.ENTITY,
      },
    },
    onError: (error) => {
      if (['duplicate key error', organizationUser.email].every((elem) => error.message.includes(elem))) setEmailError(true);
    },
  });

  const segments: Segment[] = [
    { value: GrantAccessActions.CREATE, label: t('components:accessToEntity.addButton.toggle.create') },
    { value: GrantAccessActions.INVITE, label: t('components:accessToEntity.addButton.toggle.invite') },
  ];

  const handleSubmit = async () => {
    if (!canCreateAndInvite || grantAccessAction === GrantAccessActions.INVITE) {
      setAffiliateConversionConfirmation(true);
    } else {
      await updateUser({
        variables: {
          input: {
            userId: currentAffiliate?.user?.id,
            affiliateOnly: false,
          },
        },
      });
      const dataOrgUser = await createOrganizationUser();
      const organizationUserId = dataOrgUser?.data?.createOrganizationUser?.organizationUser?.id;
      if (organizationUserId) {
        const entityAddingResponse = await addEntityToOrganizationUser({
          variables: {
            input: {
              entityId: currentAffiliate?.user?.id,
              organizationUserId,
              relation: newOrgUserRelation,
              readOnly: false,
            },
          },
        });
        const entityOrgId = entityAddingResponse?.data?.addEntityToOrganizationUser?.organizationUser?.id;
        if (entityOrgId && options.convertedAffiiateWorkflow && options?.convertedAffiiateWorkflow !== 'default') {
          await createWorkflowCompletion({
            variables: {
              input: {
                objectId: currentAffiliate?.user?.id,
                objectType: 'USER',
                workflowId: options.convertedAffiiateWorkflow,
                organizationId: organizationUser.organizationId,
              },
            },
          });
        }
      }
      if (refetch) refetch();
      handleClose();
    }
  };

  const isSubmissionDisabled = !canCreateAndInvite || grantAccessAction === GrantAccessActions.INVITE
    ? !(entityUser)
    : !(!Object.values(organizationUser).includes('') && newOrgUserRelation);

  return (
    <>
      <Dialog
        sx={{
          '.MuiDialog-container': {
            '.MuiPaper-root': {
              padding: '20px',
            },
          },
        }}
        open={openConvertAffiliate}
        onClose={handleClose}
        fullWidth
      >
        <Box display="flex" flexDirection="row" justifyContent="end">
          <Box sx={{ cursor: 'pointer' }} display="flex" onClick={handleClose}><Icon icon={CloseRoundedIcon} size='medium' /></Box>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Grid container gap={canCreateAndInvite ? 2 : 4}>
            <Grid item xs={12}>
              <Typography variant='headingMedium' sx={{ mt: 1 }}>{t('components:accessToEntity.addButton.formTitle', { entityName: entityName(entity) })}</Typography>
            </Grid>
            {canCreateAndInvite && (
              <SegmentedControl
                sx={{ mb: 4 }}
                value={grantAccessAction}
                segments={segments}
                exclusive={true}
                fullWidth
                enforceActive
                onChange={(value: SegmentValue) => {
                  if (grantAccessAction === GrantAccessActions.CREATE) {
                    setOrganizationUser(emptyUser);
                    setNewOrgUserRelation(OrganizationUserEntityRelationTypes.OWNER);
                    setEmailError(false);
                  }
                  setGrantAccessAction(value as GrantAccessActions);
                }}
              />
            )}
            {canCreateAndInvite && grantAccessAction === GrantAccessActions.CREATE && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label={t('components:accessToEntity.addButton.createTab.firstName')}
                    fullWidth
                    value={organizationUser.firstName}
                    onChange={(e: any) => setOrganizationUser({ ...organizationUser, firstName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('components:accessToEntity.addButton.createTab.lastName')}
                    fullWidth
                    value={organizationUser.lastName}
                    onChange={(e: any) => setOrganizationUser({ ...organizationUser, lastName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('components:accessToEntity.addButton.createTab.email')}
                    fullWidth
                    value={organizationUser.email}
                    onChange={(e: any) => {
                      setOrganizationUser({ ...organizationUser, email: e.target.value.trim() });
                      setEmailError(false);
                    }}
                    error={emailError}
                    errorText={t('components:accessToEntity.addButton.createTab.emailError')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    value={organizationUser.roleId}
                    label={t('components:accessToEntity.addButton.createTab.role')}
                    onChange={(e: any) => setOrganizationUser({ ...organizationUser, roleId: e.target.value })}
                    fullWidth
                    disabled={organizationUser.organizationId === ''}
                  >
                    {
                      loading ? <MenuItem>...</MenuItem> : (
                        data.fetchRoles.roles.map((x: any) => (
                          <MenuItem key={x.id} value={ x.id }>{ translateBackend(x.translatedName) } - { x.organization.name }</MenuItem>
                        ))
                      )
                    }
                  </SelectField>
                </Grid>
                <Grid item xs={12}>
                  <Switch
                    checked={organizationUser?.autoInviteUser ?? true}
                    onChange={(checked) => setOrganizationUser({ ...organizationUser, autoInviteUser: checked })}
                    label={t('components:accessToEntity.addButton.createTab.autoInviteUser')}
                  />
                </Grid>
              </>
            )}
            {(!canCreateAndInvite || grantAccessAction === GrantAccessActions.INVITE) && (
              <>
                <Grid item xs={12}>
                  <ClientSelect fullWidth autoFocus label='Select User' setUser={(user: User | undefined) => {
                    setEntityUser(user);
                  }} />
                </Grid>
              </>
            )}
            <Grid item xs={12} mt={3} justifyContent='end' display='flex'>
              <Button
                type='submit'
                color='primary'
                disabled={isSubmissionDisabled}
                label='Invite User'
              />
            </Grid>
          </Grid>
        </Form>
      </Dialog>
      <ConfirmationModal
        onCancel={() => setAffiliateConversionConfirmation(false)}
        onConfirm={async () => {
          if (accountData) {
            const affiliatestionToUpdate: any[] = [];
            accountData?.affiliations.forEach((aff: any) => {
              if (aff?.user?.id === currentAffiliate?.user?.id) {
                affiliatestionToUpdate.push({
                  allocation: aff.allocation ?? 0,
                  id: aff?.id,
                  relation: aff?.relation,
                  signatureRequired: aff?.signatureRequired,
                  type: aff?.type,
                  userId: entityUser?.id,
                });
              } else {
                affiliatestionToUpdate.push({
                  allocation: aff.allocation ?? 0,
                  id: aff?.id,
                  relation: aff?.relation,
                  signatureRequired: aff?.signatureRequired,
                  type: aff?.type,
                  userId: aff?.user?.id,
                });
              }
            });
            await updateAffiliations({
              variables: {
                input: {
                  accountId: accountData?.accountId,
                  affiliations: affiliatestionToUpdate,
                },
              },
            });
          }
          await transitionUser({
            variables: { input: { userId: currentAffiliate?.user?.id, transition: 'deactivate' } },
          });
          if (refetch) refetch();
          setAffiliateConversionConfirmation(false);
          handleClose();
        }}
        open={affiliateConversionConfirmation}
        title={t('components:affiliateConversion.confirmation.title')}
        bodyText={t('components:affiliateConversion.confirmation.subTitle', { entityUserEmail: entityUser?.primaryEmail })}
      />
    </>
  );
};
