import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import { isUndefined } from 'lodash';
import { AppBreadcrumbs } from './appBreadcrumbs';
import OrganizationLogo from './organizationLogo';
import { Box } from '../../1-primative';
import { AppBar, IconButton, Toolbar } from '../../2-component';
import EntityMenu from '../../3-pattern/entityMenu/entityMenu';
import { Notifications } from '../notifications/notifications';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { NavigationStyles } from '../../../interfaces';
import Sidebar from './sidebar';
import { useLayoutTokens } from './layout.tokens';
import { useAuthContext } from '../../../providers/ovApolloProvider';
import Downtime from '../../../components/layout/downtime';
import { getZendeskKeys, ZendeskWidget } from '../widgets/chatAgent/zendesk';
import { entityName } from '../../../util';

const Layout = () => {
  const navigate = useNavigate();
  const {
    userContext,
    displayMenuBar,
    closed,
    integrations, // needed for Zendesk Chat Agent
  } = useContext(UserContext);
  const { permissions } = usePermissions();
  const { appLogout } = useAuthContext();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();
  const { sys, comp } = useThemeTokens(useLayoutTokens());

  const logout = async () => {
    await appLogout();
  };

  // This it to make pages scroll to top after every navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // This it to redirect to root path on downtime
  useEffect(() => {
    if (userContext.applicableDowntimeSchedule) navigate('/');
  }, [navigate, userContext.applicableDowntimeSchedule]);

  const hasNotifications = permissions.includes('read:notification');
  const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
  const appBarMobileAndTabletColor = comp.layout.backgroundColor;
  const appBarDesktopColor = comp.layout.backgroundColor;
  const hideAppBarActions = !!userContext.applicableDowntimeSchedule;

  return (
    <Box sx={{ display: 'flex' }}>
      {displayMenuBar && (
        <AppBar
          position='fixed'
          elevation={0}
          sx={{
            width: { md: '100%' },
            transition: 'all 0.3s',
            backgroundColor: { xs: appBarMobileAndTabletColor, md: appBarDesktopColor },
          }}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              {!hideAppBarActions && (
                <IconButton
                  onClick={() => setMobileOpen(!mobileOpen)}
                  size='small'
                  sx={{
                    color: comp.layout.textColor,
                    mr: 1,
                    display: { xs: !isUnifiedClientExperience ? 'block' : 'none', lg: 'none' },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <OrganizationLogo textColor={comp.layout.textColor} />
            </Box>
            <Box display='flex' justifyContent='end' alignItems='center'>
              {hasNotifications && !hideAppBarActions && <Notifications color={comp.layout.textColor} />}
              <EntityMenu logout={logout} showOnlyLogout={hideAppBarActions} />
            </Box>
          </Toolbar>
        </AppBar>
      )}

      <Box
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          width: { md: '100%', width: '1vw' },
          maxWidth: { xs: 'calc(100% - 32px)', sm: 'calc(100% - 48px)' },
          bgcolor: sys.color.background,
          mt: isUnifiedClientExperience && !displayMenuBar ? 0 : 7,
        }}
      >
        {userContext.applicableDowntimeSchedule ? (
          <>
            {!displayMenuBar && (
              <Box display='flex' flexDirection='column' alignContent={isUnifiedClientExperience ? 'center' : 'start'} flexWrap='wrap'>
                <Box display='flex' justifyContent='space-between' alignItems='center' maxWidth='1080px' width='100%' height='32px' mb={2} ml={{ xs: 0, sm: 2 }}>
                  <Box display='flex' justifyContent='end' alignItems='center' marginLeft='auto'>
                    <EntityMenu logout={logout} showOnlyLogout />
                  </Box>
                </Box>
              </Box>
            )}
            <Downtime downtime={userContext.applicableDowntimeSchedule} />
          </>
        ) : (
          <Box justifyContent='start' alignItems='center' sx={{ height: '100%' }} display='flex'>
            {!isUnifiedClientExperience && <Sidebar drawerWidth={closed ? 48 : 240} setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />}
            <Box
              width='100%'
              sx={{
                transition: 'all 0.4s',
                maxWidth: { lg: `calc(100% - ${!isUnifiedClientExperience ? `${closed ? 48 : 240}px` : '0px'})` },
                ml: {
                  xs: '0px',
                  sm: '0px',
                  md: '0px',
                  lg: !isUnifiedClientExperience ? `${closed ? 48 : 240}px` : '0px',
                },
              }}
            >
              {/* Note: The breadcrumb for "UnifiedClientExperience" when "displayMenuBar" is set to true is displayed on pages such as viewPageVisual */}
              {!isUnifiedClientExperience && displayMenuBar && (
                <Box mb={3} mt={1}>
                  <AppBreadcrumbs />
                </Box>
              )}
              {!displayMenuBar && (
                <Box display='flex' flexDirection='column' alignContent={isUnifiedClientExperience ? 'center' : 'start'} flexWrap='wrap'>
                  <Box display='flex' justifyContent='space-between' alignItems='center' maxWidth='1080px' width='100%' height='32px' mb={2} ml={{ xs: 0, sm: 2 }}>
                    <AppBreadcrumbs />
                    <Box display='flex' justifyContent='end' alignItems='center' marginLeft='auto'>
                      {hasNotifications && <Notifications color={comp.layout.textColor} />}
                      <EntityMenu logout={logout} />
                    </Box>
                  </Box>
                </Box>
              )}
              {/* This is where actual component gets rendered (see appRoutes.tsx) */}
              <Outlet />
              {/* Temporary Zendesk workaround. Refactor when adding new chat providers. */}
              {userContext?.accessType && !isUndefined(getZendeskKeys(integrations)) && (
                <ZendeskWidget
                  widgetStyle={{ color: sys.color.primary }}
                  accessType={userContext?.accessType}
                  user={entityName(userContext)}
                  scriptKeys={getZendeskKeys(integrations)}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Layout;
