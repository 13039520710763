import { useContext, useEffect, useState } from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { applicableProvinces, provinces } from 'ovComponents/resources';
import { find, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { EditAccountVisual } from './editAccount.visual';
import { WorkflowContext } from '../../workflowCompletion';
import { UPDATE_AFFILIATIONS } from './components/affiliateModal';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from '../../../../../interfaces/customField';
import { SubStepTypes } from '../../../../../interfaces';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { getEnabledCustomFields } from '../utils';

const ACCOUNT = (permissions?: string[]) => `#graphql
  id
  type
  applyForGovFunds
  jurisdiction
  sourceOfFunds
  repCode { code }
  subAccounts { id }
  user {
    id
    firstName
    lastName
    primaryEmail
    taxIdExists
    physicalAddress { country city province streetName postal unitNumber houseNumber neighborhood }
    maritalStatus
    countryOfTaxResidence
    accessibleBy {
      relation
      organizationUser {
        id
        repCodes { code name }
      }
    }
  }
  scheduledIncomeFundTransfer {
    id
    scheduledDate
    minimumAnnualAmountCents
    annualAmountCents
    state
    subAccount {
      id
      theme { id translatedName { en fr } }
    }
    bankAccount {
      id
      name
      bankAccountNumber
    }
    frequency
    amountPayableType
    dateOfBirth
    taxOption
    specialTaxRate
  }
  householdClientGroup { id name }
  affiliations {
    id
    allocation
    relation
    type
    user {
      id
      affiliateOnly
      firstName
      middleName
      lastName
      primaryEmail
      gender
      taxIdExists
      dateOfBirth
      dateOfDeath
      physicalAddress { country city province streetName postal unitNumber houseNumber neighborhood }
      type
      affiliateOnly
      sinExists
      employmentStatus
      maritalStatus
      companyType
      jobTitle
      studentAreaOfStudy
      citizenships
      isOfficerOfPublicCompany
      isOwnerOfPublicCompany
      isMemberOfIiroc
      politicallyExposedForeignPerson
      politicallyExposedDomesticPerson
      closeAssociateOfPEP
      headOfInternationalOrganization
      foreignTaxInformation {
        id
        foreignTaxCountry
        foreignTaxNumber
      }
    }
  }
  baseCurrency
  availableCurrencies
  custodianAffiliates{
    allocation
    relation
    type
    contributionAmount
    grantAmount
    user {
      dateOfBirth
      lastName
      firstName
    }
  }
  ${permissions && permissions.includes('read:custodian_connection') ? `#graphql
    custodianConnection {
      id
      name
      default
      accountTypeSettings {
        type
        baseCurrency
        availableCurrencies
        isMultiCurrencyEnabled
      }
   }
  ` : ''}
`;

const FETCH_USER_ACCOUNT = (derivedCustomFields?: string[], permissions?: string[]) => gql`
  query fetchUserAccount($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        accounts {
          ${ACCOUNT(permissions)}
          ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
            key
            value
            customField { id translatedName { en fr } translatedDescription{ en fr } type format }
            selectedOptions { value displayText { en fr } }
          }` : ''}
        }
      }
    }
  }
`;

const FETCH_ACCOUNT = (derivedCustomFields?: string[], permissions?: string[]) => gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        ${ACCOUNT(permissions)}
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr } type format }
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account { id }
    }
  }
`;

const FETCH_CUSTODIAN_CONNECTIONS = gql`
  query fetchCustodianConnections($input: FetchCustodianConnectionsInput!) {
    fetchCustodianConnections(input: $input) {
      custodianConnections {
        id
        name
        default
        accountTypeSettings {
          type
          baseCurrency
          availableCurrencies
          isMultiCurrencyEnabled
        }
      }
    }
  }
`;

export const EditAccount = ({
  options, userId, onNext, previousStep, stepLoading, grid = false, updateMode = false, accountId, direction,
  workflowCompletion,
}: {
  options: any, userId?: string, onNext: () => void, previousStep: () => void, stepLoading: boolean, grid?: boolean, updateMode?: boolean, accountId?: string,
  direction: 'FORWARD' | 'BACKWARD', workflowCompletion?: any,
}) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['client', 'accountsDetail']);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const { activeOrganization, custodianConnection: defaultCustodianConnection } = useContext(UserContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const [custodianConnections, setCustodianConnections] = useState<any[]>();
  const [accountData, setAccountData] = useState<any>({ accountId: null, type: '', customFields: [] });
  const enabledCustomFields = getEnabledCustomFields(options);
  const skipFetchAccount = ((!accountId && !workflowData.currentAccountId) || (permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0)) && !workflowData.skipEditAccount;
  const skipFetchUserAccount = (!!workflowData.currentAccountId || !!accountId || (permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0)) && !workflowData.skipEditAccount;

  const { loading: customFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.EDIT_ACCOUNT,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: { perPage: 100 },
      },
      skipErrorHandler: true,
    },
    skip: (!permissions.includes('read:custom_fields') || enabledCustomFields.length === 0) && !workflowData.skipEditAccount,
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);

      if (accountId || workflowData.currentAccountId) {
        await fetchAccount({
          variables: { accountId: accountId || workflowData?.currentAccountId, skipErrorHandler: true },
          query: customFieldKeys ? FETCH_ACCOUNT(customFieldKeys, permissions) : undefined,
        });
      } else {
        await fetchUserAccount({
          variables: { userId, skipErrorHandler: true },
          query: customFieldKeys ? FETCH_USER_ACCOUNT(customFieldKeys, permissions) : undefined,
        });
      }
    },
  });

  const { loading: custodianConnectionsLoading } = useQuery(FETCH_CUSTODIAN_CONNECTIONS, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization?.id ?? undefined },
        pagination: { perPage: 100 },
      },
      skipErrorHandler: true,
    },
    skip: (!options.custodianSelector || !permissions.includes('read:custodian_connection')) && !workflowData.skipEditAccount,
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    onCompleted: async (res: any) => {
      setCustodianConnections(res?.fetchCustodianConnections?.custodianConnections);
    },
  });

  const [fetchAccount, { data, refetch: accountRefetch, loading }] = useLazyQuery(FETCH_ACCOUNT(undefined, permissions), {
    variables: { accountId: accountId || workflowData?.currentAccountId, skipErrorHandler: true },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });

  const setAccountDataFunc = (response: any): void => {
    setAccountData({
      accountId: response.fetchAccount.account.id,
      type: response.fetchAccount.account.type,
      applyForGovFunds: response.fetchAccount.account.applyForGovFunds,
      user: response.fetchAccount.account.user,
      affiliations: response.fetchAccount.account.affiliations,
      custodianAffiliates: response.fetchAccount.account.custodianAffiliates || [],
      subAccounts: response.fetchAccount.account.subAccounts,
      scheduledIncomeFundTransfer: response.fetchAccount.account.scheduledIncomeFundTransfer,
      sourceOfFunds: response.fetchAccount.account.sourceOfFunds ?? undefined,
      jurisdiction: response.fetchAccount.account.jurisdiction ?? undefined,
      householdClientGroupId: response.fetchAccount.account.householdClientGroup?.id ?? undefined,
      customFields: response.fetchAccount.account.customFields || [],
      baseCurrency: response.fetchAccount.account.baseCurrency ?? CurrencyCodes.CAD,
      repCode: response.fetchAccount.account.repCode,
      availableCurrencies:
        response.fetchAccount.account.availableCurrencies && response.fetchAccount.account.availableCurrencies.length > 0 ? response.fetchAccount.account.availableCurrencies : [CurrencyCodes.CAD],
      custodianConnection: response.fetchAccount.account.custodianConnection ?? defaultCustodianConnection,
    });
  };

  const { data: defaultdata, refetch, loading: fetchAccountLoading } = useQuery(FETCH_ACCOUNT(undefined, permissions), {
    variables: { accountId: accountId || workflowData?.currentAccountId, skipErrorHandler: true },
    fetchPolicy: 'network-only',
    skip: skipFetchAccount,
    errorPolicy: 'ignore',
  });

  const [fetchUserAccount, { data: fetchedUserAccountData, refetch: refetchUserAccount }] = useLazyQuery(FETCH_USER_ACCOUNT(undefined, permissions), {
    variables: { userId, skipErrorHandler: true },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });

  const { data: userData, refetch: userRefetch, loading: userLoading } = useQuery(FETCH_USER_ACCOUNT(undefined, permissions), {
    variables: { userId, skipErrorHandler: true },
    fetchPolicy: 'network-only',
    skip: skipFetchUserAccount,
    errorPolicy: 'ignore',
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const [updateAccount, { loading: updateAccountLoading }] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: onNext,
    variables: {
      input: {
        accountId: accountData.accountId,
        applyForGovFunds: accountData.applyForGovFunds,
        jurisdiction: accountData.jurisdiction ?? undefined,
        sourceOfFunds: accountData.sourceOfFunds ?? undefined,
        householdClientGroupId: accountData.householdClientGroupId ?? undefined,
        customFields: modifyUpdatingCustomFields(accountData?.customFields, derivedCustomFields || [], derivedCustomFieldsKeys),
        baseCurrency: accountData.baseCurrency ?? undefined,
        availableCurrencies: accountData.availableCurrencies ?? undefined,
        custodianConnectionId: accountData.custodianConnection?.id ?? undefined,
        repCode: accountData.repCode?.code,
      },
    },
  });

  const [updateAffiliations] = useMutation(UPDATE_AFFILIATIONS, {
    variables: {
      input: {
        accountId: accountData.accountId,
        affiliations: accountData.affiliations?.map((item: any) => ({
          allocation: item.allocation,
          relation: item.relation,
          type: item.type,
          userId: item.user.id,
        })) || [],
      },
    },
    onCompleted: () => {
      setWorkflowData({
        ...workflowData,
        currentAccountId: accountData.accountId,
        currentAccountType: accountData.type,
      });
      updateAccount();
    },
  });
  useEffect(() => {
    if (data || defaultdata) {
      setAccountDataFunc(data ?? defaultdata);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultdata]);

  useEffect(() => {
    if (userData || fetchedUserAccountData) {
      const firstAccount = (userData ?? fetchedUserAccountData).fetchUser.user.accounts[0];
      if (!firstAccount) {
        return;
      }
      setAccountData({
        accountId: firstAccount.id,
        type: firstAccount.type || undefined,
        repCode: firstAccount.repCode,
        applyForGovFunds: firstAccount.applyForGovFunds || [],
        user: firstAccount.user,
        affiliations: firstAccount.affiliations || [],
        custodianAffiliates: firstAccount.custodianAffiliates || [],
        subAccounts: firstAccount.subAccounts,
        scheduledIncomeFundTransfer: firstAccount.scheduledIncomeFundTransfer,
        sourceOfFunds: firstAccount.sourceOfFunds ?? undefined,
        jurisdiction: firstAccount.jurisdiction ?? undefined,
        householdClientGroupId: firstAccount.householdClientGroupId ?? undefined,
        customFields: firstAccount?.customFields || [],
        baseCurrency: firstAccount?.baseCurrency ?? CurrencyCodes.CAD,
        availableCurrencies:
          firstAccount?.availableCurrencies && firstAccount?.availableCurrencies.length > 0 ? firstAccount?.availableCurrencies : [CurrencyCodes.CAD],
        custodianConnection: firstAccount?.custodianConnection ?? defaultCustodianConnection,
      });
    }
  }, [userData, onNext, fetchedUserAccountData, defaultCustodianConnection]);

  const filterApplicableProvinces = (province: { key: string, value: string }) => {
    const currentAccountType = accountData?.type;
    const availabileRestriction: any = find({ accountType: currentAccountType }, applicableProvinces);
    if (availabileRestriction) {
      return availabileRestriction.availability.includes(province.key);
    }
    return true;
  };

  function getJurisdictionOptions(): Array<{ id?: string; label?: string }> {
    return (
      provinces.filter(filterApplicableProvinces).map((a: any) => ({ id: a.key, label: a.value }))
    );
  }

  function getSourceOfFundsOptions(): Array<{ id?: string; label?: string, key?: string }> {
    const currentAccountType = accountData?.type;
    if (currentAccountType.startsWith('USA_')) {
      return [
        'USA_EMPLOYMENT_WAGES', 'USA_INHERITANCE_TRUST', 'USA_LOTTERY_GAMING', 'USA_RETIREMENT_FUNDS',
        'USA_INVESTMENTS', 'USA_SPOUSAL_PARENTAL_SUPPORT', 'USA_GIFT', 'USA_SAVINGS',
        'USA_UNEMPLOYMENT_DISABILITY', 'USA_LEGAL_SETTLEMENT', 'USA_OTHER',
      ].map((type) => ({ id: type, label: t(`workflowCompletions:updateAccount.sourceOfFundsOptions.${type}`) }));
    }
    return ['SELF', 'SPOUSE'].map((type) => ({ id: type, label: t(`workflowCompletions:updateAccount.sourceOfFundsOptions.${type}`) }));
  }

  const getCurrentJurisdictionOption = (key: string) => {
    if (key === '') return undefined;
    const current = provinces.filter((k: any) => (k.key === key));
    if (current && current[0]) {
      return current[0].key;
    }
    return undefined;
  };

  return (
    <EditAccountVisual
      userId={userId}
      options={options}
      accountData={accountData}
      updateAccount={setAccountData}
      continueFunc={updateAffiliations}
      loadingData={customFieldLoading || loading || fetchAccountLoading || userLoading || custodianConnectionsLoading}
      loading={customFieldLoading || loading || fetchAccountLoading || userLoading || custodianConnectionsLoading || updateAccountLoading || stepLoading}
      refetch={() => {
        if (workflowData.currentAccountId || accountId) {
          if (skipFetchAccount) {
            accountRefetch();
          } else {
            refetch();
          }
        } else if (skipFetchUserAccount) {
          refetchUserAccount();
        } else {
          userRefetch();
        }
      }}
      grid={grid}
      updateMode={updateMode}
      skip={direction === 'FORWARD' ? onNext : previousStep}
      filterApplicableProvinces={filterApplicableProvinces}
      getJurisdictionOptions={getJurisdictionOptions}
      getSourceOfFundsOptions={getSourceOfFundsOptions}
      getCurrentJurisdictionOption={getCurrentJurisdictionOption}
      activeCustomFields={derivedCustomFieldsKeys}
      custodianConnections={custodianConnections}
      workflowCompletion={workflowCompletion}
      skipEditAccount={workflowData.skipEditAccount ?? false}
    />
  );
};

export default EditAccount;
