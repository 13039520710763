import { useEffect, useState } from 'react';
import { CreateModelPortfolioOptions, PortfolioTemplate } from './createModelPortfolio';
import { SelectPortfolioTemplate } from './components/selectPortfolioTemplate';
import { CreatePortfolioTemplate } from './components/createPortfolioTemplate';
import { ChoosePortfolioType } from './components/choosePortfolioType';

enum SegmentType {
  CHOOSE_PORTFOLIO_TYPE = 'CHOOSE_PORTFOLIO_TYPE',
  SELECT_PORTFOLIO_TEMPLATE = 'SELECT_PORTFOLIO_TEMPLATE',
  CREATE_PORTFOLIO_TEMPLATE = 'CREATE_PORTFOLIO_TEMPLATE',
}

interface CreateModelPortfolioVisualProps {
  options: CreateModelPortfolioOptions;
  modelPortfolioData: {
    translatedName: { en: string };
    organizationId: string;
    creationType?: 'FROM_TEMPLATE' | 'FROM_SCRATCH';
    portfolioTemplate?: PortfolioTemplate;
  };
  loading: boolean;
  updateModelPortfolio: (updates: any) => void;
  continueFunc: (modelPortfolioData: any) => void;
  workflowCompletion?: {
    workflow?: {
      id: string;
      name: string;
    };
    id: string;
    objectId: string;
    objectType: string;
  };
  workflowData: any;
  setWorkflowData: (data: any) => void;
  onBack?: () => void;
  navigateToSegment: (segment: string) => void;
}

export const CreateModelPortfolioVisual = ({
  options,
  modelPortfolioData,
  loading,
  updateModelPortfolio,
  continueFunc,
  workflowCompletion,
  workflowData,
  setWorkflowData,
  onBack,
  navigateToSegment,
}: CreateModelPortfolioVisualProps) => {
  const [currentSegment, setCurrentSegment] = useState<SegmentType>(
    workflowData.currentSegment || SegmentType.CHOOSE_PORTFOLIO_TYPE,
  );

  useEffect(() => {
    if (workflowData.currentSegment) {
      setCurrentSegment(workflowData.currentSegment);
    }
  }, [workflowData.currentSegment]);

  const handleSaveForLater = () => {
    setWorkflowData({
      ...workflowData,
      currentSegment: SegmentType.SELECT_PORTFOLIO_TEMPLATE,
    });
    onBack?.();
  };

  const renderSegment = (segment: SegmentType) => {
    switch (segment) {
      case SegmentType.CREATE_PORTFOLIO_TEMPLATE:
        return (
          <CreatePortfolioTemplate
            options={options.createTemplate}
            loading={loading}
            workflowCompletion={workflowCompletion}
            onComplete={(template: PortfolioTemplate) => {
              continueFunc({
                ...modelPortfolioData,
                portfolioTemplate: template,
              });
            }}
            onSaveForLater={handleSaveForLater}
          />
        );

      case SegmentType.SELECT_PORTFOLIO_TEMPLATE:
        return (
          <SelectPortfolioTemplate
            options={options.selectTemplate}
            loading={loading}
            continueFunc={() => continueFunc(modelPortfolioData)}
            workflowCompletion={workflowCompletion}
            selectedPortfolioTemplate={modelPortfolioData.portfolioTemplate || undefined}
            onTemplateSelect={(template: PortfolioTemplate) => {
              updateModelPortfolio({
                ...modelPortfolioData,
                portfolioTemplate: template,
              });
            }}
            onCreateNew={() => navigateToSegment(SegmentType.CREATE_PORTFOLIO_TEMPLATE)}
          />
        );

      case SegmentType.CHOOSE_PORTFOLIO_TYPE:
      default:
        return (
          <ChoosePortfolioType
            options={options.choosePortfolioType}
            modelPortfolioData={modelPortfolioData}
            loading={loading}
            updateModelPortfolio={updateModelPortfolio}
            onContinue={() => {
              if (modelPortfolioData.creationType === 'FROM_TEMPLATE') {
                navigateToSegment(SegmentType.SELECT_PORTFOLIO_TEMPLATE);
              } else {
                updateModelPortfolio({
                  ...modelPortfolioData,
                  portfolioTemplate: undefined,
                  creationType: 'FROM_SCRATCH',
                });
                continueFunc(modelPortfolioData);
              }
            }}
            workflowCompletion={workflowCompletion}
          />
        );
    }
  };

  return renderSegment(currentSegment);
};
