import { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { isUndefined, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { PieChart } from '../../2-component/pieChart/pieChart';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Card, CardContent } from '../../2-component';
import { getBackendLanguage } from '../../../assets/i18n/config';
import { Security } from '../securitiesSelectionTable/securitiesSelectionTable';
import { PortfolioTemplate } from '../../4-module/workflowCompletion/subSteps/createModelPortfolio/createModelPortfolio';

interface AssetClassGroup {
  totalPercentage: number;
  name: string;
  color: string;
  templateColor: string;
  templateTargetPercentage: number;
}

interface PieChartSecuritiesProps {
  securities: Security[];
  variant?: 'elevated' | 'outlined' | 'none';
  modelPortfolioName?: string;
  portfolioTemplate?: PortfolioTemplate;
}

interface PieChartData {
  pieChartData: number[];
  pieChartColors: string[];
}

const DEFAULT_BORDER_RADIUS = '4px';
const DEFAULT_COLOR = '#FFFFFF';
const DEFAULT_TEXT_COLOR = '#17181C';
const COLOR_LIST = ['supportOne', 'supportTwo', 'supportThree', 'supportFour', 'supportFive', 'supportSix'];

const ensureString = (value: unknown, defaultValue: string): string => {
  if (typeof value === 'string' && value !== '') {
    return value;
  }
  return defaultValue;
};

const assignColorsToAssetClasses = (assetClasses: string[]): Record<string, string> => assetClasses.reduce<Record<string, string>>((acc, assetClass, i) => ({
  ...acc,
  [assetClass]: COLOR_LIST[i % COLOR_LIST.length],
}), {});

const formatPercentage = (value: number): string => `${Number(value).toFixed(2)}%`;

const AssetClassLegendItem = ({
  item,
  isActive,
  isLast,
  color,
  surfaceContainerColor,
  surfaceColor,
  borderRadius,
  onMouseEnter,
  onMouseLeave,
}: {
  item: AssetClassGroup;
  isActive: boolean;
  isLast: boolean;
  color: string;
  surfaceContainerColor: string;
  surfaceColor: string;
  borderRadius: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => (
  <Box
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    display='flex'
    justifyContent='space-between'
    alignItems='left'
    sx={{
      borderBottom: !isActive && !isLast ? `2px solid ${surfaceContainerColor}` : `2px solid ${surfaceColor}`,
      py: 0.5,
      px: 1,
      backgroundColor: isActive ? surfaceContainerColor : 'none',
      borderRadius: isActive ? borderRadius : '0px',
    }}
  >
    <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
      <Box sx={{
        borderRadius: '10px',
        height: '8px',
        width: '8px',
        backgroundColor: color,
        mr: 1,
      }} />
      <Typography sx={{ fontSize: '0.75rem' }}>
        {item.templateTargetPercentage ? `${item.name} (${item.templateTargetPercentage}%)` : item.name}
      </Typography>
    </Box>
    <Box display='flex' alignItems='center' gap={2}>
      <Typography sx={{ fontSize: '0.75rem', color: DEFAULT_TEXT_COLOR }}>
        {formatPercentage(item.totalPercentage || 0)}
      </Typography>
    </Box>
  </Box>
);

export const PieChartSecurities = ({
  securities = [],
  variant = 'none',
  modelPortfolioName = '',
  portfolioTemplate,
}: PieChartSecuritiesProps) => {
  const [activeItem, setActiveItem] = useState<AssetClassGroup | null>(null);
  const { ref, sys } = useThemeTokens();
  const { t } = useTranslation(['shared', 'modelPortfolios']);

  const colorsSecurities = useMemo(() => ({
    supportOne: ensureString(ref.palette.supportOne50, DEFAULT_COLOR),
    supportTwo: ensureString(ref.palette.supportTwo50, DEFAULT_COLOR),
    supportThree: ensureString(ref.palette.supportThree50, DEFAULT_COLOR),
    supportFour: ensureString(ref.palette.supportFour50, DEFAULT_COLOR),
    supportFive: ensureString(ref.palette.supportFive50, DEFAULT_COLOR),
    supportSix: ensureString(ref.palette.supportSix50, DEFAULT_COLOR),
  }), [ref.palette]);

  const colorsAssetClassTemplate = useMemo(() => ({
    supportOne: ensureString(ref.palette.supportOne40, DEFAULT_COLOR),
    supportTwo: ensureString(ref.palette.supportTwo40, DEFAULT_COLOR),
    supportThree: ensureString(ref.palette.supportThree40, DEFAULT_COLOR),
    supportFour: ensureString(ref.palette.supportFour40, DEFAULT_COLOR),
    supportFive: ensureString(ref.palette.supportFive40, DEFAULT_COLOR),
    supportSix: ensureString(ref.palette.supportSix40, DEFAULT_COLOR),
  }), [ref.palette]);

  const portfolioTemplateAssetClassBranches = useMemo(() => {
    if (!portfolioTemplate?.assetClassBranches) {
      return [];
    }

    const assetClassNames = portfolioTemplate.assetClassBranches.map(
      (branch) => branch.assetClass.name,
    );

    const colorMap = assignColorsToAssetClasses(assetClassNames);

    return portfolioTemplate.assetClassBranches.map((branch) => ({
      name: branch.assetClass.name,
      templateColor: colorMap[branch.assetClass.name] || 'supportOne',
      templateTargetPercentage: branch.targetPercentage || 0,
    }));
  }, [portfolioTemplate]);

  const securitiesDataState = useMemo(() => {
    const isDummyData = securities.length === 1 && !securities[0].primaryAssetClass?.translatedName;
    const hasData = !isEmpty(securities) && !isDummyData;
    return { isDummyData, hasData };
  }, [securities]);

  const assetClassData = useMemo(() => {
    const allAssetClasses = new Set([
      ...securities.map((s) => s.primaryAssetClass?.translatedName?.[getBackendLanguage()] || 'Other'),
      ...portfolioTemplateAssetClassBranches.map((b) => b.name),
    ]);

    const colorMap = assignColorsToAssetClasses(Array.from(allAssetClasses));

    const assetClassGroups = securities.reduce((acc: Record<string, AssetClassGroup>, security: Security) => {
      const assetClass = security.primaryAssetClass?.translatedName?.[getBackendLanguage()] || 'Other';
      const templateBranch = portfolioTemplateAssetClassBranches.find((b) => b.name === assetClass);

      if (acc[assetClass]) {
        acc[assetClass].totalPercentage += security.targetPercentage || 0;
      } else {
        acc[assetClass] = {
          totalPercentage: security.targetPercentage || 0,
          name: assetClass,
          color: colorMap[assetClass] || 'supportOne',
          templateColor: templateBranch?.templateColor || colorMap[assetClass] || 'supportOne',
          templateTargetPercentage: templateBranch?.templateTargetPercentage || 0,
        };
      }
      return acc;
    }, {});

    portfolioTemplateAssetClassBranches.forEach((branch) => {
      if (!assetClassGroups[branch.name]) {
        assetClassGroups[branch.name] = {
          totalPercentage: 0,
          name: branch.name,
          color: colorMap[branch.name] || 'supportOne',
          templateColor: branch.templateColor,
          templateTargetPercentage: branch.templateTargetPercentage,
        };
      }
    });

    return Object.values(assetClassGroups).sort((a, b) => b.totalPercentage - a.totalPercentage);
  }, [securities, portfolioTemplateAssetClassBranches]);

  const pieChartDataAndColors = useMemo((): PieChartData => {
    if (securitiesDataState.hasData) {
      const totalPercentage = assetClassData.reduce((acc: number, x: any) => acc + Number(x.totalPercentage), 0);
      const remainingPercentage = Math.max(0, 100 - totalPercentage);

      return {
        pieChartData: [
          ...assetClassData.map((x) => Number(x.totalPercentage)),
          remainingPercentage,
        ],
        pieChartColors: [
          ...assetClassData.map((x) => ensureString(colorsSecurities[x.color as keyof typeof colorsSecurities], colorsSecurities.supportOne)),
          ensureString(sys.color.surfaceContainer, DEFAULT_COLOR),
        ],
      };
    }

    if (portfolioTemplateAssetClassBranches.length) {
      const totalPercentage = portfolioTemplateAssetClassBranches.reduce((acc: number, x: any) => acc + Number(x.templateTargetPercentage), 0);
      const remainingPercentage = Math.max(0, 100 - totalPercentage);

      return {
        pieChartData: [
          ...portfolioTemplateAssetClassBranches.map((x) => x.templateTargetPercentage),
          remainingPercentage,
        ],
        pieChartColors: [
          ...portfolioTemplateAssetClassBranches.map((x) => ensureString(colorsAssetClassTemplate[x.templateColor as keyof typeof colorsAssetClassTemplate], colorsAssetClassTemplate.supportOne)),
          ensureString(sys.color.surfaceContainer, DEFAULT_COLOR),
        ],
      };
    }

    return { pieChartData: [100], pieChartColors: [ensureString(sys.color.surfaceContainer, DEFAULT_COLOR)] };
  }, [assetClassData, colorsSecurities, colorsAssetClassTemplate, sys.color.surfaceContainer, securitiesDataState.hasData, portfolioTemplateAssetClassBranches]);

  return (
    <Card variant={variant}>
      <CardContent sx={{ p: '14px !important' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box position='relative' sx={{ height: 270 }}>
            <PieChart
              datasets={[{
                label: '',
                data: pieChartDataAndColors.pieChartData,
              }]}
              labels={assetClassData.map((x) => x.name)}
              onHover={(index: number | undefined) => {
                if (!isUndefined(index) && index < assetClassData.length) {
                  setActiveItem(assetClassData[index]);
                } else {
                  setActiveItem(null);
                }
              }}
              customColors={pieChartDataAndColors.pieChartColors}
              cutoutPercentage={80}
            />
            <Box
              textAlign='center'
              position='absolute'
              left='50%'
              top='50%'
              sx={{ transform: 'translate(-50%,-50%)' }}
            >
              {!securitiesDataState.hasData && !portfolioTemplateAssetClassBranches.length ? (
                <>
                  <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                    {modelPortfolioName}
                  </Typography>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                    0.00%
                  </Typography>
                </>
              ) : activeItem ? (
                <>
                  <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                    {activeItem.templateTargetPercentage
                      ? `${activeItem.name} (${activeItem.templateTargetPercentage}%)`
                      : activeItem.name}
                  </Typography>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                    {formatPercentage(activeItem.totalPercentage)}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography sx={{ color: sys.color.onSurfaceVariant, fontSize: '0.875rem' }}>
                    {modelPortfolioName}
                  </Typography>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                  {`${assetClassData.reduce((acc: number, x: any) => acc + Number(x.totalPercentage), 0).toFixed(2)}%`}
                  </Typography>
                </>
              )}
            </Box>
          </Box>

          {securitiesDataState.hasData || portfolioTemplateAssetClassBranches.length ? (
            <Box>
              {assetClassData.map((item, i) => (
                <AssetClassLegendItem
                  key={item.name}
                  item={item}
                  isActive={activeItem?.name === item.name}
                  isLast={i + 1 === assetClassData.length}
                  color={ensureString(colorsSecurities[item.color as keyof typeof colorsSecurities], colorsSecurities.supportOne)}
                  surfaceContainerColor={ensureString(sys.color.surfaceContainer, DEFAULT_COLOR)}
                  surfaceColor={ensureString(sys.color.surface, DEFAULT_COLOR)}
                  borderRadius={ensureString(sys.borderRadius.md, DEFAULT_BORDER_RADIUS)}
                  onMouseEnter={() => setActiveItem(item)}
                  onMouseLeave={() => setActiveItem(null)}
                />
              ))}
            </Box>
          ) : (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='left'
              sx={{
                py: 0.5,
                px: 1,
              }}
            >
              <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center'>
                <Box sx={{
                  borderRadius: '10px',
                  height: '8px',
                  width: '8px',
                  backgroundColor: sys.color.surfaceContainer,
                  mr: 1,
                }} />
                <Typography sx={{ fontSize: '0.75rem' }}>{t('modelPortfolios:modelPortfolioTable.assetClass')}</Typography>
              </Box>
              <Box display='flex' alignItems='center' gap={2}>
                <Typography sx={{ fontSize: '0.75rem', color: DEFAULT_TEXT_COLOR }}>
                  --%
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
