import { Maybe } from 'graphql/jsutils/Maybe';
import jp from 'jsonpath';
import { isNaN, isNull } from 'lodash/fp';
import { round } from 'lodash';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import i18n, { TFunction } from 'i18next';
import { RelationUser } from '../pages/accountsDetail/components/newAffiliate';
import {
  User, PhysicalAddress, ValidateRule, ScheduledTransfer, BankAccount,
  Holding,
} from '../interfaces';
import { availableFeatureFlagsVar } from './localVariables';
import { CustomFieldValue, CustomFieldFormats } from '../interfaces/customField';
import { BookValueType } from '../providers/statsHooks';

export const parseJwt = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  } catch {
    return {};
  }
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

export const formatMoneyValueCentsWithDecimals = (
  cents: number | undefined,
  currency = '$',
  fractionDigits = 2,
  standard = true,
): string => {
  const isFrench = i18n.language === 'fr' && currency === '$';
  const value = (cents ?? 0) / 100;

  const formatNumber = (num: number): string => {
    if (standard && num % 1 === 0) {
      return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, isFrench ? ' ' : ',')}${
        isFrench ? ',00' : '.00'}`;
    }

    let valueStr = num.toFixed(fractionDigits).replace(/\.?0+$/, '');
    if (!valueStr.includes('.')) valueStr += '.00';
    if (valueStr.match(/\.\d$/)) valueStr += '0';

    const [whole, decimals] = valueStr.split('.');
    const withSeparators = whole.replace(/\B(?=(\d{3})+(?!\d))/g, isFrench ? ' ' : ',');

    return `${withSeparators}${isFrench ? ',' : '.'}${decimals}`;
  };

  const formattedNumber = formatNumber(Math.abs(value));
  return isFrench
    ? `${value < 0 ? '-' : ''}${formattedNumber} ${currency}`
    : `${value < 0 ? '-' : ''}${currency}${formattedNumber}`;
};

export const formatMoneyValue = (cents: number | undefined, currency = '$', fractionDigits = 2, standard = true): string => {
  if (!cents) {
    if (i18n.language === 'fr' && currency === '$') return `${(cents ?? 0).toFixed(fractionDigits).replace('.', ',')} ${currency}`;
    return `${currency}${(cents ?? 0).toFixed(fractionDigits)}`;
  }

  if (standard) {
    const value = cents / 100;
    if (value % 1 === 0) {
      if (i18n.language === 'fr' && currency === '$') return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')} ${currency}`;
      return `${value < 0 ? '-' : ''}${currency}${Math.abs(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }
    if (i18n.language === 'fr' && currency === '$') return `${value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')} ${currency}`;

    return `${value < 0 ? '-' : ''}${currency}${Math.abs(value).toFixed(fractionDigits).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
  }

  const value = cents / 100;
  if (i18n.language === 'fr' && currency === '$') return `${value.toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')} ${currency}`;
  return `${value < 0 ? '-' : ''}${currency}${Math.abs(value).toFixed(fractionDigits).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
};

export const formatAmountValueCents = (cents: number, fractionDigits = 2): string => {
  const regex = /\B(?=(\d{3})+(?!\d))/g;

  if ((cents / 100) % 1 === 0) {
    return `${(cents / 100).toString().replace(regex, ',')}`;
  }

  return `${(Math.round(cents) / 100)
    .toFixed(fractionDigits)
    .toString()
    .replace(regex, ',')}`;
};

export const formatDecimalMoneyValue = (cents?: number, decimalPlaces = 4, currency = '$'): string => {
  if (!cents) {
    if (i18n.language === 'fr' && currency === '$') return `0 ${currency}`;
    return `${currency}0`;
  }
  const value = Math.abs(round(cents / 100, decimalPlaces)).toString().split('.');
  if (i18n.language === 'fr' && currency === '$') return `${cents < 0 ? '-' : ''}${value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')}${value[1] ? `.${value[1]}` : ''} ${currency}`;
  return `${cents < 0 ? '-' : ''}${currency}${value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${value[1] ? `.${value[1]}` : ''}`;
};

export const formatDecimalNumber = (number?: number, decimalPlaces?: number): string => {
  if (!number) return '0';
  let value = number.toString().split('.');
  let decimals = value[1];
  if (decimalPlaces) {
    value = round(number, decimalPlaces).toString().split('.');
    decimals = value[1] ? value[1].padEnd(decimalPlaces, '0') : ''.padEnd(decimalPlaces, '0');
  }
  if (i18n.language === 'fr') return `${value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${decimals ? `,${decimals}` : ''}`;
  return `${value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${decimals ? `.${decimals}` : ''}`;
};

export const generateAddressString = (address: Maybe<PhysicalAddress>): string => {
  if (!address) return '';

  const numbersSeparatpr = address.unitNumber && address.houseNumber ? '-' : '';
  return [
    [address.unitNumber, numbersSeparatpr, address.houseNumber, address.streetName].join(' ').trim(),
    address.city,
    address.jurisdiction ? address.jurisdiction.substring(3, 5) : address.province,
    address.postal,
    countryName(address.country || ''),
  ].filter(Boolean).join(', ');
};

export const generateShortAddressString = (address: PhysicalAddress): string => {
  if (!address) return '';

  return [address.city, address.jurisdiction ? address.jurisdiction.substring(3, 5) : address.province, countryName(address.country || 'CA')].filter(Boolean).join(', ');
};

function countryName(country: string): string {
  if (country === 'CA') return 'Canada';
  if (country === 'US') return 'USA';
  return country;
}

export const generateBankDetailString = (bankAccount: Pick<BankAccount, 'name' | 'institutionNumber' | 'bankAccountNumber'>): string => {
  const bankAccountName = bankAccount?.name ? `${bankAccount.name} ` : '';
  const bankAccountInstitutionNumber = bankAccount?.institutionNumber ?? '';
  const bankAccountNumber = bankAccount?.bankAccountNumber ?? '';

  const separator = bankAccount?.institutionNumber && bankAccount?.bankAccountNumber ? '-' : '';
  return `${bankAccountName}${bankAccountInstitutionNumber}${separator}${bankAccountNumber}`;
};

export const generateSourceText = (t: TFunction, transfer: ScheduledTransfer): string => {
  if (transfer?.toSubAccount) {
    const accType = t(`accountTypes:${transfer?.toSubAccount?.account?.type}`);
    return t('transferTo', { subAccount: accType });
  }
  return '';
};

export const generateTransferSourceText = (
  t: TFunction,
  transfer: ScheduledTransfer,
): string => `${generateSourceText(t, transfer)} ${transfer.bankAccount ? generateBankDetailString(transfer.bankAccount) : ''}`;

export const generateClientNameString = (user: User | undefined | RelationUser | any, onlyFirstName?: boolean, onlyFirstAndLastName?: boolean): string => {
  if (!user) return '';
  if (user.entityName) return user.entityName;
  if (!user?.firstName) return user?.id ?? '';
  if (onlyFirstAndLastName) return [user.firstName, user.lastName].filter(Boolean).join(' ');
  return onlyFirstName ? user.firstName : [user.firstName, user.middleName, user.lastName].filter(Boolean).join(' ');
};

export const generateClientInitials = (user: User | undefined): string => {
  if (!user) return '';
  if (user.entityName) return (user.entityName ?? '').substring(0, 1).toLocaleUpperCase();

  return [
    (user.firstName ?? '').substring(0, 1).toLocaleUpperCase(),
    (user.lastName ?? '').substring(0, 1).toLocaleUpperCase(),
  ].join('');
};

export const formatPercentValue = (percent: number, fractionDigits = 2): string => {
  const value = isNaN(percent) ? '0' : `${(percent * 100).toFixed(fractionDigits).toString()}`;

  return i18n.language === 'fr' ? `${value.replace('.', ',')} %` : `${value}%`;
};

export const validateForm = (rules: ValidateRule[], formObject: Record<string, unknown>): boolean => {
  let validate = false;
  for (const attr of rules) { // implement rules that you need to compare
    if (attr.rule === 'SHOULD_EXIST') {
      if (attr.pattern) {
        const destinationValues = jp.query(formObject, attr?.pattern)[0]; // use destination  value to check
        validate = !!destinationValues;
      } else {
        const attributeValue = formObject[attr.key];
        const isExist = Object.prototype.hasOwnProperty.call(formObject, attr.key);
        validate = isExist && (attributeValue !== '');
      }
      if (!validate) {
        return validate;
      }
    }
  }
  return validate;
};

export const formatPhoneNumber = (phone: string | undefined): string => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const PNF = PhoneNumberFormat;

  if (!phone) return '';
  if (phone.length < 10) return phone;

  const parsed = phoneUtil.parse(phone, 'CA');
  const isNANP = parsed.getCountryCode() === 1;

  return phoneUtil.format(parsed, isNANP ? PNF.NATIONAL : PNF.INTERNATIONAL);
};

export const entityName = (entity: any, full = false) => {
  if (entity?.firstName && entity?.lastName) {
    return `${entity?.firstName}${full && entity?.middleName ? ` ${entity.middleName}` : ''} ${entity.lastName}`;
  }
  return entity?.entityName;
};

export const entityInitials = (entity: any) => {
  if (entity?.firstName || entity?.lastName) return `${(entity?.firstName?.toUpperCase() ?? '')[0] ?? ''}${(entity?.lastName?.toUpperCase() ?? '')[0] ?? ''}`;
  if (entity?.entityName) return entity.entityName.toUpperCase()[0];
  return (entity?.primaryEmail?.toUpperCase() ?? '')[0];
};

export const entityNameWithGreeting = (greeting: string, firstName?: string) => {
  if (firstName) return `${greeting}, ${firstName}`;
  return greeting;
};

export const entityLink = (entity: Pick<User, 'id' | 'type'>): string => {
  if (entity.type === 'INDIVIDUAL') {
    return (`/clients/${entity.id}`);
  }
  return (`/nonIndividualClients/${entity.id}`);
};

export const isFeatureEnabled = (flag: string): boolean => availableFeatureFlagsVar().includes(flag);

export const validateUrl = (url: string): boolean => {
  const regex = /(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.([-a-zA-Z0-9@:%_+.~#?&//=]*)+$)|^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
  return regex.test(url);
};

export const parseLink = (url: string) => {
  try {
    return new URL(url).href;
  } catch (error) {
    return `https://${url}`;
  }
};

export const getCustomFieldValueByKey = (key: string, customFieldValues: CustomFieldValue[], t?: TFunction, showDefaultVal?: boolean) : any => {
  const customFieldValue = customFieldValues.find((elem) => elem.key.toLowerCase() === key);

  if (customFieldValue?.customField?.format) {
    const { format } = customFieldValue.customField;
    switch (format) {
      case CustomFieldFormats.SIMPLE_TEXT:
        return customFieldValue?.value ?? '-';
      case CustomFieldFormats.CURRENCY:
        return formatMoneyValue(customFieldValue?.value ?? 0);
      case CustomFieldFormats.NUMBER:
        return customFieldValue?.value ?? 0;
      case CustomFieldFormats.TOGGLE_SWITCH:
      case CustomFieldFormats.RADIO_BUTTON:
        return typeof customFieldValue?.value === 'boolean' && t ? (customFieldValue?.value ? t('shared:yes') : t('shared:no')) : '-';
      default:
        return '-';
    }
  }
  if (customFieldValue?.value) {
    return customFieldValue.value;
  }
  return showDefaultVal ? '-' : undefined;
};

export const isValidMongoId = (id: string) => {
  // Check if id is a stringified Bson Object
  if (id.length !== 24) return false;
  if (!/^[0-9a-fA-F]{24}$/.test(id)) {
    return false;
  }
  // Extract and validate the timestamp portion
  const timestamp = id.substring(0, 8);
  const date = new Date(parseInt(timestamp, 16) * 1000);
  if (date.getTime() <= 0 || date.getTime() >= Date.now()) {
    return false;
  }

  const machineIdentifier = id.substring(8, 14);
  const processIdentifier = id.substring(14, 18);
  const counter = id.substring(18, 24);
  // Ensure that the machine identifier, process identifier, and counter are hexadecimal
  const hexRegex = /^[a-f\d]+$/i;
  if (!hexRegex.test(machineIdentifier)
    || !hexRegex.test(processIdentifier)
    || !hexRegex.test(counter)) {
    return false;
  }
  return true;
};

export const getBookCostCents = (holding: Holding, bookValueType?: BookValueType):number => {
  const hasBookValueType = (bookValueType && !isNull(holding[bookValueType as BookValueType]) && !!holding[bookValueType as BookValueType]);
  let value = 0;
  if (isNull(holding.financialProduct?.isCash) || !holding.financialProduct?.isCash) {
    value = (holding.adjustedCostBaseCents ?? 0) * (holding.quantity ?? 0);
  }
  if (hasBookValueType) {
    value = holding[bookValueType as BookValueType] ?? 0;
  }
  return value;
};

export const truncateNumber = (number: string): string => {
  if (number.length <= 4) return number; // If the number has 4 or fewer digits, don't truncate.

  const visiblePart = number.slice(-4);
  const maskedPart = '*'.repeat(number.length - 4);
  return maskedPart + visiblePart;
};
