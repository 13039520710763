import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { OpenInNew } from '@mui/icons-material';
import {
  FETCH_ACCOUNT_STATEMENT, FETCH_ACCOUNT_STATEMENTS, FETCH_CUSTODIAN_EXTERNAL_DOCUMENT, FETCH_CUSTODIAN_STATEMENTS,
} from '../documents.queries';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import {
  EmptyStateHeaderTitle, CircularProgress, Pagination, EmptyStateAlt,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { PageObjectType } from '../../../../5-page';

export const DocumentList = ({
  objectId, objectType, documentTypes, options = {}, filters,
}: {
  objectId: string, objectType: PageObjectType, documentTypes: string[], options?: any,
  filters?: { searchText?: string, dateStart?: string, dateEnd?: string },
}) => {
  const { sys } = useThemeTokens();
  const { t } = useTranslation(['document']);
  const [page, setPage] = useState<number>(1);
  const [errorState, setErrorState] = useState(false);

  const baseFilter = () => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
        return { userId: objectId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: objectId };
      case PageObjectType.ACCOUNT:
        return options.useCustodianStatements ? { accountIds: [objectId] } : { accountId: objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupIds: [objectId] };
      default:
        return { userId: objectId };
    }
  };

  const useUserFilter = !options.useCustodianStatements && filters && (filters.searchText || filters.dateStart || filters.dateEnd);
  const userFilter: any = {};
  if (useUserFilter) {
    if (filters.dateStart) {
      userFilter.afterDate = filters.dateStart;
    }
    if (filters.dateEnd) {
      userFilter.beforeDate = filters.dateEnd;
    }
    if (filters.searchText) {
      userFilter.searchText = filters.searchText;
    }
  }

  useEffect(() => {
    // Reset page when filters are changed
    setPage(1);
  }, [filters?.searchText, filters?.dateStart, filters?.dateEnd]);

  const QUERY = options.useCustodianStatements ? FETCH_CUSTODIAN_STATEMENTS : FETCH_ACCOUNT_STATEMENTS;

  const [fetchExternalDocument, { loading: documentLoading }] = useLazyQuery(FETCH_CUSTODIAN_EXTERNAL_DOCUMENT);
  const { data, loading } = useQuery(QUERY, {
    variables: {
      input: {
        filter: { ...userFilter, ...baseFilter(), types: documentTypes },
        pagination: {
          offSet: (page - 1) * 15, perPage: 15, sortField: 'referenceDate', sortDesc: false,
        },
      },
      skipErrorHandler: true,
    },
    onError: () => {
      setErrorState(true);
    },
  });

  const [fetchAccountStatement] = useLazyQuery(FETCH_ACCOUNT_STATEMENT, { fetchPolicy: 'network-only' });

  if (loading) {
    return (
      <>
        <Box display={'flex'} gap={2} pt={2}>
          <Skeleton width='80%' height='24px' />
          <Skeleton width='20%' height='24px'/>
        </Box>
        <Box display={'flex'} gap={2} pt={2}>
          <Skeleton width='80%' height='24px' />
          <Skeleton width='20%' height='24px'/>
        </Box>
        <Box display={'flex'} gap={2} pt={2}>
          <Skeleton width='80%' height='24px' />
          <Skeleton width='20%' height='24px'/>
        </Box>
      </>
    );
  }

  const totalCount = options.useCustodianStatements ? data?.fetchCustodianStatements?.totalCount : data?.fetchAccountStatements?.totalCount;
  const statements = options.useCustodianStatements ? data?.fetchCustodianStatements?.statements : data?.fetchAccountStatements?.accountStatements;

  const isOpenBase64Pdf = (signedUrl: any, signedUrlFormat: any) => {
    // If signedUrl is encoded pdf, then we convert back into PDF
    if (signedUrlFormat === 'ENCODED_PDF') {
      const byteCharacters = atob(signedUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    }

    return signedUrl;
  };

  const onDocumentClick = async (statement: any) => {
    if (statement.fetchExternalDocument) {
      const { viewId, documentId, clientId } = statement;

      const result = await fetchExternalDocument({
        variables: {
          input: {
            filter: {
              ...baseFilter(), types: documentTypes, viewId, documentId, clientId,
            },
          },
        },
        fetchPolicy: 'no-cache',
      });

      const documentData = result.data?.fetchCustodianExternalDocument;
      if (documentData) {
        // we need setTimeout to open the url in Safari
        setTimeout(() => window.open(isOpenBase64Pdf(documentData.document, documentData.signedUrlFormat || ''), '_blank'));
      }
      return;
    }

    if (!options.useCustodianStatements) {
      fetchAccountStatement({
        variables: { accountStatementId: statement.id },
        // we need setTimeout to open the url in Safari
        onCompleted: (statementDoc) => setTimeout(() => window.open(statementDoc.fetchAccountStatement.accountStatement.signedUrl, '_blank')),
      });
      return;
    }
    // we need setTimeout to open the url in Safari
    setTimeout(() => window.open(isOpenBase64Pdf(statement.signedUrl, statement.signedUrlFormat || ''), '_blank'));
  };

  return (
    <Box width='100%'>
       {errorState && !loading && (
          <EmptyStateAlt
          sx={{
            mt: 2,
            textAlign: 'center',
            height: '100px',
            flexDirection: 'column',
          }}
          title={t('document:errorStateTitleDocuments')}
          subtitle={t('shared:errorStateSubText')}
        />
       )}
      {
        totalCount === 0 && !loading && !errorState && (
          <EmptyStateHeaderTitle sx={{ mt: 2 }} title={useUserFilter ? t('document:noDocumentsFiltered') : t('document:noDocuments')} />
        )
      }
      <Box position='relative'>
        {documentLoading && (
          <Box display='flex' justifyContent='center' alignItems='center' position='absolute' top={0} left={0} width='100%' height="100%">
            <CircularProgress />
          </Box>
        )}
        {Array.isArray(statements) && statements.length > 0 && statements.map((statement: any) => (
          <Box key={statement.id || ''} display='flex' px={2} py={1} justifyContent='space-between' alignItems='center' onClick={() => onDocumentClick(statement)} sx={{
            borderRadius: sys.borderRadius.md,
            cursor: 'pointer',
            '&:hover': { backgroundColor: sys.color.surfaceContainerVariant },
          }}>
            <Box width='100%' display='flex'>
              <Typography variant='bodyMedium' sx={{ minWidth: '120px', color: sys.color.onSurfaceVariant }}>{dayjs(statement.referenceDate).format('MMMM YYYY')}</Typography>
              <Typography variant='bodyMedium'>{translateBackend(statement.translatedFileName)}</Typography>
            </Box>
            <OpenInNew sx={{ color: sys.color.onSurfaceVariant }} />
          </Box>
        ))}
      </Box>
      {
        totalCount > 0 && (
          <Box display='flex' justifyContent='end' mt={2}>
            <Pagination page={page} onChange={(e: any, newPage: number) => setPage(newPage)} count={Math.ceil(totalCount / 15)} disabled={documentLoading} size='small' />
          </Box>
        )
      }
    </Box>
  );
};
