import { FeatureFlagTypes } from '../../../interfaces/organizationFeatureFlag';

export const reorder = (result: any, columns: any[]): any[] => {
  const { destination, source } = result;

  if (!destination) {
    return columns;
  }

  if (
    destination.droppableId === source.droppableId
    && destination.index === source.index
  ) {
    return columns;
  }

  if (destination.droppableId === source.droppableId) {
    const column = columns.find((c: any) => c.id === source.droppableId);
    const newSubSteps = Array.from(column.subSteps);
    const movedObject = newSubSteps.splice(source.index, 1)[0];
    newSubSteps.splice(destination.index, 0, movedObject);

    const newColumns = columns.map((c: any) => {
      if (c.id === source.droppableId) {
        return {
          ...c,
          subSteps: newSubSteps,
        };
      }
      return c;
    });

    return newColumns;
  }

  const oldColumn = columns.find((c: any) => c.id === source.droppableId);
  const oldSubSteps = Array.from(oldColumn.subSteps);
  const movedObject = oldSubSteps.splice(source.index, 1)[0];

  const newColumn = columns.find((c: any) => c.id === destination.droppableId);
  const newSubSteps = Array.from(newColumn.subSteps);
  newSubSteps.splice(destination.index, 0, movedObject);

  const newColumns = columns.map((c: any) => {
    if (c.id === source.droppableId) {
      return {
        ...c,
        subSteps: oldSubSteps,
      };
    }
    if (c.id === destination.droppableId) {
      return {
        ...c,
        subSteps: newSubSteps,
      };
    }
    return c;
  });

  return newColumns;
};

export const reorderColumns = (result: any, columns: any[]): any[] => {
  const { source, destination } = result;

  if (!destination) {
    return columns;
  }

  const newColumns = Array.from(columns);
  const [movedColumn] = newColumns.splice(source.index, 1);
  newColumns.splice(destination.index, 0, movedColumn);

  return newColumns;
};

/**
 * Workflow configuration specific function that dynamically adds sub-step options based on organization feature flags that are enabled
 *
 * Has no return value as this is manipulating the array of sub-steps directly
 *
 * @param currentSubSteps an array of sub-steps
 * @param availableFeatureFlags list of available feature flags to be checked against
 */
export const updateWorkflowSubSteps = (currentSubSteps: string[], availableFeatureFlags?: string[]) => {
  if (availableFeatureFlags?.includes('DEPOSIT_WORKFLOW')) {
    if (!currentSubSteps.includes('DEPOSIT_TYPE')) currentSubSteps.push('DEPOSIT_TYPE');
    if (!currentSubSteps.includes('DEPOSIT_FORM')) currentSubSteps.push('DEPOSIT_FORM');
    if (!currentSubSteps.includes('DEPOSIT_REVIEW')) currentSubSteps.push('DEPOSIT_REVIEW');
  }
  if (availableFeatureFlags?.includes('WITHDRAW_WORKFLOW')) {
    if (!currentSubSteps.includes('WITHDRAW_FORM')) currentSubSteps.push('WITHDRAW_FORM');
    if (!currentSubSteps.includes('WITHDRAW_REVIEW')) currentSubSteps.push('WITHDRAW_REVIEW');
  }

  if (availableFeatureFlags?.includes('ONBOARDING_WITHOUT_GOALS') && (!currentSubSteps.includes('CREATE_ACCOUNT'))) {
    currentSubSteps.push('CREATE_ACCOUNT');
  }
};

export const jointAffiliateConfigKeys = [
  'enableJointAccountNewJointHolder',
  'jointAccountHolderWorkflow',
  'enableJointSingleRESPNewJointHolder',
  'jointSingleRESPHolderWorkflow',
  'enableJointFamilyRESPNewJointHolder',
  'jointFamilyRESPHolderWorkflow',
];

export const affiliateConversionConfigKeys = [
  'enableConvertedAffiiateOnboarding',
  'convertedAffiiateWorkflow',
  'createFullUsers',
];
export const filterOptionBasedOnFeatureFlag = (option: any, organizationFeatureFlags?: string[]): boolean => {
  if (jointAffiliateConfigKeys.includes(option.key) && !organizationFeatureFlags?.includes(FeatureFlagTypes.JOINT_AFFILIATE_CONFIGURATIONS)) {
    return false;
  }
  if (affiliateConversionConfigKeys.includes(option.key) && !organizationFeatureFlags?.includes(FeatureFlagTypes.AFFILIATE_CONVERSION_CONFIGURATIONS)) {
    return false;
  }
  return true;
};
