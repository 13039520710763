import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { kebabCase } from 'lodash';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Typography, Form, SelectionTile, Box,
} from '../../../../..';
import { ovAnalyticsEvents } from '../../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../../providers/analyticsProvider';
import { FormErrors, FieldOptions, validateFields } from '../../utils';
import { CreateModelPortfolioOptions } from '../createModelPortfolio';

interface ChoosePortfolioTypeProps {
  options: CreateModelPortfolioOptions['choosePortfolioType'];
  modelPortfolioData: any;
  loading: boolean;
  updateModelPortfolio: (data: any) => void;
  onContinue: () => void;
  workflowCompletion?: any;
}

export const ChoosePortfolioType = ({
  options,
  modelPortfolioData,
  loading,
  updateModelPortfolio,
  onContinue,
  workflowCompletion,
}: ChoosePortfolioTypeProps) => {
  const { t } = useTranslation('shared');
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [focused, setFocused] = useState<string[]>([]);
  const [errors, setErrors] = useState<FormErrors>(null);

  const fieldOptions: FieldOptions = useMemo(() => ({
    creationType: { required: true },
  }), []);

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const data = {
      creationType: modelPortfolioData.creationType,
    };
    const newErrors = validateFields(fieldOptions, data, candidateFields);
    setErrors(newErrors);
    return newErrors;
  }, [fieldOptions, modelPortfolioData]);

  const submit = () => {
    const formErrors = validate();

    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }

    if (!formErrors) {
      sendAnalytic(ovAnalyticsEvents.workflowsCreateModelPortfolioContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      onContinue();
    }
  };

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{
          mt: 1,
          mb: 2,
          fontWeight: 400,
          fontSize: '40px',
          fontFamily: 'Tiempos Text',
        }}>
          {translateBackend(options.title)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography variant='bodyLarge' sx={{ mb: 3 }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
              {translateBackend(options.subtitle)}
            </ReactMarkdown>
          </Box>
        </Typography>
      )}

      <SelectionTile
        testId="create-model-portfolio"
        onChange={(e: any) => updateModelPortfolio({ ...modelPortfolioData, creationType: e.target.value })}
        value={modelPortfolioData.creationType ?? ''}
        options={options?.options?.map((option: any) => ({
          value: option.value,
          label: translateBackend(option.label),
          subtitle: translateBackend(option.description),
        }))}
        error={errors?.creationType?.active}
      />
      <Box display='flex' justifyContent='end'>
        <Button
          dataTestId={`create-model-portfolio-${kebabCase(translateBackend(options.title))}-continue-button`}
          label={t('continue')}
          disabled={loading || !modelPortfolioData.creationType}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit'
        />
      </Box>
    </Form>
  );
};
