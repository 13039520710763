import {
  useEffect, useMemo, useState, useContext,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { CheckCircle, Error, ChangeCircle } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { isNil } from 'lodash/fp';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { Box, Skeleton, Typography } from '../../../1-primative';
import { FETCH_ACCOUNT } from './accountHighlights.queries';
import { Card, CardContent, StatusTag } from '../../../2-component';
import { AccountHighlightsConfig } from './accountHighlightsConfig';
import { AccountHighlightsContribution } from './accountHighLightsContribution';
import { generateClientNameString, getCustomFieldValueByKey, formatMoneyValue } from '../../../../util';
import { CustomField, CustomFieldValue } from '../../../../interfaces/customField';
import { AccountHighlightsCustomFields } from './accountHighLightsCustomFields';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { evaluateTriggerRules } from '../../../../util/evaluateTriggerRules';
import { translateBackend } from '../../../../assets/i18n/config';
import { Statistics } from '../../../../interfaces';
import { showAccountNumber } from '../../workflowCompletion/subSteps/utils';

export interface Section {
  enabledBy?: 'displayContributions' | 'displayWithdrawals' | 'displaySimplifiedAccountStatus' | 'showCustodianName' | 'displaySettledCash' | 'displayUnsettledCash' | 'displayAccountRepCode' |
  'displayCashOnHoldToWithdraw' | 'displayCashOnHoldToTrade' | 'displayNewContributions' | 'displayCashAvailable';
  label: string,
  key: string,
  showIf?: () => boolean,
  useCustodianData?: boolean,
  isContribution: boolean,
  isCustomField?: boolean,
  value?: any,
  infoTooltip?: any,
}

const createCustomFieldSection = (templateCustomFields: CustomField[], customData: CustomFieldValue[], t: TFunction) : Section[] => templateCustomFields.map((cf) => ({
  label: cf.translatedName?.en || '',
  key: cf.key,
  isContribution: false,
  isCustomField: true,
  value: getCustomFieldValueByKey(cf.key, customData, t, true),
  infoTooltip: translateBackend(cf?.translatedDescription),
})).filter((cf) => cf.value !== undefined);

export const AccountHighlights = ({
  objectId, options, useCustodianData, statistics,
}: { objectId: string, options: any, useCustodianData?: boolean, statistics?: Statistics | null }) => {
  const { t } = useTranslation(['components', 'accountsDetail']);
  const { sys } = useThemeTokens();
  const { custodianConnection } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [accountSections, setAccountSections] = useState<Section[]>([]);

  const useCustodianCustomFields = custodianConnection?.enableFetchCustodianCustomFields;
  const customFieldKeys = Object.keys(options).filter((key) => {
    const value = options[key];
    return value && value.customField;
  });

  const { loading, data } = useQuery(FETCH_ACCOUNT(permissions, useCustodianCustomFields, customFieldKeys), {
    variables: { accountId: objectId },
  });

  const accountHighlightsConfig = AccountHighlightsConfig(translateBackend(options.contributionsLabel));

  useEffect(() => {
    let customFields: CustomFieldValue[] = [];
    let sections: Section[] = [
      { label: t('accountOwners'), key: 'accountOwners', isContribution: false },
      { label: t('accountNo'), key: 'accountNumber', isContribution: false },
      {
        enabledBy: 'displayCashOnHoldToWithdraw', label: t('cashOnHoldToWithdraw'), key: 'cashOnHoldToWithdrawCents', isContribution: false, showIf: () => !useCustodianData,
      },
      {
        enabledBy: 'displayCashOnHoldToTrade', label: t('cashOnHoldToTrade'), key: 'cashOnHoldToTradeCents', isContribution: false, showIf: () => !useCustodianData,
      },
      {
        enabledBy: 'displayNewContributions', label: t('newContribution'), key: 'newContributionCents', isContribution: false, showIf: () => !useCustodianData,
      },
      {
        enabledBy: 'displayCashAvailable', label: t('moneyAvailable'), key: 'moneyAvailableCents', isContribution: false, showIf: () => !useCustodianData,
      },
      {
        enabledBy: 'displaySimplifiedAccountStatus', label: t('status'), key: 'displaySimplifiedAccountStatus', isContribution: false,
      },
      {
        enabledBy: 'showCustodianName', label: t('accountsDetail:custodianName'), key: 'showCustodianName', isContribution: false,
      },
      {
        enabledBy: 'displayAccountRepCode', label: t('displayAccountRepCode'), key: 'displayAccountRepCode', isContribution: false,
      },
      {
        enabledBy: 'displaySettledCash', label: t('displaySettledCash'), key: 'displaySettledCash', isContribution: false,
      },
      {
        enabledBy: 'displayUnsettledCash', label: t('displayUnsettledCash'), key: 'displayUnsettledCash', isContribution: false,
      },
    ];

    if (data && data.fetchAccount && data.fetchAccount.account && data.fetchAccount.account.customFields) {
      customFields = [...data.fetchAccount.account.customFields];
    }

    if (data?.fetchAccount?.account?.type) {
      const templateCustomFields = Object.keys(options).filter((key) => {
        const value = options[key];
        return value && value.customField;
      }).map((key) => {
        const { enabled, customField } = options[key];
        return { isEnabled: enabled, ...customField };
      });

      let customSection: Section[] = [];

      if (templateCustomFields.length > 0) {
        if (data.fetchAccount.account.custodianCustomFields) {
          customFields = [...customFields, ...(data.fetchAccount.account.custodianCustomFields)];
        }
        const filtered = templateCustomFields.filter((x) => x.isEnabled).filter((cus: CustomField) => evaluateTriggerRules(cus, data.fetchAccount.account));
        customSection = createCustomFieldSection(filtered, customFields, t);
      }

      const { type } = data.fetchAccount.account;
      let typeSecs = accountHighlightsConfig[type];
      if (!typeSecs) typeSecs = accountHighlightsConfig.default;
      sections = [
        ...sections,
        ...typeSecs.sections,
        ...(customSection.length > 0 ? customSection : []),
      ].filter((sec) => (!sec.enabledBy || options[sec.enabledBy]) && (!sec.showIf || sec.showIf()) && (isNil(sec.useCustodianData) || sec.useCustodianData === !!useCustodianData));
    }
    setAccountSections(sections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options, t, useCustodianData]);

  const accountState = useMemo(() => {
    switch (data?.fetchAccount?.account?.state) {
      case 'ACTIVE':
        return 'ACTIVE';
      case 'CANCELED':
      case 'INACTIVE':
      case 'FAILED':
        return 'CLOSED';
      default:
        return 'PENDING';
    }
  }, [data]);

  const ownerString = () => {
    const jointUser = data?.fetchAccount?.account?.affiliations?.find((x: any) => x.type === 'JOINT');
    const clientNameStr = generateClientNameString(data?.fetchAccount?.account?.user);

    return jointUser ? `${clientNameStr} & ${generateClientNameString(jointUser.user)}` : clientNameStr;
  };

  const getHighlight = (element: Section) => {
    if (!data?.fetchAccount?.account?.type) return <></>;
    if (element.key === 'accountOwners') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Typography variant='bodyMedium' weight='bold'>{ownerString() ?? '-'}</Typography>
        </Box>
      );
    }
    if (element.key === 'accountNumber') {
      let accountNumber = data?.fetchAccount?.account?.custodianAccountNumber;
      if (accountNumber && options.displayAccountNumberWithDashes) {
        accountNumber = showAccountNumber(accountNumber, false, options.displayAccountNumberWithDashesFormat);
      } else if (!accountNumber) {
        accountNumber = '-';
      }
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Typography variant='bodyMedium' weight='bold'>{accountNumber}</Typography>
        </Box>
      );
    }
    if (element.key === 'displayAccountRepCode') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Typography variant='bodyMedium' weight='bold'>{data?.fetchAccount?.account?.repCode?.code ?? '-'}</Typography>
        </Box>
      );
    }
    if (element.key === 'displaySettledCash') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Typography
            variant='bodyMedium'
            weight='bold'
          >
          {formatMoneyValue((data?.fetchAccount?.account?.statistics?.moneyAvailableCents ?? 0)
            - (data?.fetchAccount?.account?.statistics?.pendingCashCents ?? 0))}
          </Typography>
        </Box>
      );
    }
    if (element.key === 'displayUnsettledCash') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(data?.fetchAccount?.account?.statistics?.pendingCashCents ?? 0)}</Typography>
        </Box>
      );
    }
    if (element.key === 'displaySimplifiedAccountStatus') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Box display='flex'>
            <StatusTag
              size='small'
              variant='bodyMedium'
              weight='bold'
              color={accountState === 'ACTIVE' ? 'positive' : accountState === 'CLOSED' ? 'negative' : 'neutral'}
              label={t(`accountsDetail:accountState.${accountState}`)}
              icon={accountState === 'ACTIVE' ? CheckCircle : accountState === 'CLOSED' ? Error : ChangeCircle }
            />
          </Box>
        </Box>
      );
    }
    if (element.isContribution) {
      return <AccountHighlightsContribution accountId={objectId} section={element} useCustodianData={useCustodianData} />;
    }
    if (element.isCustomField) {
      return <AccountHighlightsCustomFields section={element} />;
    }
    if (element.key === 'showCustodianName') {
      return (
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
        <Typography variant='bodyMedium' weight='bold'>{data?.fetchAccount?.account?.custodianAccountName ?? '-'}</Typography>
      </Box>
      );
    }
    if (element.key === 'cashOnHoldToWithdrawCents') {
      return (
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
        <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.cashOnHoldToTradeCents ?? 0)}</Typography>
      </Box>
      );
    }
    if (element.key === 'cashOnHoldToTradeCents') {
      return (
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
        <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.cashOnHoldToWithdrawCents ?? 0)}</Typography>
      </Box>
      );
    }
    if (element.key === 'newContributionCents') {
      return (
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
        <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.newContributionCents ?? 0)}</Typography>
      </Box>
      );
    }
    if (element.key === 'moneyAvailableCents') {
      return (
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
        <Typography variant='bodyMedium' weight='bold'>{formatMoneyValue(statistics?.moneyAvailableCents ?? 0)}</Typography>
      </Box>
      );
    }
    return <></>;
  };

  if (loading) {
    return <Skeleton width='100%' height='80px' variant='rectangular' />;
  }

  return (
    <Card sx={{ display: accountSections.length > 0 ? undefined : 'none' }}>
      <CardContent sx={{ px: 0, pb: '0 !important', backgroundColor: sys.color.surface }}>
        <Box sx={{ pb: 1.5, pt: 0.5, px: 2 }}>
          <Typography variant='titleMedium'>{t('accountHighlights')}</Typography>
        </Box>
        {accountSections.map((elem, index) => (
          <Box key={index} sx={{
            backgroundColor: index % 2 === 0 ? sys.color.surfaceContainer : undefined, px: 2, py: 1,
          }}>
            {getHighlight(elem)}
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};
