import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Close, Edit, CheckCircle, Error, ChangeCircle,
} from '@mui/icons-material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {
  Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Button, SelectField, MenuItem, Switch,
  StatusTag,
} from '../../../2-component';
import {
  FETCH_MODEL_PORTFOLIOS, FETCH_SUB_ACCOUNT, UPDATE_SUB_ACCOUNT, FETCH_GOAL, UPDATE_GOAL,
} from './changeTheme.queries';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../assets/i18n/config';
import { ThemeSelect } from '../../../3-pattern/themeSelect/themeSelect';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { PageObjectType } from '../../../5-page';
import { Statistics, SubAccount } from '../../../../interfaces';
import { formatMoneyValue } from '../../../../util';

const SUGGESTED_PRODUCT = `#graphql
  id
  translatedName { en fr}
  url
  children {
    id
    percentage
    financialProduct {
      id
      translatedName { en fr}
      primaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
      secondaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
    }
  }
`;

const SUGGEST_PORTFOLIO = gql`
  query suggestPortfolio($suitabilityScore: Int!, $organizationId: ObjectID!, $themeId: ObjectID) {
    suggestFinancialProduct(input: {
      suitabilityScore: $suitabilityScore
      organizationId: $organizationId
      themeId: $themeId
    }) {
      financialProduct {
        ${SUGGESTED_PRODUCT}
      }
    }
  }
`;

export const ChangeTheme = ({
  objectId, objectType, options, showLoader = false, statistics, useCustodianData,
}: {
  objectId: string; objectType: PageObjectType; options: any; showLoader?: boolean; statistics?: Statistics | null; useCustodianData?: boolean;
}) => {
  const [theme, setTheme] = useState<any>();
  const [modelPortfolio, setModelPortfolio] = useState<any>();
  const [portfolios, setPortfolios] = useState<any[]>();
  const [object, setObject] = useState<any>();
  const [subAccountUpdateData, setSubAccountUpdateData] = useState<Partial<SubAccount>>({});
  const [isMakingNetworkCall, setIsMakingNetworkCall] = useState<boolean>(false);

  const { t } = useTranslation(['client', 'components']);
  const [open, setOpen] = useState<boolean>(false);
  const { sys } = useThemeTokens();
  const { activeOrganization } = useContext(UserContext);
  const [suggestPortfolio] = useLazyQuery(SUGGEST_PORTFOLIO);
  const { permissions } = usePermissions();

  const showAllThemes = permissions.includes('read:all_themes');

  const { data: subAccountData, loading: subAccountLoading, refetch: subAccountRefetch } = useQuery(FETCH_SUB_ACCOUNT, {
    variables: { subAccountId: objectId },
    fetchPolicy: 'no-cache',
    skip: objectType !== PageObjectType.SUB_ACCOUNT,
  });

  const subAccountWithGoalPortfolio = objectType === PageObjectType.SUB_ACCOUNT && Boolean(subAccountData?.fetchSubAccount?.subAccount?.goal?.financialProduct);

  const { data: goalData, loading: goalLoading, refetch: goalRefetch } = useQuery(FETCH_GOAL(options.useExternalSuitabilityScore === true), {
    variables: { goalId: objectId },
    fetchPolicy: 'no-cache',
    skip: objectType !== PageObjectType.GOAL,
  });

  const refetch = () => {
    if (objectType === PageObjectType.SUB_ACCOUNT) subAccountRefetch();
    if (objectType === PageObjectType.GOAL) goalRefetch();
  };

  useQuery(FETCH_MODEL_PORTFOLIOS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          themeId: theme?.id,
          state: 'ACTIVE',
          suggestable: showAllThemes ? undefined : true,
        },
        pagination: { perPage: 1000 },
      },
    },
    onCompleted: (d: any) => {
      setPortfolios(d.fetchModelPortfolios.modelPortfolios);
    },
    fetchPolicy: 'no-cache',
  });

  const [updatePortfolio] = useMutation(objectType === PageObjectType.SUB_ACCOUNT ? UPDATE_SUB_ACCOUNT : UPDATE_GOAL);
  const [updateTheme] = useMutation(objectType === PageObjectType.SUB_ACCOUNT ? UPDATE_SUB_ACCOUNT : UPDATE_GOAL, {
    variables: {
      input: {
        subAccountId: objectType === PageObjectType.SUB_ACCOUNT ? objectId : undefined,
        goalId: objectType === PageObjectType.GOAL ? objectId : undefined,
        themeId: objectType === PageObjectType.SUB_ACCOUNT && !subAccountWithGoalPortfolio ? theme?.id : undefined,
        financialProductId: options.setModelPortfolio && !subAccountWithGoalPortfolio ? modelPortfolio?.id : undefined,
        ...(objectType === PageObjectType.SUB_ACCOUNT ? {
          skipIPS: options.enableSkipIPSEdit ? subAccountUpdateData.skipIPS : undefined,
          isPartial: options.enableIsPartialEdit ? subAccountUpdateData.isPartial : undefined,
          allowClientDeposits: options.enableAllowClientDepositsEdit ? subAccountUpdateData.allowClientDeposits : undefined,
        } : {}),
      },
    },
    onCompleted: (d: any) => {
      if (!options.setModelPortfolio && objectType === PageObjectType.SUB_ACCOUNT) {
        updatePortfolio({
          variables: {
            input: {
              subAccountId: objectType === PageObjectType.SUB_ACCOUNT ? objectId : undefined,
              financialProductId: d.updateSubAccount?.subAccount?.suggestedFinancialProduct?.id,
            },
          },
          onCompleted: () => {
            setOpen(false);
            refetch();
          },
        }).then();
      } else {
        setOpen(false);
        refetch();
      }
    },
  });

  const submitTheme = async () => {
    if (objectType === PageObjectType.GOAL && object.financialProduct && object?.financialProduct?.theme.id !== theme.id && options.allowModelPortfolioEdit !== true) {
      const response = await suggestPortfolio({
        variables: {
          suitabilityScore: options.useExternalSuitabilityScore === true ? object.custodianSuitabilityScore : object.suitabilityScore,
          organizationId: activeOrganization.id,
          themeId: theme.id,
        },
      });
      updateTheme({
        variables: {
          input: {
            subAccountId: undefined,
            goalId: objectId,
            themeId: undefined,
            financialProductId: response.data.suggestFinancialProduct.financialProduct.id,
          },
        },
      }).then();
    } else {
      updateTheme().then();
    }
  };

  useEffect(() => {
    if (subAccountData || goalData) {
      const d = objectType === PageObjectType.SUB_ACCOUNT ? subAccountData.fetchSubAccount.subAccount : goalData.fetchGoal.goal;
      setObject(d);
      setTheme(d?.theme || d?.financialProduct?.theme);
      setModelPortfolio(d.financialProduct);
      setSubAccountUpdateData({
        skipIPS: d?.skipIPS,
        allowClientDeposits: d?.allowClientDeposits,
        isPartial: d?.isPartial,
      });
    }
  }, [subAccountData, goalData, objectType]);

  useEffect(() => {
    if (subAccountLoading || goalLoading) {
      setIsMakingNetworkCall(true);
    } else {
      setIsMakingNetworkCall(false);
    }
  }, [subAccountLoading, goalLoading]);

  const subAccountState = useMemo(() => {
    switch (subAccountData?.fetchSubAccount?.subAccount.state) {
      case 'ACTIVE':
        return 'ACTIVE';
      case 'INACTIVE':
        return 'CLOSED';
      default:
        return 'PENDING';
    }
  }, [subAccountData]);

  const loading = showLoader || isMakingNetworkCall;

  return (
    <Card>
      <CardContent sx={{ px: 0, pb: '0 !important', backgroundColor: sys.color.surface }}>
        <Box sx={{ pb: 1.5, pt: 0.5, px: 2 }}>
          {loading ? <Skeleton width='45%' height='20px' variant='rectangular'></Skeleton> : <Typography variant='titleMedium'>{t('components:portfolioHighlights')}</Typography>}
        </Box>
        {options.displayPortfolioTheme && (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: sys.color.surfaceContainer,
              px: 2,
              height: 40,
            }}
          >
            {loading ? (
              <>
                <Skeleton width='30%' height='14px' variant='rectangular'></Skeleton>
                <Skeleton width='40%' height='14px' variant='rectangular'></Skeleton>
              </>
            ) : (
              <>
                <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>
                  {t('components:changeTheme.portfolioTheme')}:
                </Typography>
                <Box display='flex' alignItems='center'>
                  {options.allowPortfolioThemeEdit && (
                    <IconButton size='medium' sx={{ height: '32px' }} onClick={() => setOpen(true)}>
                      <Edit sx={{ fontSize: '16px' }} />
                    </IconButton>
                  )}
                  <Typography variant='bodyMedium'>
                    {translateBackend(object?.theme?.translatedName || object?.financialProduct?.theme?.translatedName) || t('components:changeTheme.unset')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
        {options.setModelPortfolio && (
          <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ px: 2, height: 40 }}>
            {loading ? (
              <>
                <Skeleton width='30%' height='14px' variant='rectangular'></Skeleton>
                <Skeleton width='40%' height='14px' variant='rectangular'></Skeleton>
              </>
            ) : (
              <>
                <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>
                  {t('components:changeTheme.modelPortfolio')}:
                </Typography>
                <Box display='flex' alignItems='center'>
                  {options.allowModelPortfolioEdit && (
                    <IconButton size='medium' sx={{ height: '32px' }} onClick={() => setOpen(true)}>
                      <Edit sx={{ fontSize: '16px' }} />
                    </IconButton>
                  )}
                  <Typography
                    variant='bodyMedium'
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: '180px',
                    }}
                  >
                    {translateBackend(object?.financialProduct?.translatedName) || t('components:changeTheme.unset')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
        {options.showFundFacts && object?.financialProduct?.url && (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: options.displayPortfolioTheme === options.setModelPortfolio ? sys.color.surfaceContainer : undefined,
              px: 2,
              height: 40,
            }}
          >
            {loading ? (
              <>
                <Skeleton width='30%' height='14px' variant='rectangular' />
                <Skeleton width='40%' height='14px' variant='rectangular' />
              </>
            ) : (
              <>
                <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>
                  {translateBackend(options.fundFactsLabel)}
                </Typography>
                <Box display='flex' alignItems='center'>
                  <IconButton onClick={(e) => window.open(object?.financialProduct?.url, '_blank', 'noopener,noreferrer')}>
                    <OpenInNewRoundedIcon />
                  </IconButton>
                  <Typography variant='bodyMedium'>{t('components:view')}</Typography>
                </Box>
              </>
            )}
          </Box>
        )}
        {options.displaySimplifiedSubAccountStatus && (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: sys.color.surface,
              px: 2,
              height: 40,
            }}
          >
            <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{t('components:status')}</Typography>
              <Box display='flex'>
              <StatusTag
                size='small'
                variant='bodyMedium'
                weight='bold'
                color={subAccountState === 'ACTIVE' ? 'positive' : subAccountState === 'CLOSED' ? 'negative' : 'neutral'}
                label={t(`client:subAccountState.${subAccountState}`)}
                icon={subAccountState === 'ACTIVE' ? CheckCircle : subAccountState === 'CLOSED' ? Error : ChangeCircle }
              />
            </Box>
          </Box>
        )}
        {options.displayCashOnHoldToWithdraw && !useCustodianData && (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: sys.color.surface,
              px: 2,
              height: 40,
            }}
          >
            <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>
              {t('components:cashOnHoldToWithdraw')}
            </Typography>
            <Typography variant='bodyMedium'>{formatMoneyValue(statistics?.cashOnHoldToTradeCents ?? 0)}</Typography>
          </Box>
        )}
        {options.displayCashOnHoldToTrade && !useCustodianData && (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: sys.color.surface,
              px: 2,
              height: 40,
            }}
          >
            <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>
              {t('components:cashOnHoldToTrade')}
            </Typography>
            <Typography variant='bodyMedium'>{formatMoneyValue(statistics?.cashOnHoldToWithdrawCents ?? 0)}</Typography>
          </Box>
        )}
        {options.displayNewContributions && !useCustodianData && (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: sys.color.surface,
              px: 2,
              height: 40,
            }}
          >
            <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>
              {t('components:newContribution')}
            </Typography>
            <Typography variant='bodyMedium'>{formatMoneyValue(statistics?.newContributionCents ?? 0)}</Typography>
          </Box>
        )}
        {options.displayCashAvailable && !useCustodianData && (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: sys.color.surface,
              px: 2,
              height: 40,
            }}
          >
            <Typography variant='bodyMedium' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>
              {t('components:moneyAvailable')}
            </Typography>
            <Typography variant='bodyMedium'>{formatMoneyValue(statistics?.moneyAvailableCents ?? 0)}</Typography>
          </Box>
        )}
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              {t('components:changeTheme.portfolioTheme')}
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {options.allowPortfolioThemeEdit && (
              <ThemeSelect
                label={t('components:changeTheme.portfolioTheme')}
                disabled={subAccountWithGoalPortfolio}
                onThemeSelect={(th: any) => setTheme(th)}
                selectedTheme={theme}
                suitabilityScore={options.useExternalSuitabilityScore === true ? object?.custodianSuitabilityScore : object?.suitabilityScore ?? undefined}
              />
            )}
            {options.allowPortfolioThemeEdit && (
              <Typography variant='bodyLarge' sx={{ mt: 2 }}>
                {translateBackend(theme?.translatedDescription || {})}
              </Typography>
            )}
            {options.allowModelPortfolioEdit && (
              <SelectField
                label={t('components:changeTheme.modelPortfolio')}
                disabled={!theme || subAccountWithGoalPortfolio}
                value={modelPortfolio?.id}
                onChange={(e: any) => setModelPortfolio(portfolios?.find((x: any) => x.id === e.target.value))}
                sx={{ mt: 2 }}
                fullWidth
              >
                {portfolios && portfolios?.length > 0 && portfolios?.map((p: any) => (
                  <MenuItem key={p.id} value={p.id}>
                    {translateBackend(p.translatedName)}{object?.suggestedFinancialProduct?.id === p.id && ` (${t('components:changeTheme.suggested')})`}
                  </MenuItem>
                ))}
              </SelectField>
            )}
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {options.enableSkipIPSEdit && (
                <Grid item xs={12}>
                  <Switch
                    label={t('workflowCompletions:subAccounts.requireIPS')}
                    checked={!subAccountUpdateData.skipIPS}
                    onChange={(onoff: boolean) => setSubAccountUpdateData({ ...subAccountUpdateData, skipIPS: !onoff })}
                    labelPosition='left'
                    fullWidth
                  />
                </Grid>
              )}
              {options.enableIsPartialEdit && (
                <Grid item xs={12}>
                  <Switch
                    label={t('workflowCompletions:subAccounts.allowFractionalShares')}
                    checked={subAccountUpdateData.isPartial}
                    onChange={(onoff: boolean) => setSubAccountUpdateData({ ...subAccountUpdateData, isPartial: onoff })}
                    labelPosition='left'
                    fullWidth
                  />
                </Grid>
              )}
              {options.enableAllowClientDepositsEdit && (
                <Grid item xs={12}>
                  <Switch
                    label={t('workflowCompletions:subAccounts.allowClientDeposits')}
                    checked={subAccountUpdateData.allowClientDeposits}
                    onChange={(onoff: boolean) => setSubAccountUpdateData({ ...subAccountUpdateData, allowClientDeposits: onoff })}
                    labelPosition='left'
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            <Box display='flex' justifyContent='flex-end' sx={{ mt: 3 }}>
              <Button label={t('components:changeTheme.save')} onClick={() => submitTheme()} />
            </Box>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};
