import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryTabs } from '../../../3-pattern';
import NotificationGroups from './components/notificationGroups';
import NotificationDefinitions from './components/notificationDefinitions';

export const Notifications = () => {
  const [activeTab, setActiveTab] = useState('groups');
  const { t } = useTranslation(['settings']);

  return (
    <>
      <SecondaryTabs tabs={[
        { label: t('notifications.groups'), icon: '', key: 'groups' },
        { label: t('notifications.definitions'), icon: '', key: 'definitions' },
      ]}
      activeTab={activeTab} setActiveTab={(tab) => {
        setActiveTab(tab.toString());
      }} />
      {activeTab === 'groups' && <NotificationGroups />}
      {activeTab === 'definitions' && <NotificationDefinitions />}
    </>
  );
};
