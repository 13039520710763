import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { isNull } from 'lodash/fp';
import { Box, Typography } from '../../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../../../2-component';
import { CopyId, ExternalTransferInManualProcessInfo, StateStepper } from '../../../../3-pattern';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { formatMoneyValue, generateTransferSourceText } from '../../../../../util';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { useLocalization } from '../../../../../util/useLocalization';
import { TransferSources } from '../../../../../interfaces';

enum TransferStates {
  REVIEWING = 'REVIEWING',
  INITIATED = 'INITIATED',
  READY = 'READY',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  RECONCILED = 'RECONCILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  ACTIVE = 'ACTIVE',
}

const CORE_STATES = [
  { name: TransferStates.INITIATED },
  { name: TransferStates.READY },
  { name: TransferStates.REQUESTED },
  { name: TransferStates.PROCESSING },
  { name: TransferStates.RECONCILED },
];

const activeStepFinder = (state: string) => {
  switch (state) {
    case TransferStates.INITIATED:
      return 0;
    case TransferStates.READY:
      return 1;
    case TransferStates.REQUESTED:
      return 2;
    case TransferStates.PROCESSING:
      return 3;
    case TransferStates.RECONCILED:
      return 4;
    default:
      return null;
  }
};

export const TransferModal = ({
  transfer, handleClose, open, options, transition,
}: {
  transfer: any, handleClose: any, open: any, options: any, transition: (transferId: string, isScheduled: boolean, variables: any, transfer?: boolean) => void, onTransition?: () => void,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const [activeStep, setActiveStep] = useState(0);
  const { sys } = useThemeTokens();
  const { permissions } = usePermissions();
  const { localizedDate } = useLocalization();

  useEffect(() => {
    if (transfer && transfer.stateMachineChanges) {
      let step = activeStepFinder(transfer.state);
      if (isNull(step)) {
        step = activeStepFinder(transfer.stateMachineChanges.slice(-1)[0].from);
      }
      setActiveStep(step || 0);
    }
  }, [transfer]);

  const transitions = [
    {
      name: 'review',
      from: [TransferStates.INITIATED, TransferStates.READY, TransferStates.REQUESTED, TransferStates.PROCESSING],
      error: false,
      show: options.canTransition,
      tonal: true,
    },
    {
      name: 'initiate',
      from: [TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'ready',
      from: [TransferStates.INITIATED, TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'request',
      from: [TransferStates.READY, TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'process',
      from: [TransferStates.REQUESTED, TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'reconcile',
      from: [TransferStates.REQUESTED, TransferStates.PROCESSING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'fail',
      from: [TransferStates.PROCESSING, TransferStates.REQUESTED],
      error: true,
      show: options.canTransition && options.canCancelPending,
    },
    {
      name: 'cancelReconciled',
      from: [TransferStates.RECONCILED],
      error: true,
      show: options.canTransition && options.canCancelPending,
    },
    {
      name: 'cancel',
      from: [TransferStates.INITIATED, TransferStates.READY, TransferStates.REVIEWING, TransferStates.PROCESSING],
      error: true,
      show: options.canCancelPending,
    },
    {
      name: 'cancelScheduled',
      from: [TransferStates.ACTIVE],
      error: true,
      show: options.canCancelScheduled,
    },
    {
      name: 'flipCancelToReconcile',
      from: [TransferStates.CANCELED],
      error: false,
      show: permissions.includes('transition:reconcile_canceled_transfer'),
    },
  ];

  const onTransitionClick = (tr: typeof transitions[number]) => {
    transition(transfer.id, transfer.state === TransferStates.ACTIVE, { transition: tr.name }, transfer);
    handleClose();
  };

  if (!transfer?.state) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {t('components:transfers.transferDetails')}
          <Box display='flex' justifyContent='end' alignItems='center'>
            <CopyId id={transfer.id} />
            <Box ml={1} />
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {transfer.source === TransferSources.EXTERNAL_TRANSFER_IN && <ExternalTransferInManualProcessInfo transfer={transfer} />}
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' alignItems='center'>
              <Typography variant='displayMedium'>{formatMoneyValue(transfer.amountCents)}</Typography>
            </Box>
            <Typography variant='bodySmall' colorVariant='variant'>{t(`pendingTransfers.types.${transfer.type}`)}</Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='flex-end' minWidth='250px'>
            {(transfer.source || !!(transfer.toSubAccount || transfer.bankAccount)) && (
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Typography variant='bodySmall'>{t('components:transfers.source')}</Typography>
                <Typography variant='bodySmall' weight='bold'>
                  {transfer.source
                    ? transfer.source === 'USER' && transfer.bankAccount.name
                      ? `${transfer.bankAccount.name} (${transfer.bankAccount.bankAccountNumber})`
                      : t(`components:transferSources.${transfer.source}`)
                    : generateTransferSourceText(t, transfer)}
                  </Typography>
              </Box>
            )}
            <Box display='flex' justifyContent='space-between' width='100%'>
              <Typography variant='bodySmall'>{t('components:transfers.date')}</Typography>
              <Typography variant='bodySmall' weight='bold'>{localizedDate(transfer.scheduledDate ? transfer.scheduledDate : transfer.date)}</Typography>
            </Box>
            {transfer.frequency && (
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Typography variant='bodySmall'>{t('components:transfers.frequency')}</Typography>
                <Typography variant='bodySmall' weight='bold'>{t(`components:transfersTable.frequencyOption.${transfer.frequency}`)}</Typography>
              </Box>
            )}
          </Box>
        </Box>
        {transfer.stateMachineChanges && (
          <Box borderTop={`1px solid ${sys.color.outlineVariant}`} pt={2} mt={2}>
            <Typography variant='bodyMedium' weight='bold' sx={{ mb: 2 }}>{t('components:transfers.transferProgress')}</Typography>
            <StateStepper
              steps={CORE_STATES}
              failed={[TransferStates.CANCELED, TransferStates.FAILED, TransferStates.REVIEWING].includes(transfer.state)}
              activeStep={activeStep}
              currentState={transfer.state}
              stateChanges={[{ to: TransferStates.INITIATED, createdAt: transfer.createdAt }, ...transfer.stateMachineChanges]}
            />
          </Box>
        )}
      </DialogContent>
      {permissions.includes('transition:transfer') && transitions.filter((tr) => tr.from.includes(transfer.state) && tr.show).length > 0 && (
        <DialogFooter>
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Box display='flex'>
              {transitions.filter((tr) => tr.from.includes(transfer.state) && tr.error && tr.show).map((tr) => (
                <Button key={tr.name} variant='tonal' color='destructive' label={t(`transferTransitions.${tr.name}`)} sx={{ mr: 1 }} onClick={() => onTransitionClick(tr)} />
              ))}
            </Box>
            <Box display='flex'>
              {transitions.filter((tr) => tr.from.includes(transfer.state) && !tr.error && tr.show).map((tr) => (
                <Button key={tr.name} variant={tr.tonal ? 'tonal' : 'filled'} label={t(`transferTransitions.${tr.name}`)} sx={{ ml: 1 }} onClick={() => onTransitionClick(tr)} />
              ))}
            </Box>
          </Box>
        </DialogFooter>
      )}
    </Dialog>
  );
};
