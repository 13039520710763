import {
  Chip, SxProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash/fp';
import {
  Box, Autocomplete,
} from '../..';
import { countryList } from '../../resources';

export const CitizenshipField = ({
  onChange, label, readonly = false, citizenships, singleSelect = false, sx, error, infoTooltip, testId,
}: {
  onChange: ((citizenships: string[]) => void),
  label?: string,
  rows?: number,
  readonly?: boolean,
  singleSelect?: boolean,
  citizenships: string[],
  sx?: SxProps,
  error?: boolean,
  infoTooltip?: string,
  testId?: string,
}) => {
  const { t } = useTranslation('components');

  return (
    <Box sx={sx}>
      <Autocomplete
        multiple={!singleSelect}
        data-testid="citizenshipField"
        testId={testId}
        freeSolo
        value={citizenships?.map((citizen) => countryList.find((country) => country.code === citizen)?.name ?? '') }
        options={countryList.map((country) => ({ label: country.name }))}
        fullWidth
        disabled={readonly}
        label={label ?? t('components:citizenshipInput.label')}
        infoTooltip={infoTooltip}
        error={error}
        isOptionEqualToValue={(option: any, value: any) => option.label === value}
        onChange={(e: any, value: any) => {
          if (isArray(value)) {
            const newCiti = value.map((x: any) => {
              if (x.label) {
                return countryList.find((country) => country.name === x.label)?.code ?? '';
              }
              return countryList.find((country) => country.name === x)?.code ?? '';
            });
            onChange(newCiti);
          } else {
            onChange([countryList.find((country) => country.name === value?.label)?.code || '']);
          }
        }}
        renderTags={(tagValue: any, getTagProps: any) => tagValue.map((option: any, index: number) => (
          <Chip
            label={option}
            sx={{ marginTop: '-2px !important' }}
            {...getTagProps({ index })}
            size='small'
            onDelete={undefined}
          />
        ))
        }
      />
    </Box>
  );
};

export default CitizenshipField;
