import { useEffect } from 'react';
import { OrganizationUserAccessTypes } from '../../../../interfaces/organizationUser';
import { Integration, IntegrationProvider, IntegrationType } from '../../../../interfaces/integrations';

export interface ZendeskScriptKeys {
  clientRoleKey: string;
  advisorRoleKey: string;
}

interface UserInfoForZendeskScript {
  userName?: string;
  userEmail?: string;
}

interface ZendeskProps {
  widgetStyle: { color?: string };
  accessType: OrganizationUserAccessTypes;
  user: UserInfoForZendeskScript;
  scriptKeys?: ZendeskScriptKeys;
}

declare global {
  interface Window {
    zE: (...args: any[]) => void;
  }
}

export const getZendeskKeys = (integrations: Integration[]): ZendeskScriptKeys | undefined => integrations.find((integration) => integration.type === IntegrationType.CHAT_AGENT
&& integration.provider === IntegrationProvider.ZENDESK)?.configuration;

export const ZendeskWidget = ({
  accessType, widgetStyle, user, scriptKeys,
}: ZendeskProps) => {
  useEffect(() => {
    const zendeskKey = accessType === OrganizationUserAccessTypes.ENTITY ? scriptKeys?.clientRoleKey : scriptKeys?.advisorRoleKey;

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (!window.zE) return;

      window.zE('webWidget', 'identify', {
        name: user.userName,
        email: user.userEmail,
      });

      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          color: {
            theme: widgetStyle.color,
          },
          launcher: {
            mobile: {
              labelVisible: true,
            },
          },
        },
      });
    };

    return () => {
      document.head.removeChild(script);
      if (window.zE) {
        window.zE('webWidget', 'clear');
      }
    };
  }, [accessType, scriptKeys, user, widgetStyle]);

  return null;
};
