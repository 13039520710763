import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';
import { useGlobalToast } from 'providers/globalToastProvider';
import { Widget } from './components/widget';
import {
  Box, Button, Card, CardContent, MultiCurrencySelector, PageObjectType, Typography,
  WidgetInterface,
} from '../..';
import { TabNav } from '../../3-pattern/tabNav/tabNav';
import { NewWidget } from './components/newWidget';
import { NewTab } from './components/newTab';
import { ClientSelectField } from '../../2-component/clientSelectField/clientSelect';
import { ClientContext } from '../../../pages/client';
import { HouseholdContext } from '../../../pages/household';
import { FETCH_RELATED_OBJECTS } from './pageConfiguration.queries';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { usePageState } from '../../../util/usePageState';
import { ClientName } from '../../4-module/widgets/clientName/clientName';
import { EditPage } from './components/editPage';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import { DELETE_FILE_DOCUMENT } from '../../../pages/client/components/documents/documentViewer';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { deleteFile, doUpload } from '../../4-module/configurableOptionFields';
import { Relationship } from '../../../interfaces';
import UpcomingDowntimeSchedules from '../../4-module/upcomingDowntimeSchedules/upcomingDowntimeSchedules';
import { EditCode } from './components/editCode';

export const PageConfigurationVisual = ({
  page, setPage, update, id,
}: {
  page: any, setPage: (e: any) => void, update: (e: any) => void, id: string,
}) => {
  const [objectId, setObjectId] = usePageState('', 'objectId');
  const [userId, setUserId] = usePageState('', 'userId');
  const { activeOrganization, userContext } = useContext(UserContext);
  const [viewFor, setViewFor] = useState({ id: userId, label: '' });
  const [user, setUser] = useState<any>();
  const [activeTab, setActiveTab] = usePageState(0, 'tab');
  const [widgets, setWidgets] = useState<any>([]);
  const [activeWidget, setActiveWidget] = useState<WidgetInterface>({ type: '', options: {} });
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation('pageConfiguration');
  const { sys } = useThemeTokens();
  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT);
  const widgetsWithAttachment = ['ADVISOR_CONTACT_INFORMATION'];
  const { permissions } = usePermissions();

  const getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
    marginBottom: '20px',
    ...draggableStyle,
  });

  const getListStyle = (_isDraggingOver: boolean) => ({
    background: sys.color.background,
    maxWidth: `${page.options?.maxPageWidth || 1080}px`,
    borderRadius: '4px',
    marginTop: '16px',
  });

  const { data: objectData, loading: objectLoading } = useQuery(FETCH_RELATED_OBJECTS, {
    variables: { userId: viewFor?.id },
    skip: !viewFor?.id,
  });

  useEffect(() => {
    if (objectData) {
      setUser(objectData.fetchUser.user);
      const userInfo = pick(objectData.fetchUser.user, ['id', 'firstName', 'middleName', 'lastName', 'entityName']);
      setViewFor(userInfo as any);
      switch (page.type) {
        case 'INDIVIDUAL':
          setObjectId(objectData.fetchUser.user.id);
          break;
        case 'NON_INDIVIDUAL':
          setObjectId(objectData.fetchUser.user.id);
          break;
        case 'GOAL':
          setObjectId(objectData.fetchUser.user.goals[0]?.id);
          break;
        case 'ACCOUNT':
          setObjectId(objectData.fetchUser.user.accounts[0]?.id);
          break;
        case 'SUB_ACCOUNT':
          setObjectId(objectData.fetchUser.user.subAccounts[0]?.id);
          break;
        case 'HOUSEHOLD':
          setObjectId(objectData.fetchUser.user.households[0]?.id);
          break;
        default:
          setObjectId(objectData.fetchUser.user.id);
          break;
      }
    }
    // eslint-disable-next-line
  }, [objectData, page]);

  const onDragEnd = (result: any) => {
    const newColumns = [...widgets];
    if (!result.destination) {
      return;
    }
    const item = newColumns[result.source.index];
    newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, item);
    setWidgets(newColumns);
  };

  const selectOptions = (item: any) => {
    const refineObject: any = {};
    Object.keys(item).forEach((key: string) => {
      refineObject[key] = item[key];
    });
    return refineObject;
  };

  const uploadOrDeleteDocument = async (widget: any): Promise<any> => {
    const newWidget = { ...widget };
    if (widget?.options?.attachment?.file) {
      await doUpload({
        objectId: activeOrganization.id, userId: userContext.id, subStep: newWidget, fetchFileUploadUrl, createFileDocument,
      });
    }
    if (widget?.options?.attachment?.docToDelete) {
      await deleteFile({ subStep: newWidget, deleteFileDocument });
    }
    return newWidget;
  };

  const handleDocumentChange = async (tabs: any[]) => {
    const newTabs = await Promise.all(tabs.map(async (tab: any) => {
      const newWidgets = await Promise.all(tab.widgets.map(async (widget: any) => {
        if (widgetsWithAttachment.includes(widget.type)) {
          const newWidget = await uploadOrDeleteDocument(widget);
          return newWidget;
        }
        return widget;
      }));
      return { ...tab, widgets: newWidgets };
    }));
    return newTabs;
  };

  const updatePageWithCurrentTab = async () => {
    const uPage = { ...page };
    const newTabs = [...uPage.tabs];
    // if there is no tab, no new tab should be created, because
    // tab.label.en, tab.label.fr, tab.label.icon can not be empty or null.
    const hasNoTab = newTabs.length === 0;
    if (hasNoTab) {
      return ({ ...uPage, tabs: [] });
    }
    newTabs[activeTab] = {
      ...newTabs[activeTab],
      widgets: widgets.map((widget: any) => ({ type: widget.type, options: widget.options })),
    };
    const tabsWithUpload = await handleDocumentChange(newTabs);
    setPage({ ...uPage, tabs: tabsWithUpload });
    return ({ ...uPage, tabs: tabsWithUpload });
  };

  useEffect(() => {
    setWidgets(page.tabs[activeTab]?.widgets || []);
  }, [page, activeTab]);

  const onSave = async () => {
    const newPage = await updatePageWithCurrentTab();
    update({
      variables: {
        input: {
          pageConfigurationId: id,
          name: newPage.name,
          tabs: newPage.tabs.map((tab: any) => ({
            label: {
              en: tab?.label?.en ?? '',
              fr: tab?.label?.fr ?? '',
            },
            icon: tab.icon ?? '',
            widgets: tab.widgets.map((x: any) => ({
              type: x.type,
              options: selectOptions(x.options),
            })),
            url: tab.url,
          })),
          options: newPage.options,
        },
      },
      onCompleted: () => (showToast({ severity: 'success', message: t('saveSuccessMessage') })),
    });
  };

  const removeWidget = (i: number) => {
    const uWidgets = [...widgets];
    uWidgets.splice(i, 1);
    setWidgets(uWidgets);
  };

  const removeTab = () => {
    const uPage = { ...page };
    uPage.tabs = uPage.tabs.filter((x: any, i: number) => i !== activeTab);
    setActiveTab(0);
    setPage(uPage);
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='headingMedium'>{t('Page Settings')} - {page.type}</Typography>
            { permissions.includes('write:advanced_organization_settings') && (
              <EditCode page={page} setPage={setPage} />
            )}
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='space-between' mt={3}>
            <Box display='flex' justifyContent='start' alignItems='end'>
              <EditPage page={page} setPage={setPage} />
              <Button label={t('save')} sx={{ ml: 2, mr: 2 }} onClick={onSave} />
            </Box>
            <ClientSelectField
              label={t('viewFor')}
              user={viewFor as any}
              setUser={(item: any) => {
                setViewFor(item);
                setUserId(item?.id);
                if (item === undefined) setObjectId('');
              }}
              width='200px'
            />
          </Box>
        </CardContent>
      </Card>
      {objectId && user && !objectLoading ? (
        <Box display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' } }} justifyContent='start' alignItems='start'>
          <Box maxWidth={`${page.options?.maxPageWidth || 1080}px`} width='100%' ml={{ xs: '0px', sm: '16px' }}>
            <ClientName objectId={objectId} objectType={page.type} options={page.options} />
            {page.options?.showUpcomingMaintenanceSchedule && [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL, PageObjectType.HOUSEHOLD].includes(page.type) && (
              <UpcomingDowntimeSchedules/>
            )}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Box display='flex' justifyContent='start' alignItems='center'>
                <TabNav
                  tabs={page.tabs}
                  activeTab={activeTab}
                  isPageConfigurationView
                  setActiveTab={(tab: number) => {
                    setActiveTab(tab);
                  }}
                  page={page}
                  setPage={setPage}
                />
              </Box>
              <Box display='flex' justifyContent='start' alignItems='center' gap={1}>
                <NewTab page={page} setPage={setPage} />
                {objectData?.fetchUser?.user?.organization?.displayCurrency && <MultiCurrencySelector userId={objectData.fetchUser.user.id} />}
              </Box>
            </Box>
            <ClientContext.Provider value={{
              id: user.id,
              orgSettings: user.organization,
              totalMarketValueCents: user.statistics?.marketValueCents ?? 0,
            }}>
              <HouseholdContext.Provider value={{
                orgSettings: user.organization,
                totalMarketValueCents: user.households[0]?.statistics.marketValueCents ?? 0,
                members: user.households[0]?.relationships ? user.households[0].relationships.map((rel: Relationship) => ({
                  id: rel.user.id,
                })) : [],
                indexedMembers: user.households[0] ? Object.fromEntries(user.households[0].relationships.map((rel: Relationship, index: number) => [rel.user.id, index]))
                  : {},
              }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'test'}>
                  {(provided: DroppableProvided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {widgets.map((item: any, i: number) => (
                        <Draggable key={item.id} draggableId={`${item.type}-${JSON.stringify(item.options)}`} index={i}>
                          {(dragProvided: any, dragSnapshot: any) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                              style={getItemStyle(
                                dragSnapshot.isDragging,
                                dragProvided.draggableProps.style,
                              )}
                            >
                              <Widget type={item.type} i={i} onDelete={removeWidget} onEdit={() => {
                                setActiveIndex(i);
                                setActiveWidget(item);
                                setOpen(true);
                              }} objectId={objectId} objectType={page.type} options={item.options} userId={objectData.fetchUser.user.id} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              </HouseholdContext.Provider>
            </ClientContext.Provider>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline' sx={{ mt: 3, width: '100%' }}>
              <Button label={t('deleteTab')} sx={{ ml: 2, mr: 2 }} onClick={removeTab} variant='tonal' color='destructive' />
              <Box display='flex' flexDirection='row' justifyContent='end' sx={{ mt: 3, width: '100%' }}>
                <Button onClick={() => {
                  setActiveWidget({ type: '', options: {} });
                  setOpen(true);
                }} label={t('addWidget')} variant='tonal' />
                <NewWidget
                  setWidgets={setWidgets}
                  widgets={widgets}
                  objectType={page.type}
                  open={open}
                  setOpen={setOpen}
                  activeWidget={activeWidget}
                  index={activeIndex ?? undefined}
                  onSave={() => {
                    setActiveIndex(null);
                    setActiveWidget({ type: '', options: {} });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box textAlign='center' mt={5}>
          <Typography variant='headingMedium'>{t('selectViewFor')}</Typography>
        </Box>
      )}

    </Box>
  );
};
