import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AutoRenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { Typography, Box } from '../../1-primative';
import { Card, CardContent } from '../../2-component';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { SubAccountStates } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { PageObjectType } from '../../5-page';
import { translateBackend } from '../../../assets/i18n/config';
import { MemberTag } from '../../../pages/household/components/memberTag';
import { showAccountNumber } from '../workflowCompletion/subSteps/utils';

const getSimplifiedState = (state: SubAccountStates) => {
  switch (state) {
    case SubAccountStates.ACTIVE:
      return 'ACTIVE';
    case SubAccountStates.INACTIVE:
      return 'CLOSED';
    default:
      return 'PENDING';
  }
};

type Tag = {
  text: string;
  sequenceNumber: number | 'HOUSEHOLD';
};

const SubAccountItem = ({
  subAccount, objectType, showSimplifiedState, tags, options,
}: { subAccount: any; tags?: Tag[]; objectType: PageObjectType; showSimplifiedState?: boolean, options?: any; }) => {
  const isHoushold = objectType === PageObjectType.HOUSEHOLD;
  const SUB_ACCOUNT_ITEM_CARD_HEIGHT = isHoushold ? '112px' : '72px';

  const { t } = useTranslation(['client', 'accountTypes', 'subAccountsDetail']);
  const { sys } = useThemeTokens();
  const navigate = useNavigate();

  const subAccountState = showSimplifiedState ? getSimplifiedState(subAccount.state) : subAccount.state;

  const name = () => {
    if (objectType === PageObjectType.ACCOUNT && subAccount.goal.name) {
      return subAccount.goal.name;
    }
    return t(`accountTypes:${subAccount.account.type}`);
  };

  return (
    <Card
      hover
      onClick={() => navigate(`subAccount/${subAccount.id}`)}
      sx={{
        cursor: 'pointer',
        height: SUB_ACCOUNT_ITEM_CARD_HEIGHT,
        boxSizing: 'border-box',
        padding: '16px',
        backgroundColor: subAccount.state === SubAccountStates.INACTIVE ? sys.color.disabled : sys.color.surface,
      }}
    >
      <CardContent data-testid={`subAccount-${name()}`} sx={{
        padding: '0px !important', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%',
      }}>
        <Box display='flex' justifyContent='space-between' height='100%'>
          <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Typography variant='titleMedium'>{name()}</Typography>
                <Typography variant='bodySmall' display='inline-flex' ml={0.5} colorVariant='variant'>
                  {isHoushold && subAccount.account.custodianAccountNumber ? (
                    showAccountNumber(
                      subAccount.account.custodianAccountNumber,
                      options?.displayMaskedAccountNumber,
                      options?.displayAccountNumberWithDashes ? options?.displayAccountNumberWithDashesFormat : undefined,
                    )
                  ) : subAccount.state !== SubAccountStates.ACTIVE && (
                    <>
                      <AutoRenewRoundedIcon sx={{ fontSize: '14px', verticalAlign: 'middle' }} />
                      {t(`client:subAccountState.${subAccountState}`)}
                    </>
                  )}
                </Typography>
              </Box>
              {isHoushold && options.displayHouseholdAccountHolder && (
                <Box display="flex" flexDirection="row" alignItems="center" gap={0.5} mt={0.5}>
                  {tags ? tags.map((tag, index) => (<MemberTag key={index} sequenceNumber={tag.sequenceNumber} name={tag.text} />)) : <Box height='16.5px' width={1} />}
                </Box>
              )}
            </Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='bodySmall' sx={{ color: subAccount.state === SubAccountStates.INACTIVE ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
                {translateBackend(subAccount?.theme?.translatedName)}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='end'>
            <Typography variant='titleMedium'>{formatMoneyValue(subAccount?.statistics?.marketValueCents ?? 0)}</Typography>
            <Typography
              variant='bodySmall'
              color={subAccount.state === SubAccountStates.INACTIVE ? sys.color.onDisabled : (subAccount?.statistics?.simpleReturnAmount || 0) >= 0 ? sys.color.positive : sys.color.negative}
            >
              {`${formatMoneyValue(subAccount?.statistics?.simpleReturnAmount ?? 0)}
              (${formatPercentValue(subAccount?.statistics?.simpleReturnPercent ?? 0)})`}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubAccountItem;
