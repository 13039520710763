import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '../../../../1-primative';
import {
  Dialog, DialogTitle, DialogContent, MenuItem, Button, TextField, DialogFooter, Form,
} from '../../../../2-component';
import { ActionContext } from '../../../../5-page';
import { ConfirmationModal } from '../../../../3-pattern';

const FETCH_HOUSEHOLD = gql`
  query fetchHousehold($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup { id name relationships { type } }
    }
  }
`;

const UPDATE_HOUSEHOLD = gql`
  mutation updateClientGroup($input: UpdateClientGroupInput!) {
    updateClientGroup(input: $input) {
      clientGroup { id }
    }
  }
`;

const TRANSITION_HOUSEHOLD = gql`
  mutation($input:TransitionClientGroupInput!) {
    transitionClientGroup(input:$input) {
      clientGroup {
        id
      }
    }
  }
`;

export const EditHousehold = ({ objectId, onClose, options = {} }: { objectId: string, onClose: () => void, options?: any }) => {
  const { t } = useTranslation('client');
  const { refreshWidgets } = useContext(ActionContext);
  const navigate = useNavigate();

  const [updated, setUpdated] = useState(false);
  const [householdName, setHouseholdName] = useState('');
  const [numOfMembers, setNumOfMembers] = useState<number>(0);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalProps, setWarningModalProps] = useState({
    open: false,
    onConfirm: () => {},
    onCancel: () => {},
    title: '',
    bodyText: '',
    showCancelButton: true,
  });

  const { loading, refetch } = useQuery(FETCH_HOUSEHOLD, {
    variables: { clientGroupId: objectId },
    onCompleted: (e) => {
      setHouseholdName(e.fetchClientGroup?.clientGroup.name);
      setNumOfMembers(e.fetchClientGroup?.clientGroup.relationships.length);
    },
  });

  const [updateHousehold] = useMutation(UPDATE_HOUSEHOLD, { variables: { input: { clientGroupId: objectId, name: householdName } } });
  const [transitionHoushold] = useMutation(TRANSITION_HOUSEHOLD, {
    variables: { input: { clientGroupId: objectId, transition: 'archive' } },
    onCompleted: () => navigate('/households'),
  });

  const submit = async () => {
    setUpdated(false);
    await updateHousehold();

    if (refreshWidgets) refreshWidgets();
    refetch();
  };

  const onEditDialogClose = () => setIsEditDialogOpen(false);
  const onEditDialogOpen = () => {
    onClose();
    setIsEditDialogOpen(true);
  };

  const transition = () => {
    setShowWarningModal(true);
    setWarningModalProps({
      open: true,
      onConfirm: () => {
        setShowWarningModal(false);
        transitionHoushold();
      },
      onCancel: () => setShowWarningModal(false),
      title: t('editHousehold.archiveHouseholdConfirmation.title'),
      bodyText: t('editHousehold.archiveHouseholdConfirmation.bodyText', { numOfMembers }),
      showCancelButton: true,
    });
  };

  return (
    <>
      {options.canEditHouseholdName && <MenuItem onClick={onEditDialogOpen}>{t('editHousehold.edit')}</MenuItem>}
      {options.enableClosingHousehold && <MenuItem onClick={transition}>{t('editHousehold.archive')}</MenuItem>}
      {showWarningModal && <ConfirmationModal {...warningModalProps} />}

      <Dialog open={isEditDialogOpen} onClose={onEditDialogClose} fullWidth maxWidth='xs'>
        <DialogTitle onClose={onEditDialogClose}>{t('editHousehold.editHousehold')}</DialogTitle>
        <Form onSubmit={submit}>
          <DialogContent>
            <Grid item spacing={2}>
              <TextField fullWidth data-testid='edit-household-name' label={t('editHousehold.name')} disabled={loading} value={householdName} error={!householdName} onChange={(e: any) => {
                setUpdated(true);
                setHouseholdName(e.target.value);
              }} />
            </Grid>
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='end'>
              <Button data-testid="confirm-button" label={t('client:update')} disabled={loading || !updated} type='submit' />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};
