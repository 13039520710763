import { isNull } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import {
  SelectField, MenuItem,
} from '../../2-component';

export const FETCH_ASSET_CLASSES = gql`
  query fetchAssetClasses($input: FetchAssetClassesInput) {
    fetchAssetClasses(input: $input) {
      assetClasses {
        id
        translatedName {
          en
        }
      }
    }
  }
`;

export const AssetClassSelect = ({
  setAssetClass, value, label, size, disabledWith = null, filteredAllocations = [], omitAny = false, leadingIcon,
}: {
  setAssetClass: (event: any) => void, value: string, label: string, size?: 'small' | 'medium' | undefined,
  disabledWith?: string | null, filteredAllocations?: any[], omitAny?: boolean, leadingIcon?: 'dollar' | 'percent' | 'search',
}) => {
  const { t } = useTranslation(['configureModels']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { loading, data } = useQuery(FETCH_ASSET_CLASSES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
      },
    },
  });

  const list = data?.fetchAssetClasses?.assetClasses || [];
  const filteredList = filteredAllocations.length === 0 ? list : list.filter((i: any) => i.id === value || !filteredAllocations.includes(i.id));

  return (
    <>
      { permissions.includes('read:asset_classes') ? (
        <SelectField
          leadingIcon={leadingIcon}
          fullWidth
          value={data ? value : ''}
          label={label}
          sx={{ width: '100%' }}
          onChange={setAssetClass}
          size={size}
          disabled={!isNull(disabledWith)}
        >
          {!omitAny && (<MenuItem value='ANY'>{t('components:any')}</MenuItem>)}
          {
            loading ? <MenuItem>...</MenuItem> : (
              disabledWith ? (
                <MenuItem key={value} value={ value }><b>{disabledWith}</b></MenuItem>
              ) : filteredList?.map((x: any) => (
                <MenuItem key={x.id} value={ x.id }>{ x.translatedName.en }</MenuItem>
              ))
            )
          }
        </SelectField>
      ) : (<></>)}
    </>
  );
};

export default AssetClassSelect;
