/* eslint-disable no-param-reassign */
import { gql, useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import dayjs from 'dayjs';
import {
  cloneDeep, kebabCase, sum,
} from 'lodash/fp';
import {
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Account, Affiliation, EmploymentStatus,
  User,
} from '../../../../../../interfaces';
import { ForeignTaxInformation } from '../../../../../../interfaces/foreignTaxInformation';
import {
  Box, Grid, Typography,
} from '../../../../../1-primative';
import {
  AddressField, Alert, Button, Checkbox,
  ClientSelectField,
  DateField, Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, MenuItem, Radio, RadioGroup,
  SegmentValue,
  SegmentedControl,
  SelectField,
  TextField,
} from '../../../../../2-component';
import { CitizenshipField, ForeignTaxField, SelectionTile } from '../../../../../3-pattern';
import { ALL_RELATIONS, type FieldType, RelationType } from '../accountConfig';
import { AffiliateType } from './affiliate';
import { SEARCH_CLIENTS_FOR_AFFILIATES } from './affiliateOperations.queries';
import { TaxIdInputs } from './taxIdExists';

const MAXIMUM_ALLOCATION_PERCENTAGE = 100;

const EMPLOYED_EMPLOYMENT_STATUSES = [EmploymentStatus.RETIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.SELF_EMPLOYED];

const CREATE_AFFILIATE = gql`
  mutation createAffiliate($input: CreateAffiliateInput!) {
    createAffiliate(input: $input) {
      user {
        id
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_AFFILIATIONS = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!) {
    updateAffiliations(input: $input) {
      account {
        id
        affiliations {
          id
          allocation
          relation
          type
        }
      }
      incompleteAffiliations {
        user {
          id
          firstName
          lastName
        }
        type
        relation
        incompleteFields
      }
    }
  }
`;
export interface InitiatedAffiliate {
  id: string,
  firstName: string,
  lastName: string,
  relation: RelationType,
}

export const AffiliateModal = ({
  open,
  setOpen,
  affiliate,
  action = 'create',
  fields = [],
  optionalFields = [],
  type,
  title,
  accountId,
  affiliates,
  refetch,
  allAffiliates,
  account,
  useAccountHoldersAddress,
  canUseExistingAffiliate = false,
  options,
  onJointAffiliateCreation,
  isCustomJointAffiliateFlowUsed,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  affiliate?: Affiliation;
  action?: string;
  fields?: FieldType[];
  optionalFields?: FieldType[];
  type: AffiliateType;
  title?: string;
  accountId: string;
  affiliates: Affiliation[];
  refetch?: () => void;
  allAffiliates: Affiliation[];
  account: Partial<Account>;
  useAccountHoldersAddress?: boolean;
  canUseExistingAffiliate?: boolean;
  options?: any;
  onJointAffiliateCreation?: (initiatedAffiliate: InitiatedAffiliate) => void;
  isCustomJointAffiliateFlowUsed?: boolean;
}) => {
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const [affiliateData, setAffiliateData] = useState<any>(affiliate);
  const [focused, setFocused] = useState<string[]>([]);
  const [checkUseExistingAffiliate, setCheckUseExistingAffiliate] = useState(false);
  const [checkUseAccountOwner, setCheckUseAccountOwner] = useState(false);
  const [existingUserSelected, setExistingUserSelected] = useState(false);
  const [grantAction, setGrantAction] = useState<SegmentValue>('primary');
  const [createAffiliate, { loading: createAffiliateLoading }] = useMutation(CREATE_AFFILIATE);
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  const [updateAffiliations, { loading }] = useMutation(UPDATE_AFFILIATIONS);
  let requiredFields = fields.map((field) => field.type);
  let allFieldTypes = requiredFields.concat(...optionalFields.map((field) => field.type));
  if (isCustomJointAffiliateFlowUsed) {
    const jointInitialFields = ['fullName', 'relation'];
    allFieldTypes = allFieldTypes.filter((availableField?: string) => availableField && jointInitialFields.includes(availableField));
    requiredFields = requiredFields.filter((availableField?: string) => availableField && jointInitialFields.includes(availableField));
    useAccountHoldersAddress = false;
  }
  const relations = fields.find((field) => field?.type === 'relation')?.options;
  const isDisabled = createAffiliateLoading || updateLoading || loading;
  const isUsingExistingAffiliate = (action === 'edit' && allAffiliates?.some((a: Affiliation) => (a.user?.id === affiliate?.user.id && a.id !== affiliate?.id)))
  || (action === 'create' && allAffiliates?.some((a: Affiliation) => a.user?.id === affiliateData.user.id));
  const isUsingAccountOwner = affiliateData.user.id === account?.user?.id;
  const selectExistingUsersEnabled = action === 'create' && (options?.selectExistingUsers ?? false);

  useEffect(() => {
    if (!open) return;

    if (affiliate?.user && Object.keys(affiliate?.user).length === 0 && allFieldTypes.includes('allocated')) {
      const allocation = requiredFields.includes('allocated') ? calculateAllocation() : 0;
      setAffiliateData({ ...affiliate, allocation });
    } else {
      setAffiliateData(affiliate);
    }
    setFocused([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate, open]);

  const calculateAllocation = () => Math.floor(MAXIMUM_ALLOCATION_PERCENTAGE / ((affiliates.filter((x: any) => x.type === type).length || 0) + 1));
  const calculateAllocatedValue = (diff: number, aff: Affiliation[]) => Math.floor((MAXIMUM_ALLOCATION_PERCENTAGE - diff) / (aff.filter((x: any) => x.type === type).length || 0));

  const updateAccountAffiliations = (userId: string) => {
    let allocation = 0;
    if (requiredFields.includes('allocation')) allocation = calculateAllocation();
    if (allFieldTypes.includes('allocated')) allocation = affiliateData.allocation || 0;

    const allocationEnabled = requiredFields.includes('allocation') || allFieldTypes.includes('allocated');

    const affiliations = [
      ...(allAffiliates || []).map((item: any) => ({
        // eslint-disable-next-line max-len
        allocation: item.type === type ? (allFieldTypes.includes('allocated') ? calculateAllocatedValue(affiliateData.allocation || 0, affiliates) : (!allocationEnabled ? 0 : calculateAllocation())) : item.allocation,
        relation: item.relation,
        type: item.type,
        userId: item.user.id,
      })),
      {
        userId,
        relation: affiliateData.relation || RelationType.OTHER,
        type,
        allocation,
      },
    ];

    updateAffiliations({
      variables: { input: { accountId, affiliations } },
      onCompleted: async (res: any) => {
        const incompleteAffiliations = res?.updateAffiliations?.incompleteAffiliations ?? [];
        if (incompleteAffiliations?.length > 0) {
          const currentAffiliateIncompleteFields = incompleteAffiliations[0]?.incompleteFields;
          setFocused(currentAffiliateIncompleteFields);
        } else {
          refetch && refetch();
          setOpen(false);
        }
      },
    });
  };

  const updateExistingAccountAffiliations = () => {
    const newAff = allAffiliates.filter((item) => item.id !== affiliateData.id);

    let allocationGreaterThanMaxPercentage = false;
    if (allFieldTypes.includes('allocated')) {
      const relatedAff = newAff.filter((item: any) => item.type === type);
      const totalAllocation = sum(relatedAff.map((item) => item.allocation)) + (affiliateData.allocation || 0);
      allocationGreaterThanMaxPercentage = totalAllocation - MAXIMUM_ALLOCATION_PERCENTAGE >= 0;
    }

    const affiliations = [
      ...newAff.map((item: Affiliation) => ({
        allocation:
          allFieldTypes.includes('allocated') && (item.type as unknown as AffiliateType) === type && allocationGreaterThanMaxPercentage
            ? calculateAllocatedValue(affiliateData.allocation || 0, newAff)
            : item.allocation,
        relation: item.relation,
        type: item.type,
        userId: item.user.id,
      })),
      {
        type,
        userId: affiliateData.user.id,
        relation: affiliateData.relation || RelationType.OTHER,
        allocation: affiliateData.allocation,
      },
    ];

    updateAffiliations({
      variables: { input: { accountId, affiliations } },
      onCompleted: (res: any) => {
        const incompleteAffiliations = res?.updateAffiliations?.incompleteAffiliations ?? [];
        if (incompleteAffiliations?.length > 0) {
          const currentAffiliateIncompleteFields = incompleteAffiliations[0]?.incompleteFields;
          setFocused(currentAffiliateIncompleteFields);
        } else {
          refetch && refetch();
          setOpen(false);
        }
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setCheckUseExistingAffiliate(false);
    setCheckUseAccountOwner(false);
    setExistingUserSelected(false);
    setGrantAction('primary');
  };

  const clearUserObject = (userDataObject: any) => {
    delete userDataObject?.id;
    delete userDataObject?.taxIdExists;
    delete userDataObject?.__typename;
    delete userDataObject?.physicalAddress?.__typename;
    delete userDataObject?.sinExists;
    delete userDataObject?.affiliateOnly;
    if (!userDataObject?.foreignTaxInformation) {
      delete userDataObject?.foreignTaxInformation;
    }
    if (userDataObject?.foreignTaxInformation) {
      // remove __typename from foreignTaxInformation clean-up
      userDataObject.foreignTaxInformation = userDataObject?.foreignTaxInformation.map((item: any) => {
        const taxItem = cloneDeep(item);
        delete taxItem?.__typename;
        return taxItem;
      });
    }
    if (!userDataObject?.employmentStatus) delete userDataObject?.employmentStatus;
    if (!userDataObject?.companyType) delete userDataObject?.companyType;
    if (!userDataObject?.jobTitle) delete userDataObject?.jobTitle;
    if (!userDataObject?.maritalStatus) delete userDataObject?.maritalStatus;
    if (!userDataObject?.citizenships || userDataObject?.citizenships.length === 0) delete userDataObject?.citizenships;
    // For USA addresses, province is not available and is set to "null" from the backend. which causes an error while updating the affiliate.
    if (userDataObject?.physicalAddress?.province === null) delete userDataObject?.physicalAddress?.province;
  };

  const submit = async () => {
    if (!validate()) return;
    const userData = {
      ...affiliateData.user,
      isOfficerOfPublicCompany: allFieldTypes.includes('isOfficerOfPublicCompany') ? affiliateData.user?.isOfficerOfPublicCompany ?? false : false,
      isOwnerOfPublicCompany: allFieldTypes.includes('isOwnerOfPublicCompany') ? affiliateData.user?.isOwnerOfPublicCompany ?? false : false,
      isMemberOfIiroc: allFieldTypes.includes('isMemberOfIiroc') ? affiliateData.user?.isMemberOfIiroc ?? false : false,
      politicallyExposedForeignPerson: allFieldTypes.includes('politicallyExposedForeignPerson') ? affiliateData.user?.politicallyExposedForeignPerson ?? false : false,
      politicallyExposedDomesticPerson: allFieldTypes.includes('politicallyExposedDomesticPerson') ? affiliateData.user?.politicallyExposedDomesticPerson ?? false : false,
      closeAssociateOfPEP: allFieldTypes.includes('closeAssociateOfPEP') ? affiliateData.user?.closeAssociateOfPEP ?? false : false,
      headOfInternationalOrganization: allFieldTypes.includes('headOfInternationalOrganization') ? affiliateData.user?.headOfInternationalOrganization ?? false : false,
      foreignTaxInformation: allFieldTypes.includes('foreignTaxInformation') ? (affiliateData.user?.foreignTaxInformation ?? []) : undefined,
    };
    if (((action === 'create' && !checkUseExistingAffiliate) && (action === 'create' && !checkUseAccountOwner))) {
      if (!userData?.dateOfBirth) delete userData?.dateOfBirth;
      if (!userData?.dateOfDeath) delete userData?.dateOfDeath;

      if (existingUserSelected) {
        clearUserObject(userData);
        const input = { userId: affiliateData.user.id, ...userData };
        updateUser({ variables: { input }, onCompleted: () => updateAccountAffiliations(affiliateData.user.id) });
      } else {
        createAffiliate({
          variables: { input: userData },
          onCompleted: (res) => {
            if (isCustomJointAffiliateFlowUsed && onJointAffiliateCreation) {
              onJointAffiliateCreation({
                id: res?.createAffiliate?.user.id,
                firstName: userData?.firstName ?? '',
                lastName: userData?.lastName ?? '',
                relation: affiliateData.relation,
              });
              handleClose();
            } else {
              updateAccountAffiliations(res?.createAffiliate?.user.id);
            }
          },
        });
      }
    } else {
      if (!userData?.dateOfBirth) delete userData?.dateOfBirth;
      if (!userData?.dateOfDeath) delete userData?.dateOfDeath;
      clearUserObject(userData);

      const input = { userId: affiliateData.user.id, ...userData };
      updateUser({ variables: { input }, onCompleted: updateExistingAccountAffiliations });
    }
  };

  const validate = () => {
    const invalidFields: string[] = [];

    if (requiredFields.includes('fullName') && !affiliateData.user.firstName) invalidFields.push('firstName');
    if (requiredFields.includes('fullName') && !affiliateData.user.lastName) invalidFields.push('lastName');
    if (requiredFields.includes('primaryEmail') && !affiliateData.user.primaryEmail) invalidFields.push('primaryEmail');
    if (requiredFields.includes('physicalAddress') && !affiliateData.user.physicalAddress?.streetName) invalidFields.push('physicalAddress');
    if (requiredFields.includes('dateOfBirth') && !affiliateData.user.dateOfBirth) invalidFields.push('dateOfBirth');
    if (requiredFields.includes('dateOfDeath') && !affiliateData.user.dateOfDeath) invalidFields.push('dateOfDeath');
    if (requiredFields.includes('gender') && !affiliateData.user.gender) invalidFields.push('gender');

    if (requiredFields.includes('relation') && !affiliateData.relation) invalidFields.push('relation');
    if (affiliateData.allocation < 0 || (requiredFields.includes('allocated') && !affiliateData.allocation)) invalidFields.push('allocated');

    const isTaxIdIncorrect = affiliateData.user.taxId && affiliateData.user.taxId.length !== 9;
    if (isTaxIdIncorrect || (requiredFields.includes('taxId') && !affiliateData.user.taxIdExists && !affiliateData.user.taxId)) invalidFields.push('taxId');

    if (allFieldTypes.includes('employmentStatus')) {
      if (requiredFields.includes('employmentStatus') && !affiliateData.user.employmentStatus) invalidFields.push('employmentStatus');

      if (EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && !affiliateData.user.companyType) invalidFields.push('companyType');
      if (EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && !affiliateData.user.jobTitle) invalidFields.push('jobTitle');
      if (affiliateData.user.employmentStatus === EmploymentStatus.STUDENT && !affiliateData.user.studentAreaOfStudy) invalidFields.push('studentAreaOfStudy');
    }

    if (requiredFields.includes('maritalStatus') && !affiliateData.user.maritalStatus) invalidFields.push('maritalStatus');
    if (requiredFields.includes('citizenships') && !affiliateData.user.citizenships) invalidFields.push('citizenships');

    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  const canada = ['CA', 'CANADA'];
  const isOutsideCanadaRegion = affiliateData?.user?.citizenships?.some((x: string) => !canada.includes(x.toUpperCase()));
  const displaySearchWarning = existingUserSelected && !isUsingExistingAffiliate && !isUsingAccountOwner;
  const showFields = existingUserSelected || grantAction === 'primary' || !options?.selectExistingUsers;
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{title ?? action === 'create' ? t(`button.${type}`) : t(`button.edit.${type}`)}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <Form onSubmit={submit}>
        <DialogContent>
          {selectExistingUsersEnabled && (
            <>
              <SegmentedControl
                sx={{ mb: 4 }}
                value={grantAction}
                segments={[
                  { label: t('createNewUser'), value: 'primary' },
                  { label: t('selectExistingUsers'), value: 'secondary' },
                ]}
                exclusive={true}
                fullWidth
                enforceActive
                onChange={(value: SegmentValue) => {
                  setGrantAction(value);
                  setExistingUserSelected(false);
                  setAffiliateData({ ...affiliateData, user: {} });
                }}
              />
              {grantAction === 'secondary' && (
                <Box display={'flex'} flexDirection={'column'} gap={2} mb={2}>
                  <ClientSelectField
                    fullWidth
                    autoFocus
                    user={undefined}
                    customSearchQuery={SEARCH_CLIENTS_FOR_AFFILIATES}
                    label={''}
                    setUser={(selectedUser: User | undefined) => {
                      if (selectedUser) {
                        setExistingUserSelected(true);
                        setAffiliateData({ ...affiliateData, user: selectedUser });
                      } else {
                        setExistingUserSelected(false);
                      }
                    }}
                  />
                </Box>
              )}
              {displaySearchWarning && grantAction === 'secondary' && (
                <Box display={'flex'} flexDirection={'column'} gap={2} mb={2}>
                  <Alert variant='outlined' icon={<WarningIcon />} severity='warning'>
                    <Typography>{t('userSearchWarning')}</Typography>
                  </Alert>
                </Box>
              )}
            </>
          )}
          {allFieldTypes.includes('fullName') && showFields && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  disabled={isUsingAccountOwner}
                  testId='affiliate-first-name'
                  label={t('client:details.firstName')}
                  value={affiliateData.user.firstName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, firstName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'firstName'])}
                  error={!affiliateData.user.firstName && focused.includes('firstName') && requiredFields.includes('fullName')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled={isUsingAccountOwner}
                  testId='affiliate-middle-name'
                  label={t('client:details.middleName')}
                  value={affiliateData.user.middleName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, middleName: e.target.value } })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled={isUsingAccountOwner}
                  testId='affiliate-last-name'
                  label={t('client:details.lastName')}
                  value={affiliateData.user.lastName || ''}
                  onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, lastName: e.target.value } })}
                  onBlur={() => setFocused([...focused, 'lastName'])}
                  error={!affiliateData.user.lastName && focused.includes('lastName') && requiredFields.includes('fullName')}
                />
              </Grid>
            </Grid>
          )}
          {allFieldTypes.includes('physicalAddress') && showFields && (
            <AddressField
              disabled={isUsingAccountOwner}
              manualAddressEntry
              sx={{ mt: 2 }}
              testId='affiliate-physical-address'
              address={affiliateData.user.physicalAddress || {}}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: e } })}
              label={t('client:details.physicalAddress')}
              onBlur={() => setFocused([...focused, 'physicalAddress'])}
              error={!affiliateData.user.physicalAddress?.streetName && focused.includes('physicalAddress') && requiredFields.includes('physicalAddress')}
            />
          )}
          {useAccountHoldersAddress && showFields && (
            <Box display='flex' flexDirection='row' sx={{ flexFlow: 'wrap' }} mt={1}>
              <Checkbox
                disabled={isUsingAccountOwner}
                chip
                testId='assign-account-holder-address'
                label={t('affiliationTypes:assignAccountHoldersAddress')}
                onChange={(checked: boolean) => {
                  if (checked) {
                    const accountHolderAddress = account.user?.physicalAddress;
                    delete accountHolderAddress?.__typename;
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: accountHolderAddress } });
                  } else {
                    setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, physicalAddress: '' } });
                  }
                }}
              />
            </Box>
          )}
          {allFieldTypes.includes('dateOfBirth') && showFields && (
            <DateField
              fullWidth
              sx={{ mt: 2 }}
              dataTestId='affiliate-date-of-birth'
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfBirth')}
              value={affiliateData.user.dateOfBirth || ''}
              onBlur={() => setFocused([...focused, 'dateOfBirth'])}
              error={!affiliateData.user.dateOfBirth && focused.includes('dateOfBirth') && requiredFields.includes('dateOfBirth')}
            />
          )}
          {allFieldTypes.includes('dateOfDeath') && showFields && (
            <DateField
              fullWidth
              sx={{ mt: 2 }}
              dataTestId='affiliate-date-of-death'
              onChange={(date: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, dateOfDeath: dayjs(date?.toString()).format('YYYY-MM-DD') } })}
              label={t('client:details.dateOfDeath')}
              value={affiliateData.user.dateOfDeath || ''}
              onBlur={() => setFocused([...focused, 'dateOfDeath'])}
              error={!affiliateData.user.dateOfDeath && focused.includes('dateOfDeath') && requiredFields.includes('dateOfDeath')}
            />
          )}
          {allFieldTypes.includes('taxId') && showFields && (
            <Box sx={{ mt: 2 }}>
              <TaxIdInputs
                disabled={isUsingAccountOwner}
                user={account?.user}
                affiliateUser={affiliateData.user}
                setAffiliateUser={(user) => setAffiliateData({ ...affiliateData, user })}
                onBlur={() => {
                  if (!(affiliateData.user.taxIdExists && !affiliateData.user.taxId)) {
                    setFocused([...focused, 'taxId']);
                  }
                }}
                error={((affiliateData.user.taxId && affiliateData.user.taxId.length !== 9) || (!affiliateData.user.taxId && requiredFields.includes('taxId'))) && focused.includes('taxId')}
              />
            </Box>
          )}
          {allFieldTypes.includes('primaryEmail') && showFields && (
            <TextField
              disabled={isUsingAccountOwner}
              fullWidth
              sx={{ mt: 2 }}
              testId='affiliate-primary-email'
              label={t('client:details.primaryEmail')}
              value={affiliateData.user.primaryEmail || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, primaryEmail: e.target.value } })}
              onBlur={() => setFocused([...focused, 'primaryEmail'])}
              error={!affiliateData.user.primaryEmail && focused.includes('primaryEmail') && requiredFields.includes('primaryEmail')}
            />
          )}
          {allFieldTypes.includes('gender') && showFields && (
            <Box mt={2}>
              <Typography variant='labelSmall' colorVariant='variant' sx={{ mb: 1 }}>
                {t('client:details.gender')}
              </Typography>
              <SelectionTile
                testId='affiliate-gender'
                direction='row'
                value={affiliateData.user.gender || ''}
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, gender: e.target.value } })}
                options={[
                  { value: 'Male', label: t('client:details.Male') },
                  { value: 'Female', label: t('client:details.Female') },
                ]}
                error={!affiliateData.user.gender && focused.includes('gender') && requiredFields.includes('gender')}
              />
            </Box>
          )}
          {allFieldTypes.includes('employmentStatus') && showFields && (
            <SelectField
              fullWidth
              sx={{ mt: 2 }}
              testId='affiliate-employment-status'
              label={t('client:details.employmentStatus')}
              value={affiliateData.user.employmentStatus || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, employmentStatus: e.target.value } })}
              onBlur={() => setFocused([...focused, 'employmentStatus'])}
              error={!affiliateData.user.employmentStatus && focused.includes('employmentStatus') && requiredFields.includes('employmentStatus')}
            >
              {['EMPLOYED', 'SELF_EMPLOYED', 'UNEMPLOYED', 'STUDENT', 'RETIRED'].map((value) => (
                <MenuItem key={value} data-testid={`affiliate-employment-status-${kebabCase(value)}`} value={value}>
                  {t(`client:edit.employmentStatusOptions.${value}`)}
                </MenuItem>
              ))}
            </SelectField>
          )}
          {allFieldTypes.includes('employmentStatus') && EMPLOYED_EMPLOYMENT_STATUSES.includes(affiliateData.user.employmentStatus) && showFields && (
            <>
              <TextField
                fullWidth
                sx={{ mt: 2 }}
                testId='affiliate-company-type'
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, companyType: e.target.value } })}
                label={t('client:details.companyType')}
                value={affiliateData.user.companyType}
                onBlur={() => setFocused([...focused, 'companyType'])}
                error={!affiliateData.user.companyType && focused.includes('companyType')}
              />
              <TextField
                fullWidth
                sx={{ mt: 2 }}
                testId='affiliate-job-title'
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, jobTitle: e.target.value } })}
                label={t('client:details.jobTitle')}
                value={affiliateData.user.jobTitle}
                onBlur={() => setFocused([...focused, 'jobTitle'])}
                error={!affiliateData.user.jobTitle && focused.includes('jobTitle')}
              />
            </>
          )}
          {allFieldTypes.includes('employmentStatus') && affiliateData.user.employmentStatus === EmploymentStatus.STUDENT && showFields && (
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              testId='affiliate-area-of-study'
              onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, studentAreaOfStudy: e.target.value } })}
              label={t('client:details.studentAreaOfStudy')}
              value={affiliateData.user.studentAreaOfStudy}
              onBlur={() => setFocused([...focused, 'studentAreaOfStudy'])}
              error={!affiliateData.user.studentAreaOfStudy && focused.includes('studentAreaOfStudy')}
            />
          )}
          {allFieldTypes.includes('allocated') && showFields && (
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              testId='affiliate-allocated'
              label={t('client:details.allocation')}
              type='number'
              trailingIcon='percent'
              value={affiliateData.allocation || 0}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, allocation: parseInt(e.target.value, 10) })}
              onBlur={() => setFocused([...focused, 'allocated'])}
              error={(affiliateData.allocation < 0 || (!affiliateData.allocation && requiredFields.includes('allocated'))) && focused.includes('allocated')}
            />
          )}
          {allFieldTypes.includes('maritalStatus') && showFields && (
            <Grid item mt={2} xs={12}>
              <SelectField
                testId='marital-status'
                onChange={(e: any) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, maritalStatus: e.target.value } })}
                disabled={loading}
                label={t('client:details.citizenshipAndTaxStatusInformationBox.maritalStatusTitle')}
                fullWidth
                value={affiliateData?.user?.maritalStatus ?? ''}
                error={!affiliateData.user?.maritalStatus && focused.includes('maritalStatus') && requiredFields.includes('maritalStatus')}
              >
                <MenuItem data-testid='marital-status-single' value='SINGLE'>
                  {t('client:edit.maritalStatusOptions.SINGLE')}
                </MenuItem>
                <MenuItem data-testid='marital-status-married' value='MARRIED'>
                  {t('client:edit.maritalStatusOptions.MARRIED')}
                </MenuItem>
                <MenuItem data-testid='marital-status-common-law' value='COMMON_LAW'>
                  {t('client:edit.maritalStatusOptions.COMMON_LAW')}
                </MenuItem>
                <MenuItem data-testid='marital-status-divorced' value='DIVORCED'>
                  {t('client:edit.maritalStatusOptions.DIVORCED')}
                </MenuItem>
                <MenuItem data-testid='marital-status-separated' value='SEPARATED'>
                  {t('client:edit.maritalStatusOptions.SEPARATED')}
                </MenuItem>
                <MenuItem data-testid='marital-status-widowed' value='WIDOWED'>
                  {t('client:edit.maritalStatusOptions.WIDOWED')}
                </MenuItem>
                <MenuItem data-testid='marital-status-civil-union' value='CIVIL_UNION'>
                  {t('client:edit.maritalStatusOptions.CIVIL_UNION')}
                </MenuItem>
              </SelectField>
            </Grid>
          )}
          {allFieldTypes.includes('isOfficerOfPublicCompany') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <RadioGroup
                testId={kebabCase('isOfficerOfPublicCompany')}
                value={affiliateData?.user?.isOfficerOfPublicCompany ? 'yes' : 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value === 'yes';
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, isOfficerOfPublicCompany: newValue } });
                }}
                disabled={loading}
                label={t('client:details.personAndEntitiesOfInterestBox.officerOfAPublicCompanyTitle')}
              >
                <Radio testId={`${kebabCase('isOfficerOfPublicCompany')}-option-no`} value={'no'} label={t('shared:no')} />
                <Radio testId={`${kebabCase('isOfficerOfPublicCompany')}-option-yes`} value={'yes'} label={t('shared:yes')} />
              </RadioGroup>
            </Grid>
          )}
          {allFieldTypes.includes('isOwnerOfPublicCompany') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <RadioGroup
                testId={kebabCase('isOwnerOfPublicCompany')}
                value={affiliateData?.user?.isOwnerOfPublicCompany ? 'yes' : 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value === 'yes';
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, isOwnerOfPublicCompany: newValue } });
                }}
                disabled={loading}
                label={t('client:details.personAndEntitiesOfInterestBox.ownerOfPublicCompanyTitle')}
              >
                <Radio testId={`${kebabCase('isOwnerOfPublicCompany')}-option-no`} value={'no'} label={t('shared:no')} />
                <Radio testId={`${kebabCase('isOwnerOfPublicCompany')}-option-yes`} value={'yes'} label={t('shared:yes')} />
              </RadioGroup>
            </Grid>
          )}
          {allFieldTypes.includes('isMemberOfIiroc') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <RadioGroup
                testId={kebabCase('isMemberOfIiroc')}
                value={affiliateData?.user?.isMemberOfIiroc ? 'yes' : 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value === 'yes';
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, isMemberOfIiroc: newValue } });
                }}
                disabled={loading}
                label={t('client:details.personAndEntitiesOfInterestBox.memberOfIIROCTitle')}
              >
                <Radio testId={`${kebabCase('isMemberOfIiroc')}-option-no`} value={'no'} label={t('shared:no')} />
                <Radio testId={`${kebabCase('isMemberOfIiroc')}-option-yes`} value={'yes'} label={t('shared:yes')} />
              </RadioGroup>
            </Grid>
          )}
          {allFieldTypes.includes('politicallyExposedDomesticPerson') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <RadioGroup
                testId={kebabCase('politicallyExposedDomesticPerson')}
                value={affiliateData?.user?.politicallyExposedDomesticPerson ? 'yes' : 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value === 'yes';
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, politicallyExposedDomesticPerson: newValue } });
                }}
                disabled={loading}
                label={t('client:details.personAndEntitiesOfInterestBox.politicallyExposedDomesticPersonTitle')}
              >
                <Radio testId={`${kebabCase('politicallyExposedDomesticPerson')}-option-no`} value={'no'} label={t('shared:no')} />
                <Radio testId={`${kebabCase('politicallyExposedDomesticPerson')}-option-yes`} value={'yes'} label={t('shared:yes')} />
              </RadioGroup>
            </Grid>
          )}
          {allFieldTypes.includes('politicallyExposedForeignPerson') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <RadioGroup
                testId={kebabCase('politicallyExposedForeignPerson')}
                value={affiliateData?.user?.politicallyExposedForeignPerson ? 'yes' : 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value === 'yes';
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, politicallyExposedForeignPerson: newValue } });
                }}
                disabled={loading}
                label={t('client:details.personAndEntitiesOfInterestBox.politicallyExposedForeignPersonTitle')}
              >
                <Radio testId={`${kebabCase('politicallyExposedForeignPerson')}-option-no`} value={'no'} label={t('shared:no')} />
                <Radio testId={`${kebabCase('politicallyExposedForeignPerson')}-option-yes`} value={'yes'} label={t('shared:yes')} />
              </RadioGroup>
            </Grid>
          )}
          {allFieldTypes.includes('closeAssociateOfPEP') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <RadioGroup
                testId={kebabCase('closeAssociateOfPEP')}
                value={affiliateData?.user?.closeAssociateOfPEP ? 'yes' : 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value === 'yes';
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, closeAssociateOfPEP: newValue } });
                }}
                disabled={loading}
                label={t('client:details.personAndEntitiesOfInterestBox.closeAssociateOfPEPTitle')}
              >
                <Radio testId={`${kebabCase('closeAssociateOfPEP')}-option-no`} value={'no'} label={t('shared:no')} />
                <Radio testId={`${kebabCase('closeAssociateOfPEP')}-option-yes`} value={'yes'} label={t('shared:yes')} />
              </RadioGroup>
            </Grid>
          )}
          {allFieldTypes.includes('headOfInternationalOrganization') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <RadioGroup
                testId={kebabCase('headOfInternationalOrganization')}
                value={affiliateData?.user?.headOfInternationalOrganization ? 'yes' : 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = event.target.value === 'yes';
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, headOfInternationalOrganization: newValue } });
                }}
                disabled={loading}
                label={t('client:details.personAndEntitiesOfInterestBox.headOfInternationalOrganizationTitle')}
              >
                <Radio testId={`${kebabCase('headOfInternationalOrganization')}-option-no`} value={'no'} label={t('shared:no')} />
                <Radio testId={`${kebabCase('headOfInternationalOrganization')}-option-yes`} value={'yes'} label={t('shared:yes')} />
              </RadioGroup>
            </Grid>
          )}
          {allFieldTypes.includes('citizenships') && showFields && (
            <Grid item mt={2} xs={12} md={12}>
              <CitizenshipField
                onChange={(e: any) => {
                  setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, citizenships: e } });
                }}
                readonly={loading}
                label={t('client:details.citizenshipAndTaxStatusInformationBox.citizenshipsTitle')}
                citizenships={affiliateData?.user?.citizenships || []}
                testId='affiliate-citizenships'
                error={!affiliateData.user?.citizenships && focused.includes('citizenships') && requiredFields.includes('citizenships')}
              />
            </Grid>
          )}
          {allFieldTypes.includes('foreignTaxInformation') && showFields && isOutsideCanadaRegion && (
            <Grid item mt={2} xs={12} md={12}>
              <ForeignTaxField
                setForeignTax={(newValue: ForeignTaxInformation[]) => setAffiliateData({ ...affiliateData, user: { ...affiliateData.user, foreignTaxInformation: newValue } })}
                readonly={loading}
                label={t('client:details.citizenshipAndTaxStatusInformationBox.foreignTaxInformationTitle')}
                foreignTax={affiliateData?.user.foreignTaxInformation ?? []}
                data-testid='affiliate-foreign-tax-information'
                error={!affiliateData.user?.foreignTaxInformation && focused.includes('foreignTaxInformation') && requiredFields.includes('foreignTaxInformation')}
              />
            </Grid>
          )}
          {allFieldTypes.includes('relation') && showFields && (
            <SelectField
              fullWidth
              sx={{ mt: 2 }}
              testId='affiliate-relation'
              label={t('client:details.relation')}
              value={affiliateData.relation || ''}
              onChange={(e: any) => setAffiliateData({ ...affiliateData, relation: e.target.value })}
              onBlur={() => setFocused([...focused, 'relation'])}
              error={!affiliateData.relation && focused.includes('relation') && requiredFields.includes('relation')}
            >
              {relations
                ? relations.map((item: any, idx: number) => (
                    <MenuItem key={idx} value={item.value}>
                      {item.label}
                    </MenuItem>
                ))
                : ALL_RELATIONS.map((item: any, idx: number) => (
                    <MenuItem data-testid={`affiliate-relation-${item}`} key={idx} value={item}>
                      {t(`client:details.relationOptions.${item}`)}
                    </MenuItem>
                ))}
            </SelectField>
          )}
        </DialogContent>
        <DialogFooter>
          {isUsingExistingAffiliate && !isUsingAccountOwner && (
            <Alert variant='outlined' icon={<WarningIcon />} severity='warning'>
              <Typography>{action === 'create' ? t('createWithExistingAffiliation') : t('updateExistingAffiliation')}</Typography>
            </Alert>
          )}
          {isUsingAccountOwner && (
            <Alert variant='outlined' icon={<WarningIcon />} severity='warning'>
              <Typography>{action === 'create' ? t('createUsingAccountOwner') : t('updateUsingAccountOwner')}</Typography>
            </Alert>
          )}
          <Box display='flex' justifyContent='end' p={1}>
            <Button dataTestId='create-btn' label={t(`client:form.${action === 'create' ? 'add' : 'update'}`)} type='submit' variant='tonal' disabled={isDisabled} />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
