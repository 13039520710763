import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { DateTime } from '../../../../components/misc/dateTime/dateTime';

import { Box, Skeleton, Typography } from '../../../1-primative';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TextField,
  MenuItem,
  Card,
  CardContent,
  TableHeadCell,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';

export const FETCH_JOB_EVENTS_QUERY = `
  query fetchEvents($input: FetchEventsInput!) {
    fetchEvents(input: $input) {
      events {
        id
        objectType
        eventType
        objectPayload
        createdAt
      }
      totalCount
    }
  }`;

const FETCH_CUSTODIAN_JOBS = gql`${FETCH_JOB_EVENTS_QUERY}`;

const isFilterActive = ({
  selectedJobState,
  selectedEventType,
}: {
  selectedJobState: string,
  selectedEventType: string,
}) => {
  if (selectedJobState !== 'ANY') return true;
  if (selectedEventType !== 'RECONCILIATION') return true;
  return false;
};

const Jobs = () => {
  const { t } = useTranslation(['reconciliation']);
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(25, 'pageSize');
  const [open, setOpen] = useState(false);
  const [activeJobEvent, setActiveJobEvent] = useState<any | null>(null);
  const [selectedJobState, setSelectedJobState] = usePageState('ANY', 'jobState');
  const [selectedEventType, setSelectedEventType] = usePageState('RECONCILIATION', 'eventType');

  const queryFilter = {
    organizationId: activeOrganization.id,
    objectType: 'Job',
    eventType: selectedEventType,
    payloadStatus: selectedJobState === 'ANY' ? undefined : selectedJobState,
  };

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_CUSTODIAN_JOBS, {
    errorPolicy: 'all',
    variables: {
      input: {
        filter: queryFilter,
        pagination: {
          sortField: 'createdAt',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) return <Typography>Error loading jobs</Typography>;

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
          <FilterModal filterExists={isFilterActive({
            selectedJobState,
            selectedEventType,
          })}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                select
                value={selectedJobState}
                onChange={(e: any) => {
                  setSelectedJobState(e.target.value);
                  setPage(1);
                }}
                label={t('eventTable.state')}
                fullWidth
              >
                <MenuItem value="ANY">{t('jobState.ANY')}</MenuItem>
                <MenuItem value="INITIATED">{t('jobState.INITIATED')}</MenuItem>
                <MenuItem value="COMPLETED">{t('jobState.COMPLETED')}</MenuItem>
                <MenuItem value="FAILED">{t('jobState.FAILED')}</MenuItem>
              </TextField>

              <TextField
                select
                value={selectedEventType}
                onChange={(e: any) => {
                  setSelectedEventType(e.target.value);
                  setPage(1);
                }}
                label={t('type')}
                fullWidth
              >
                <MenuItem value="RECONCILIATION">
                  {t('jobEventType.RECONCILIATION')}
                </MenuItem>
              </TextField>
            </Box>
          </FilterModal>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('eventTable.eventType')}</TableHeadCell>
              <TableHeadCell>{t('eventTable.jobName')}</TableHeadCell>
              <TableHeadCell>{t('eventTable.ranAt')}</TableHeadCell>
              <TableHeadCell>{t('eventTable.state')}</TableHeadCell>
              <TableHeadCell>{t('eventTable.organization')}</TableHeadCell>
            </TableRow>

            {loading && !previousData && [...Array(pageSize)].map((_, i) => (
              <TableRow key={i}>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
              </TableRow>
            ))}

            {(data || previousData)?.fetchEvents?.events?.map((event: any) => {
              const { jobName } = event.objectPayload;
              const { status } = event.objectPayload;
              const { organizationName } = event.objectPayload;

              return (
                <TableRow
                  hover
                  pointer
                  key={event.id}
                  onClick={() => {
                    setOpen(true);
                    setActiveJobEvent(event);
                  }}
                  selected={activeJobEvent?.id === event.id}
                >
                  <TableCell>
                    <Badge label={event.eventType} />
                  </TableCell>
                  <TableCell>{jobName}</TableCell>
                  <TableCell>
                    <DateTime variant="subtitle3" date={event.createdAt} />
                  </TableCell>
                  <TableCell>
                    <Badge
                      label={status}
                      color={
                        status === 'COMPLETED'
                          ? 'positive'
                          : status === 'INITIATED'
                            ? 'warning'
                            : 'negative'
                      }
                      variant="text"
                    />
                  </TableCell>
                  <TableCell>{organizationName || ''}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchEvents?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          {`Additional details for ${activeJobEvent?.objectPayload?.jobName || ''} ran on ${activeJobEvent?.createdAt || ''}`}
        </DialogTitle>
        <DialogContent>
          <pre>{JSON.stringify(activeJobEvent, null, 2)}</pre>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Jobs;
