import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { kebabCase } from 'lodash';

import {
  Button,
  Form,
  SelectField,
  TextField,
  Typography,
  Switch,
  TranslatableTextField,
  SecuritySelect,
} from 'ovComponents';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { getBackendLanguage, translateBackend } from 'assets/i18n/config';
import { Theme } from 'interfaces';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export enum ModelType {
  STANDARD = 'STANDARD',
}

interface Props {
  options: any;
  modelPortfolioData: any;
  loading: boolean;
  continueFunc: () => void;
  themes?: Theme[];
  workflowCompletion?: any;
  setModelPortfolioData: (data: any) => void;
}

export const AddModelPortfolioDetailsVisual = ({
  options,
  modelPortfolioData,
  loading,
  continueFunc,
  themes = [],
  workflowCompletion,
  setModelPortfolioData,
}: Props) => {
  const { t } = useTranslation(['modelPortfolios, shared']);
  const { sendAnalytic } = useContext(AnalyticsContext);

  const [validationErrors, setValidationErrors] = useState<Set<string>>(new Set());

  const validateField = (fieldName: string, value: any): boolean => {
    if (!options[fieldName]?.enabled || options[fieldName]?.required === 'NOT_REQUIRED') {
      return true;
    }

    if (fieldName === 'translatedName' || fieldName === 'translatedPortfolioDescription') {
      return value?.[getBackendLanguage()]?.trim()?.length > 0;
    }

    if (fieldName === 'riskProfileName') {
      return value?.[getBackendLanguage()]?.trim()?.length > 0;
    }

    return value !== undefined && value !== null && value !== '';
  };

  const handleFieldBlur = (fieldName: string) => {
    const isValid = validateField(fieldName, modelPortfolioData[fieldName]);
    setValidationErrors((prev: Set<string>) => {
      const newErrors = new Set(prev);
      if (!isValid) {
        newErrors.add(fieldName);
      } else {
        newErrors.delete(fieldName);
      }
      return newErrors;
    });
  };

  const isFormValid = () => {
    const requiredFields = Object.keys(options).filter(
      (key) => {
        if (!options[key]?.enabled) return false;
        if (options[key]?.required === 'NOT_REQUIRED') return false;
        return true;
      },
    );
    return requiredFields.every((field) => validateField(field, modelPortfolioData[field]));
  };

  const submit = () => {
    if (!isFormValid()) return;

    sendAnalytic(ovAnalyticsEvents.workflowsAddModelPortfolioDetailsContinueButtonSelect, {
      workflowStepTitle: options?.title,
      workflowId: workflowCompletion?.workflow?.id,
      workflowName: workflowCompletion?.workflow?.name,
      activeWorkflowCompletionId: workflowCompletion?.id,
      objectId: workflowCompletion?.objectId,
      objectType: workflowCompletion?.objectType,
    });
    continueFunc();
  };

  const update = (newValue: any) => {
    setModelPortfolioData({ ...modelPortfolioData, ...newValue });
  };

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant="displayLarge" sx={{
          mt: 1,
          mb: 2,
          fontWeight: 400,
          fontSize: '40px',
          fontFamily: 'Tiempos Text',
        }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (
            <InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />
          )}
        </Typography>
      )}

      <Grid container spacing={2}>
        {options?.modelType?.enabled && (
          <Grid item xs={12} md={6}>
            <SelectField
              testId="model-type"
              onChange={(e: any) => update({ modelType: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.modelType?.label)}
              infoTooltip={additionalInfo(options?.modelType?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.modelType}
              onBlur={() => handleFieldBlur('modelType')}
              error={validationErrors.has('modelType')}
              required={options.modelType.required === 'ERROR'}
            >
              <MenuItem
                value={ModelType.STANDARD}
                data-testid={`type-${kebabCase(ModelType.STANDARD)}`}
              >
                {t('modelPortfolios:modelType.STANDARD')}
              </MenuItem>
            </SelectField>
          </Grid>
        )}

        {options?.translatedName?.enabled && (
          <Grid item xs={12} md={6}>
            <TranslatableTextField
              testId="model-name"
              onChange={(value) => update({ translatedName: value })}
              disabled={loading}
              label={translateBackend(options?.translatedName?.label)}
              fullWidth
              value={modelPortfolioData.translatedName || { en: '', fr: '' }}
              onBlur={() => handleFieldBlur('translatedName')}
              error={validationErrors.has('translatedName')}
              required={options.translatedName.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.translatedPortfolioDescription?.enabled && (
          <Grid item xs={12}>
            <TranslatableTextField
              testId="model-description"
              onChange={(value) => update({ translatedPortfolioDescription: value })}
              disabled={loading}
              label={translateBackend(options?.translatedPortfolioDescription?.label)}
              fullWidth
              multiline
              rows={1}
              value={modelPortfolioData.translatedPortfolioDescription || { en: '', fr: '' }}
              onBlur={() => handleFieldBlur('translatedPortfolioDescription')}
              error={validationErrors.has('translatedPortfolioDescription')}
              required={options.translatedPortfolioDescription.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.translatedInvestmentObjective?.enabled && (
          <Grid item xs={12}>
            <TranslatableTextField
              testId="model-investment-objective"
              onChange={(value) => update({ translatedInvestmentObjective: value })}
              disabled={loading}
              label={translateBackend(options?.translatedInvestmentObjective?.label)}
              fullWidth
              multiline
              rows={1}
              value={modelPortfolioData.translatedInvestmentObjective || { en: '', fr: '' }}
              onBlur={() => handleFieldBlur('translatedInvestmentObjective')}
              error={validationErrors.has('translatedInvestmentObjective')}
              required={options.translatedInvestmentObjective.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.riskProfileName?.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="risk-profile"
              onChange={(e: any) => update({ riskProfileName: { [getBackendLanguage()]: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.riskProfileName?.label)}
              infoTooltip={additionalInfo(options?.riskProfileName?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.riskProfileName?.[getBackendLanguage()] ?? ''}
              onBlur={() => handleFieldBlur('riskProfileName')}
              error={validationErrors.has('riskProfileName')}
              required={options.riskProfileName.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.themeId?.enabled && (
          <Grid item xs={12} md={6}>
            <SelectField
              testId="model-family"
              onChange={(e: any) => update({ themeId: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.themeId?.label)}
              infoTooltip={additionalInfo(options?.themeId?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.themeId}
              onBlur={() => handleFieldBlur('themeId')}
              error={validationErrors.has('themeId')}
              required={options.themeId.required === 'ERROR'}
            >
              {themes.map((theme) => (
                <MenuItem
                  key={theme.id}
                  value={theme.id}
                  data-testid={`theme-${kebabCase(theme.translatedName?.[getBackendLanguage()] || '')}`}
                >
                  {theme.translatedName?.[getBackendLanguage()]}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}

        {options?.riskReturnLevel?.enabled && (
          <Grid item xs={12} md={6}>
            <TextField
              testId="risk-return-level"
              type="number"
              onChange={(e: any) => update({ riskReturnLevel: parseFloat(e.target.value) })}
              disabled={loading}
              fullWidth
              label={translateBackend(options?.riskReturnLevel?.label)}
              infoTooltip={additionalInfo(options?.riskReturnLevel?.additionalInfo)}
              value={modelPortfolioData.riskReturnLevel}
              onBlur={() => handleFieldBlur('riskReturnLevel')}
              error={validationErrors.has('riskReturnLevel')}
              required={options.riskReturnLevel.required === 'ERROR'}
            />
          </Grid>
        )}

        <Grid container item xs={12} spacing={2} sx={{ mt: 1, mb: 1 }}>
          {options?.suggestable?.enabled && (
            <Grid item xs={12} md={6}>
              <Switch
                testId="suggestable"
                onChange={(e: any) => update({ suggestable: e || options?.suggestable?.defaultValue })}
                disabled={loading}
                label={translateBackend(options?.suggestable?.label)}
                checked={modelPortfolioData.suggestable || options?.suggestable?.defaultValue}
              />
            </Grid>
          )}

          {options?.zeroPercentCash?.enabled && (
            <Grid item xs={12} md={6}>
              <Switch
                testId="zero-percent-cash"
                onChange={(e: any) => update({ zeroPercentCash: e || options?.zeroPercentCash?.defaultValue })}
                disabled={loading}
                label={translateBackend(options?.zeroPercentCash?.label)}
                checked={modelPortfolioData.zeroPercentCash || options?.zeroPercentCash?.defaultValue}
              />
            </Grid>
          )}
        </Grid>

        {options?.zeroPercentCash?.enabled && modelPortfolioData.zeroPercentCash && (
          <Grid item xs={12}>
            <SecuritySelect
              setSecurity={(security: any) => update({ cashEquivalentSecurity: security })}
              value={modelPortfolioData.cashEquivalentSecurity?.id || ''}
              label={t('modelPortfolios:cashEquivalentSecurity')}
              disabledWith={loading ? 'Loading...' : null}
              inputError={!modelPortfolioData.cashEquivalentSecurity && validationErrors.has('cashEquivalentSecurity')}
            />
          </Grid>
        )}

        {options?.lowThresholdScore?.enabled && (
          <Grid item xs={12} md={4}>
            <TextField
              testId="low-threshold-score"
              type="number"
              onChange={(e: any) => update({ lowThresholdScore: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.lowThresholdScore?.label)}
              infoTooltip={additionalInfo(options?.lowThresholdScore?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.lowThresholdScore}
              onBlur={() => handleFieldBlur('lowThresholdScore')}
              error={validationErrors.has('lowThresholdScore')}
              required={options.lowThresholdScore.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.suggestedMinScore?.enabled && (
          <Grid item xs={12} md={4}>
            <TextField
              testId="suggested-min-score"
              type="number"
              onChange={(e: any) => update({ suggestedMinScore: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.suggestedMinScore?.label)}
              infoTooltip={additionalInfo(options?.suggestedMinScore?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.suggestedMinScore}
              onBlur={() => handleFieldBlur('suggestedMinScore')}
              error={validationErrors.has('suggestedMinScore')}
              required={options.suggestedMinScore.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.suggestedMaxScore?.enabled && (
          <Grid item xs={12} md={4}>
            <TextField
              testId="suggested-max-score"
              type="number"
              onChange={(e: any) => update({ suggestedMaxScore: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.suggestedMaxScore?.label)}
              infoTooltip={additionalInfo(options?.suggestedMaxScore?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.suggestedMaxScore}
              onBlur={() => handleFieldBlur('suggestedMaxScore')}
              error={validationErrors.has('suggestedMaxScore')}
              required={options.suggestedMaxScore.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.forecastedRateOfReturn?.enabled && (
          <Grid item xs={12} md={6}>
            <TextField
              testId="forecasted-rate-of-return"
              type="number"
              onChange={(e: any) => update({ forecastedRateOfReturn: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.forecastedRateOfReturn?.label)}
              infoTooltip={additionalInfo(options?.forecastedRateOfReturn?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.forecastedRateOfReturn}
              onBlur={() => handleFieldBlur('forecastedRateOfReturn')}
              error={validationErrors.has('forecastedRateOfReturn')}
              required={options.forecastedRateOfReturn.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.forecastedStandardDeviation?.enabled && (
          <Grid item xs={12} md={6}>
            <TextField
              testId="forecasted-standard-deviation"
              type="number"
              onChange={(e: any) => update({ forecastedStandardDeviation: parseFloat(e.target.value) })}
              disabled={loading}
              label={translateBackend(options?.forecastedStandardDeviation?.label)}
              infoTooltip={additionalInfo(options?.forecastedStandardDeviation?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.forecastedStandardDeviation}
              onBlur={() => handleFieldBlur('forecastedStandardDeviation')}
              error={validationErrors.has('forecastedStandardDeviation')}
              required={options.forecastedStandardDeviation.required === 'ERROR'}
            />
          </Grid>
        )}

        {options?.url?.enabled && (
          <Grid item xs={12}>
            <TextField
              testId="url"
              onChange={(e: any) => update({ url: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.url?.label)}
              infoTooltip={additionalInfo(options?.url?.additionalInfo)}
              fullWidth
              value={modelPortfolioData.url ?? ''}
              onBlur={() => handleFieldBlur('url')}
              error={validationErrors.has('url')}
              required={options.url.required === 'ERROR'}
            />
          </Grid>
        )}

      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            dataTestId="continue-button"
            onClick={submit}
            disabled={loading || !isFormValid()}
            variant="filled"
            color="primary"
            label={t('shared:continue')}
          />
        </Box>
      </Grid>
    </Form>
  );
};

export default AddModelPortfolioDetailsVisual;
