import { isObject, keyBy, transform } from 'lodash';

export const deepOmit = (obj: any, keysToOmit: string[]) => {
  const keysToOmitIndex = keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]); // create an index object of the keys that should be omitted

  const omitFromObject = (ob: any) => transform(ob, (result: any, value, key) => { // transform to a new object
    if (key in keysToOmitIndex) { // if the key is in the index skip it
      return;
    }

    /* eslint-disable-next-line no-param-reassign */
    result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
  });

  return omitFromObject(obj); // return the inner function result
};
