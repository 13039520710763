import { Organization } from './organization';

export interface CurrentIntegrationType {
  configuration: any;
  provider: IntegrationProvider;
  type: IntegrationType;
  state: IntegrationState;
  scope?: string[];
  id: string;
}

export interface NewIntegrationType {
  configuration: any;
  provider: IntegrationProvider | string;
  type: IntegrationType | string;
  organizationId: string;
  scope?: string[];
}

export interface Integration {
  id?: string
  configuration: any;
  provider: IntegrationProvider;
  type: IntegrationType;
  organization: Organization;
  scope?: string[];
}

export enum IntegrationProvider {
  CANADA_POST = 'CANADA_POST',
  FLINKS = 'FLINKS',
  GOOGLE = 'GOOGLE',
  FIREBASE = 'FIREBASE',
  IEX_CLOUD = 'IEX_CLOUD',
  LSEG = 'LSEG',
  MIXPANEL = 'MIXPANEL',
  PERSONA = 'PERSONA',
  QUOTE_MEDIA = 'QUOTE_MEDIA',
  ENVESTNET = 'ENVESTNET',
  ATB = 'ATB',
  PLAID = 'PLAID',
  ONESPAN = 'ONESPAN',
  ZENDESK = 'ZENDESK',
}

export enum IntegrationType {
  ADDRESS = 'ADDRESS',
  ANALYTICS = 'ANALYTICS',
  BANKING = 'BANKING',
  FINANCIAL_DATA_WIDGETS = 'FINANCIAL_DATA_WIDGETS',
  ID_VERIFICATION = 'ID_VERIFICATION',
  MARKET_DATA = 'MARKET_DATA',
  DATA_INGESTION = 'DATA_INGESTION',
  STATISTICS = 'STATISTICS',
  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',
  FORM = 'FORM',
  CHAT_AGENT = 'CHAT_AGENT',
}

export enum IntegrationState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE,',
}

export enum IntegrationTransitionState {
  ACTIVATE = 'activate',
  ARCHIVE = 'archive',
  DEACTIVATE = 'deactivate',
}

export enum IntegrationTypes {
  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',
  ADDRESS = 'ADDRESS',
  ANALYTICS = 'ANALYTICS',
  BANKING = 'BANKING',
  DATA_INGESTION = 'DATA_INGESTION',
  FINANCIAL_DATA_WIDGETS = 'FINANCIAL_DATA_WIDGETS',
  ID_VERIFICATION = 'ID_VERIFICATION',
  MARKET_DATA = 'MARKET_DATA',
  STATISTICS = 'STATISTICS',
}
