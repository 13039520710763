import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import workflow from './createModelPortfolioWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { WorkflowContext, WorkflowDataType } from '../workflowCompletion/workflowCompletion';
import { ConfirmationModal } from '../../3-pattern/confirmationDialog/confirmationDialog';

const cancelDialogStyles = {
  dialogTitleStyles: {
    fontWeight: '700',
  },
  dialogContainer: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '416px',
      },
    },
  },
  dialogContentStyles: {
    width: 'auto',
    maxWidth: '421px',
    minWidth: 'auto',
  },
};

const initialWorkflowCompletion = {
  id: '123',
  currentStep: workflow.steps[0],
  currentSubStep: workflow.steps[0].subSteps[0],
  workflow,
};

enum SegmentType {
  CHOOSE_PORTFOLIO_TYPE = 'CHOOSE_PORTFOLIO_TYPE',
  SELECT_PORTFOLIO_TEMPLATE = 'SELECT_PORTFOLIO_TEMPLATE',
  CREATE_PORTFOLIO_TEMPLATE = 'CREATE_PORTFOLIO_TEMPLATE',
}

const initialWorkflowData = {
  currentSegment: null,
  segmentHistory: [],
};

export const CreateModelPortfolioWorkflow = ({
  open,
  setOpen,
  objectId,
  refetch,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  objectId: string;
  refetch: () => void;
}) => {
  const { t } = useTranslation(['shared', 'workflowCompletions']);
  const [workflowData, setWorkflowData] = useState<WorkflowDataType>(initialWorkflowData);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [stepHistory, setStepHistory] = useState<any[]>([]);
  const [workflowCompletion, setWorkflowCompletion] = useState({
    objectId,
    ...initialWorkflowCompletion,
  });

  const resetWorkflow = () => {
    setWorkflowData(initialWorkflowData);
    setStepHistory([]);
    setWorkflowCompletion({
      objectId,
      ...initialWorkflowCompletion,
    });
  };

  const updateNext = () => {
    setStepHistory([...stepHistory, workflowCompletion.currentSubStep]);

    if (workflowData.currentSegment && workflowData.currentSegment !== SegmentType.CREATE_PORTFOLIO_TEMPLATE) {
      const { currentSegment, segmentHistory = [] } = workflowData;
      if (!segmentHistory.length || segmentHistory[segmentHistory.length - 1] !== currentSegment) {
        setWorkflowData({
          ...workflowData,
          segmentHistory: [...segmentHistory, currentSegment],
        });
      }
    }

    updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, () => {
      refetch();
      setOpen(false);
      resetWorkflow();
    });
  };

  const handleModelPortfolioSectionNavigation = () => {
    const { segmentHistory = [], currentSegment } = workflowData;

    if (workflowData.currentSegment === SegmentType.CREATE_PORTFOLIO_TEMPLATE) {
      setWorkflowData({
        ...workflowData,
        currentSegment: SegmentType.SELECT_PORTFOLIO_TEMPLATE,
        segmentHistory: segmentHistory
          .filter((segment, index) => segmentHistory.indexOf(segment) === index)
          .filter((segment) => segment !== SegmentType.CREATE_PORTFOLIO_TEMPLATE),
      });
      return;
    }

    if (currentSegment === SegmentType.SELECT_PORTFOLIO_TEMPLATE) {
      setWorkflowData({
        ...workflowData,
        modelPortfolioDetails: null,
        segmentHistory: [],
        currentSegment: SegmentType.CHOOSE_PORTFOLIO_TYPE,
      });
      return;
    }

    if (segmentHistory.length > 0) {
      const newHistory = Array.from(new Set(segmentHistory));
      const previousSegment = newHistory.pop();

      setWorkflowData({
        ...workflowData,
        segmentHistory: newHistory,
        currentSegment: previousSegment,
      });
      return;
    }

    setWorkflowData(initialWorkflowData);
  };

  const handleWorkflowNavigation = () => {
    if (stepHistory.length > 0) {
      const previousStep = stepHistory[stepHistory.length - 1];
      setStepHistory(stepHistory.slice(0, -1));
      setWorkflowCompletion({
        ...workflowCompletion,
        currentSubStep: previousStep,
      });
    } else if (workflowCompletion.currentSubStep?.id !== workflow.steps[0]?.subSteps[0]?.id) {
      updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);
    }
  };

  const updatePrevious = () => {
    const { currentSubStep } = workflowCompletion;

    if ((currentSubStep?.type === 'CREATE_MODEL_PORTFOLIO')) {
      handleModelPortfolioSectionNavigation();
      return;
    }

    handleWorkflowNavigation();
  };

  const closeModelPortfolioWorkflow = () => {
    setShowCancelDialog(true);
  };

  const handleConfirmClose = () => {
    setShowCancelDialog(false);
    setOpen(false);
    setWorkflowData(initialWorkflowData);
    setStepHistory([]);
    setWorkflowCompletion({
      objectId,
      ...initialWorkflowCompletion,
    });
  };

  const navigateToSegment = (segment: string) => {
    const { currentSegment } = workflowData;

    if (currentSegment !== segment) {
      const newHistory = currentSegment && currentSegment !== SegmentType.CREATE_PORTFOLIO_TEMPLATE
        ? [...(workflowData.segmentHistory || []), currentSegment]
        : workflowData.segmentHistory || [];

      setWorkflowData({
        ...workflowData,
        currentSegment: segment as SegmentType,
        segmentHistory: newHistory,
      });
    }
  };

  return (
    <WorkflowContext.Provider
      value={{
        workflowData,
        setWorkflowData,
        navigateToSegment,
      }}
    >
      <WorkflowCompletionVisual
        workflowCompletion={workflowCompletion}
        open={open}
        onClose={closeModelPortfolioWorkflow}
        onNext={updateNext}
        previousStep={updatePrevious}
        loading={false}
      />
      <ConfirmationModal
        open={showCancelDialog}
        title={t('workflowCompletions:workflowCancelModal.title')}
        bodyText={t('workflowCompletions:workflowCancelModal.bodyText')}
        onConfirm={handleConfirmClose}
        onCancel={() => setShowCancelDialog(false)}
        confirmButtonLabel={t('workflowCompletions:workflowCancelModal.confirmButtonText')}
        cancelButtonLabel={t('workflowCompletions:workflowCancelModal.cancelButtonText')}
        dialogStyles={cancelDialogStyles}
      />
    </WorkflowContext.Provider>
  );
};
