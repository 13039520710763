import { useContext, useState } from 'react';
import { AddModelPortfolioSecuritiesVisual } from './addModelPortfolioSecurities.visual';
import { WorkflowContext } from '../../workflowCompletion';
import { Security } from '../../../../3-pattern/securitiesSelectionTable/securitiesSelectionTable';
import { getBackendLanguage } from '../../../../../assets/i18n/config';

export const AddModelPortfolioSecurities = ({
  options,
  userId,
  onNext,
  stepLoading,
  workflowCompletion,
}: {
  options: any;
  userId: string;
  onNext: () => void;
  stepLoading: boolean;
  workflowCompletion?: any;
}) => {
  const { workflowData, setWorkflowData, updateWorkflowContext } = useContext(WorkflowContext);
  const [securities, setSecurities] = useState<Security[]>(workflowData.modelPortfolioDetails?.securities || []);

  const colorList = ['supportOne', 'supportTwo', 'supportThree', 'supportFour', 'supportFive', 'supportSix'];

  const handleAddEmptySecurity = () => {
    // Calculate the next tax rank by finding the highest existing rank and adding 1
    const nextTaxRank = securities.length > 0
      ? Math.max(...securities.map((security) => security.taxRank)) + 1
      : 1;

    const tempId = `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    const newSecurity: Security = {
      name: '',
      assetClass: '',
      taxRank: nextTaxRank,
      targetPercentage: null,
      primaryAssetClass: undefined,
      ticker: '',
      translatedName: {
        en: '',
        fr: '',
      },
      financialProductId: tempId,
      id: tempId,
      color: 'supportOne',
    };
    const updatedSecurities = [...securities, newSecurity];
    setSecurities(updatedSecurities);
  };

  const handleUpdateSecurity = (index: number, security: any) => {
    const updatedSecurities = [...securities];
    const currentSecurity = updatedSecurities[index];
    const updatedSecurity = { ...security } as Security;

    if (currentSecurity.financialProductId?.startsWith('temp-') && !security.financialProductId) {
      updatedSecurity.financialProductId = currentSecurity.financialProductId;
      updatedSecurity.id = currentSecurity.financialProductId;
    }

    updatedSecurities[index] = updatedSecurity;

    const seenAssetClasses = new Set<string>();
    const orderedAssetClasses = updatedSecurities.reduce<string[]>((acc, sec) => {
      const assetClass = sec.primaryAssetClass?.translatedName?.[getBackendLanguage()] || sec.assetClass;
      if (assetClass && !seenAssetClasses.has(assetClass)) {
        seenAssetClasses.add(assetClass);
        acc.push(assetClass);
      }
      return acc;
    }, []);

    const newColorMap = orderedAssetClasses.reduce<Record<string, string>>((acc, assetClass, i) => ({
      ...acc,
      [assetClass]: colorList[i % colorList.length],
    }), {});

    const finalSecurities = updatedSecurities.map((sec) => {
      const assetClass = sec.primaryAssetClass?.translatedName?.[getBackendLanguage()] || sec.assetClass;
      return {
        ...sec,
        color: assetClass ? newColorMap[assetClass] as 'supportOne' | 'supportTwo' | 'supportThree' | 'supportFour' | 'supportFive' | 'supportSix' : 'supportOne',
      };
    });

    setSecurities(finalSecurities);
  };

  const handleRemoveSecurity = (index: number) => {
    const updatedSecurities = securities.filter((_: any, i: number) => i !== index);
    setSecurities(updatedSecurities);
  };

  const handleUpdateTaxRank = (index: number, value: number) => {
    const updatedSecurities = [...securities];
    updatedSecurities[index].taxRank = value;
    setSecurities(updatedSecurities);
  };

  const handleUpdateTargetPercentage = (index: number, value: number) => {
    const updatedSecurities = [...securities];
    updatedSecurities[index].targetPercentage = value;
    setSecurities(updatedSecurities);
  };

  const handleSaveForLater = () => {
    // Implement save for later functionality
  };

  const handleImportFromCsv = () => {
    // Implement CSV import functionality
  };

  const handleReorderSecurities = (newSecurities: any[]) => {
    // Ensure we're working with a fresh copy of the securities array
    const updatedSecurities = newSecurities.map((security, index) => ({
      ...security,
      financialProductId: security.financialProductId,
      id: security.id || security.financialProductId,
      taxRank: security.taxRank,
      targetPercentage: security.targetPercentage,
      name: security.name,
      primaryAssetClass: security.primaryAssetClass,
      translatedName: security.translatedName,
    }));

    setSecurities(updatedSecurities);
  };

  const continueFunc = async () => {
    try {
      setWorkflowData({
        ...workflowData,
        modelPortfolioDetails: {
          ...workflowData.modelPortfolioDetails,
          securities,
        },
      });

      if (updateWorkflowContext) {
        await updateWorkflowContext();
      }
      // TODO: Needs to be passes to next workflow step and then to the backend

      onNext();
    } catch (error) { /* empty */ }
  };

  return (
    <AddModelPortfolioSecuritiesVisual
      options={options}
      workflowCompletion={workflowCompletion}
      securities={securities}
      continueFunc={continueFunc}
      onAddSecurity={handleAddEmptySecurity}
      onUpdateSecurity={handleUpdateSecurity}
      onRemoveSecurity={handleRemoveSecurity}
      onUpdateTaxRank={handleUpdateTaxRank}
      onUpdateTargetPercentage={handleUpdateTargetPercentage}
      onSaveForLater={handleSaveForLater}
      onImportFromCsv={handleImportFromCsv}
      onReorderSecurities={handleReorderSecurities}
      modelPortfolioDetails={workflowData.modelPortfolioDetails}
    />
  );
};

export default AddModelPortfolioSecurities;
