import { gql, useQuery } from '@apollo/client';
import { AccountStates, getMaskedAccountName } from 'interfaces';
import { usePermissions } from 'providers/userContextProvider';
import { Autocomplete, SelectField, MenuItem } from '../../2-component';

type SelectItem = { key: string, label: string };

export const FETCH_ACCOUNTS = (permissions: string[]) => gql`
  query fetchAccounts($filter: AccountsQueryFilter!) {
    fetchAccounts(input: { filter: $filter, pagination: { perPage: 1000 } }) {
      accounts {
        id
        type
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
      }
    }
  }
`;

const ACCOUNT_STATES = [AccountStates.ACTIVE, AccountStates.REQUESTED, AccountStates.FROZEN, AccountStates.FAILED, AccountStates.INITIATED, AccountStates.READY];

export const MultiAccountSelect = ({
  forId, label = '', error, errorText, onChange, selectedAccountIds, onBlur,
}: {
  forId: string, label?: string, error?: boolean, errorText?: string, onChange: (event: any) => void; selectedAccountIds?: string[], onBlur?: () => void,
}) => {
  const { permissions } = usePermissions();

  const { data } = useQuery(FETCH_ACCOUNTS(permissions), { variables: { filter: { userId: forId, states: ACCOUNT_STATES } }, fetchPolicy: 'no-cache' });

  const getAccountNames = (accountIds: any): SelectItem[] => accountIds.map((account: any) => {
    const fetchedAccount = data?.fetchAccounts?.accounts?.find((fetched: any) => fetched?.id === (account.id || account));
    return { key: fetchedAccount?.id as string || '', label: getMaskedAccountName(fetchedAccount) };
  }).filter((obj: SelectItem) => Object.keys(obj).length !== 0);

  return (
    <Autocomplete
      multiple
      fullWidth
      label={label}
      onChange={(_e: React.SyntheticEvent, value: SelectItem[]) => onChange(value.map((x) => x.key))}
      value={getAccountNames(selectedAccountIds || [])}
      options={data?.fetchAccounts?.accounts?.map((x: any) => ({ key: x.id, label: getMaskedAccountName(x) })) || []}
      isOptionEqualToValue={(option: SelectItem, value: SelectItem) => option.key === value.key}
      renderOption={(props: any, option: SelectItem) => <li {...props}>{option.label}</li>}
      error={error}
      errorText={errorText}
      onBlur={onBlur}
    />
  );
};

export const AccountSelect = ({
  forId, onChange, includeEmpty, value, label, size,
}: {
  forId: string, onChange: (event: any) => void, value: string, label: string, includeEmpty?: boolean, size?: 'small' | 'medium',
}) => {
  const { permissions } = usePermissions();

  const { data, loading } = useQuery(FETCH_ACCOUNTS(permissions), { variables: { filter: { userId: forId, states: ACCOUNT_STATES } }, fetchPolicy: 'no-cache' });

  return (
    <>
      <SelectField value={data ? value : ''} label={label} fullWidth onChange={onChange} size={size}>
        {includeEmpty && <MenuItem key='empty' value=''>-</MenuItem>}
        {loading ? (<MenuItem>...</MenuItem>) : (
          data?.fetchAccounts?.accounts?.map((x: any) => (
            <MenuItem key={x.id} value={x.id}>{getMaskedAccountName(x)}</MenuItem>
          ))
        )}
      </SelectField>
    </>
  );
};
