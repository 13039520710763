import { Grid, Typography } from '../../1-primative';
import {
  Alert,
  Card, CardContent,
} from '../../2-component';

export const SettingsTitle = ({ title, description }: { title: string, description: string }) => (
  <>
    <Typography variant='headingLarge' sx={{ mb: 1 }}>{title}</Typography>
    <Typography variant='bodyLarge' colorVariant='variant' sx={{ mb: 2 }}>{description}</Typography>
  </>
);

export const SettingsCard = ({
  title, description, children, highlightInfo,
}: {
  title: string, description: string, children: React.ReactNode, highlightInfo?: React.ReactNode,
}) => (
  <Card sx={{ maxWidth: '1440px', overflow: 'visible' }}>
    <CardContent sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SettingsTitle title={title} description={description} />
          {highlightInfo && (
            <Alert severity='info' icon={false}>
              {highlightInfo}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {children}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
