import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { BulkImport } from '../../../../interfaces/bulkImport';
import { getOrganizationUserName } from '../../../../interfaces/organizationUser';
import { useLocalization } from '../../../../util/useLocalization';
import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination,
  Badge, Button, Card, CardContent,
} from '../../../2-component';
import { SettingsTitle } from '../../../3-pattern';

const FETCH_BULK_IMPORTS = gql`
  query fetchBulkImports($input: FetchBulkImportsInput!) {
    fetchBulkImports(input: $input) {
      totalCount
      bulkImports {
        id
        organization { id name }
        name
        createdAt
        state
        organizationUser { id firstName lastName }
      }
    }
  }
`;

const CREATE_BULK_IMPORT = gql`
  mutation createBulkImport($input: CreateBulkImportInput!){
    createBulkImport(input: $input) {
      bulkImport {
        id
      }
    }
  }
`;

export const BulkImportsSettings = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { localizedDateTime } = useLocalization();
  const { t } = useTranslation(['bulkImport']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_BULK_IMPORTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [newBulkImport, { data: newData, loading: newLoading }] = useMutation(CREATE_BULK_IMPORT);

  useEffect(() => {
    if (newData) {
      navigate(`${newData.createBulkImport.bulkImport.id}`);
    }
  }, [newData, navigate]);

  const getStatusColor = (state?: string) => {
    if (state === 'COMPLETED' || state === 'PROCESSED') return 'positive';
    if (state === 'FAILED' || state === 'PARTIALLY_FAILED') return 'negative';
    return 'warning';
  };

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      <SettingsTitle title={t('settings:bulkImport.title')} description={t('settings:bulkImport.description')} />
      <Card loading={loading}>
        {permissions.includes('write:bulk_import') && (
          <CardContent>
            <Box display='flex' justifyContent='flex-end'>
              <Button disabled={newLoading} leadingIcon={Add} onClick={() => {
                newBulkImport({
                  variables: {
                    input: { organizationId: activeOrganization.id },
                  },
                });
              }} label={t('startNew')} />
            </Box>
          </CardContent>
        )}
        <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('table.createdAt')}</TableHeadCell>
              <TableHeadCell>{t('table.createdBy')}</TableHeadCell>
              <TableHeadCell>{t('table.organization')}</TableHeadCell>
              <TableHeadCell align="right">{t('table.state')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.fetchBulkImports?.bulkImports?.map((bulkImport: BulkImport) => (
              <TableRow
                hover
                key={bulkImport.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => navigate(`${bulkImport.id}`)}
              >
                <TableCell>{localizedDateTime(bulkImport.createdAt)}</TableCell>
                <TableCell>{getOrganizationUserName(bulkImport.organizationUser)}</TableCell>
                <TableCell>{bulkImport.organization?.name}</TableCell>
                <TableCell align="right"><Badge label={bulkImport.state ?? ''} color={getStatusColor(bulkImport.state)}/></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchBulkImports?.totalCount ?? 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
    </>
  );
};
