import { gql, useMutation, useQuery } from '@apollo/client';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import {
  Box,
  Skeleton,
  Typography,
} from '../../1-primative';
import {
  Button, Card, CardContent, Pagination, Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../2-component';
import { SubAccountDailyFee } from '../../../interfaces/subAccountDailyFee';
import { formatDecimalMoneyValue, formatMoneyValue } from '../../../util';
import { SubAccountBilling } from '../../../interfaces/subAccountBilling';
import { BillingCycleStates } from '../../../interfaces/billingCycle';
import { getSubAccountName } from '../../../interfaces/subAccount';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import OverrideFeesModal, { UPDATE_SUB_ACCOUNT_BILLING } from './components/overrideFeesModal';
import { DailyFeeGridBox } from './components/dailyFeeGridBox';
import { useLocalization } from '../../../util/useLocalization';

export const FETCH_SUB_ACCOUNT_BILLING = gql`
  query fetchSubAccountBilling ($input: FetchSubAccountBillingsInput!) {
    fetchSubAccountBillings (input: $input) {
      subAccountBillings {
        id
        billingCycle { id state }
        feeTier { id name }
        subAccount {
          id
          account { id type user { id firstName lastName physicalAddress { province } } }
        }
        feeCents
        salesTaxCents
        adjustedFeeCents
        adjustedSalesTaxCents
        notes
        marketValueCentsOnLastDay
        chargeableMarketValueCentsOnLastDay
        billingDate
        startDate
        endDate
        accountBaseCurrency
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT_DAILY_FEES_QUERY = `query fetchSubAccountDailyFees($input: FetchSubAccountDailyFeesInput!) {
  fetchSubAccountDailyFees(input: $input) {
    totalCount
    subAccountDailyFees {
      id
      date
      user {
        id
        firstName
        lastName
        entityName
        organization {
          id
          name
        }
      }
      subAccount {
        id
        name
        goal {
          id
          name
        }
        account {
          id
          type
          feePaymentAccount {
             id
          }
          custodianAccountNumber
        }
      }
      billingCycle {
         state
         startDate
         endDate
         salesTaxCents
         projectedFeeAndTaxCents
      }
      feeTier {
        id
        name
      }
      marketValueCents
      moneyAvailableCents
      gridScopeTotalMarketValueCents
      annualFeeBps
      chargeableMarketValueCents
      totalDailyFeeCents
      dailyFeeBps
      dailyFixedFeeCents
    }
  }
}`;

export const FETCH_SUB_ACCOUNT_DAILY_FEES = gql`${FETCH_SUB_ACCOUNT_DAILY_FEES_QUERY}`;

const isOverridden = (subAccountBilling: SubAccountBilling) => !isNil(subAccountBilling?.adjustedFeeCents) && !isNil(subAccountBilling?.adjustedSalesTaxCents);

const calculateFeesAndTax = (original: boolean, subAccountBilling?: SubAccountBilling) => {
  if (!original && subAccountBilling && !isNil(subAccountBilling?.adjustedFeeCents) && !isNil(subAccountBilling?.adjustedSalesTaxCents)) {
    const { adjustedFeeCents, adjustedSalesTaxCents } = subAccountBilling;
    return (adjustedFeeCents + adjustedSalesTaxCents);
  }
  return (subAccountBilling?.feeCents ?? 0) + (subAccountBilling?.salesTaxCents ?? 0);
};

export const DailyFeeDetails = () => {
  const { t } = useTranslation();
  const { localizedDate } = useLocalization();
  const [page, setPage] = useState(1);
  const { subAccountId, billingCycleId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const selectedSubAccountBillingId = useRef<string>('');
  const { showToast } = useGlobalToast();

  const pageSize = 32;

  const { refetch, loading: subBillingLoading, data: subBillings } = useQuery<{ fetchSubAccountBillings:{
    subAccountBillings?:SubAccountBilling[]
  } }>(FETCH_SUB_ACCOUNT_BILLING, {
    variables: {
      input: {
        filter: {
          subAccountIds: subAccountId,
          billingCycleIds: billingCycleId,
        },
      },
    },

  });

  const subBilling = useMemo(
    () => subBillings?.fetchSubAccountBillings?.subAccountBillings?.[0],
    [subBillings],
  );

  const [updateSubAccountBilling] = useMutation(UPDATE_SUB_ACCOUNT_BILLING);

  const {
    loading, data, previousData,
  } = useQuery(FETCH_SUB_ACCOUNT_DAILY_FEES, {
    variables: {
      input: {
        filter: {
          subAccountIds: subAccountId,
          billingCycleIds: billingCycleId,
        },
        pagination: {
          sortField: 'date', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  return (
    <Card loading={loading || subBillingLoading}>
      <CardContent>
        { subBilling && (<DailyFeeGridBox row={subBilling} />) }
        <Box display='flex' flexDirection='row' gap={3} m={2}>
          <Box display='flex' flexDirection='column'>
            <Typography variant='labelSmall'>{t('feeAndBilling:feeReport.table.billableAumLastDay')}</Typography>
            <Typography variant='bodyLarge'>{formatMoneyValue(subBilling?.marketValueCentsOnLastDay)}</Typography>
          </Box>
          {!isNil(subBilling?.adjustedFeeCents) ? (
            <>
              <Box display='flex' flexDirection='column'>
                <Typography variant='labelSmall'>{t('feeAndBilling:feeReport.table.adjustedAccuredFees')}</Typography>
                <Box display='flex' justifyContent='start' gap={1}>
                  <Typography variant='bodyLarge' sx={{ textDecoration: subBilling && isOverridden(subBilling) ? 'line-through' : 'none' }}>
                    {formatMoneyValue(calculateFeesAndTax(true, subBilling))}
                  </Typography>
                  <Typography variant='bodyLarge'>{formatMoneyValue(calculateFeesAndTax(false, subBilling))}</Typography>
                </Box>
              </Box>
              {subBilling?.billingCycle?.state === BillingCycleStates.AWAITING_REVIEW && (
                <Button
                  sx={{ float: 'right' }}
                  type='submit'
                  onClick={async () => {
                    if (subBilling?.id) {
                      const response = await updateSubAccountBilling({
                        variables: {
                          input: {
                            adjustedFeeCents: null,
                            adjustedSalesTaxCents: null,
                            notes: null,
                            subAccountBillingId: subBilling?.id,
                          },
                        },
                      });
                      if (response?.data) {
                        showToast({ severity: 'info', message: t('feeAndBilling:feeReport.overrideFees.undoMessage') });
                        await refetch();
                      }
                    }
                  }}
                  label={t('feeAndBilling:feeReport.overrideFees.undo')}
                />
              )}
            </>
          ) : (
            <>
              <Box display='flex' flexDirection='column'>
                <Typography variant='labelSmall'>{t('feeAndBilling:feeReport.table.accuredFees')}</Typography>
                <Typography variant='bodyLarge'>{formatMoneyValue(calculateFeesAndTax(true, subBilling))}</Typography>
              </Box>
              {subBilling && subBilling.billingCycle?.state === BillingCycleStates.AWAITING_REVIEW && (
                <Button
                  sx={{ float: 'right' }}
                  type='submit'
                  onClick={() => {
                    if (subBilling.id) {
                      selectedSubAccountBillingId.current = subBilling.id;
                      setModalOpen(true);
                    }
                  }}
                  label={t('feeAndBilling:feeReport.overrideFees.title')}
                />
              )}
            </>
          )}
        </Box>
      </CardContent>
      <Table>
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('feeAndBilling:feeReport.dailyFee.date')}</TableHeadCell>
            <TableHeadCell right>{t('feeAndBilling:feeReport.dailyFee.billableAum')}</TableHeadCell>
            <TableHeadCell right>{t('feeAndBilling:feeReport.table.billableAumWithReductionsApplied')}</TableHeadCell>
            <TableHeadCell right>{t('feeAndBilling:feeReport.dailyFee.dailyFeeBps')}</TableHeadCell>
            <TableHeadCell right>{t('feeAndBilling:feeReport.dailyFee.dailyFixedFeeCents')}</TableHeadCell>
            <TableHeadCell right>{t('feeAndBilling:feeReport.dailyFee.totalDailyFeeCents')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          { data?.fetchSubAccountDailyFees.subAccountDailyFees.map((x: SubAccountDailyFee) => (
            <TableRow key={x.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{localizedDate(x.date)}</TableCell>
              <TableCell number>{formatMoneyValue(x.marketValueCents)}</TableCell>
              <TableCell number>
              {formatMoneyValue((x?.marketValueCents ?? 0) - (x.chargeableMarketValueCents ?? 0))}
              </TableCell>
              <TableCell number>{x.dailyFeeBps?.toFixed(4) ?? 0}</TableCell>
              <TableCell number>{formatDecimalMoneyValue(x.dailyFixedFeeCents)}</TableCell>
              <TableCell number>{formatDecimalMoneyValue(x.totalDailyFeeCents)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchSubAccountDailyFees?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      { subBilling && (
        <OverrideFeesModal
          handleClose={() => {
            setModalOpen(false);
          }}
          open={modalOpen}
          afterUpdate={async () => {
            await refetch();
          }}
          subAccountBilling={subBilling}
          subAccountTitle={getSubAccountName(subBilling?.subAccount, true)}
        />
      )}
    </Card>
  );
};

export default DailyFeeDetails;

export { DailyFeeDetailsBreadcrumb } from './dailyFeeDetailsBreadcrumb';
