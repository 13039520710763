import { useContext, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Link as MuiLink, Skeleton } from '../../1-primative';
import {
  MenuItem, Table, TableBody, TableCell, TableHeadCell,
  TableRow, SelectField, Pagination, IconButton, Menu,
  CardContent, TextField, DateField, Card, Badge,
} from '../../2-component';
import { useGlobalStats } from '../../../providers/globalStatsHooks';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { AccountTypeSelect, FilterModal } from '../../3-pattern';

export const FETCH_STATEMENTS = gql`
  query fetchAccountStatements($input: FetchAccountStatementsInput!) {
    fetchAccountStatements(input: $input) {
      accountStatements {
        id
        state
        account {
          id
          type
        }
        user {
          id
          firstName
          lastName
          organization {
            name
          }
        }
      }
      totalCount
    }
  }
`;
export const TRANSITION_STATEMENT = gql`
  mutation transitionAccountStatement($input: TransitionAccountStatementInput!) {
    transitionAccountStatement(input: $input) {
      accountStatement {
        id
      }
    }
  }
`;
enum StatementStates {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  FAILED = 'FAILED',
  RECONCILED = 'RECONCILED',
}
const transitions = [
  {
    name: 'fail',
    from: [StatementStates.AWAITING_REVIEW],
  },
  {
    name: 'reconcile',
    from: [StatementStates.AWAITING_REVIEW],
  },
];
export const StatementsReview = () => {
  const { permissions } = usePermissions();
  const { statementsRefresh } = useGlobalStats();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['statementsReview', 'client']);
  const [page, setPage] = usePageState(1, 'page');
  const [currentStatement, setCurrentStatement] = useState<any>({});
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [transitionStatement] = useMutation(TRANSITION_STATEMENT);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filterTypeGroup, setFilterTypeGroup] = usePageState('ANY', 'typeGroup');
  const [filterAfterDate, setFilterAfterDate] = usePageState('', 'afterDate');
  const [filterBeforeDate, setFilterBeforeDate] = usePageState('', 'beforeDate');
  const [filterAccountType, setFilterAccountType] = usePageState('ANY', 'accountType');
  const [filterPeriod, setFilterPeriod] = usePageState('ANY', 'period');

  const {
    data, loading, previousData, refetch,
  } = useQuery(FETCH_STATEMENTS, {
    skip: !activeOrganization.id,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          state: 'AWAITING_REVIEW',
          organizationId: activeOrganization.id,
          afterDate: filterAfterDate === '' ? undefined : filterAfterDate,
          beforeDate: filterBeforeDate === '' ? undefined : filterBeforeDate,
          typeGroup: filterTypeGroup === 'ANY' ? undefined : filterTypeGroup,
          accountType: filterAccountType === 'ANY' ? undefined : filterAccountType,
        },
        pagination: {
          sortField: 'referenceDate',
          sortDesc: false,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });
  const generateNewFromDate = (value: string) => {
    if (value === 'YEAR_TO_DATE') {
      return dayjs().startOf('year').format('YYYY-MM-DD');
    }
    return '';
  };

  return (
    <Card loading={loading}>
      <CardContent sx={{ display: 'flex', justifyContent: 'end' }}>
        <FilterModal>
          <Box display='flex' flexDirection='column' gap={2}>
            <SelectField
              label={t('client:statements.byPeriod')}
              fullWidth
              value={filterPeriod || ''}
              onChange={(e: any) => {
                setFilterPeriod(e.target.value);
                setFilterAfterDate(generateNewFromDate(e.target.value));
                setFilterBeforeDate(dayjs().format('YYYY-MM-DD'));
              }}>
              <MenuItem value='ANY'>{t('client:statements.allTime')}</MenuItem>
              <MenuItem value='YEAR_TO_DATE'>{t('client:statements.yearToDate')}</MenuItem>
              <MenuItem value='CUSTOM'>{t('client:statements.custom')}</MenuItem>
            </SelectField>
            <DateField
              label={t('client:statements.fromDate')}
              value={filterAfterDate || null}
              fullWidth
              onChange={(date: any) => {
                setFilterPeriod('CUSTOM');
                setFilterAfterDate(dayjs(date?.toString()).format('YYYY-MM-DD'));
              }}
              renderInput={(params: any) => <TextField fullWidth {...params} />}
            />
            <DateField
              label={t('client:statements.toDate')}
              fullWidth
              value={filterBeforeDate || null}
              onChange={(date: any) => {
                setFilterBeforeDate(dayjs(date?.toString()).format('YYYY-MM-DD'));
                setFilterPeriod('CUSTOM');
              }}
              renderInput={(params: any) => <TextField fullWidth {...params} />}
            />
            <SelectField label={t('client:statements.typeGroup')} fullWidth
              value={filterTypeGroup || ''}
              onChange={(e: any) => setFilterTypeGroup(e.target.value)}
            >
              <MenuItem value='ANY'>{t('client:statements.any')}</MenuItem>
              <MenuItem value='TAX'>{t('client:statements.tax')}</MenuItem>
              <MenuItem value='STATEMENTS'>{t('client:statements.statements')}</MenuItem>
              <MenuItem value='MANAGEMENT_FEES'>{t('client:statements.managementFees')}</MenuItem>
            </SelectField>
            <AccountTypeSelect
              size='medium'
              onChange={(e: any) => setFilterAccountType(e)}
              value={filterAccountType || ''}
            />
          </Box>
        </FilterModal>
      </CardContent>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('statementsReview:table.client')}</TableHeadCell>
            <TableHeadCell>{t('statementsReview:table.organization')}</TableHeadCell>
            <TableHeadCell>{t('statementsReview:table.account')}</TableHeadCell>
            <TableHeadCell>{t('statementsReview:table.statementState')}</TableHeadCell>
            {permissions.includes('transition:account_statement') && (
              <TableHeadCell align='right'>{t('statementsReview:table.actions')}</TableHeadCell>
            )}
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              {permissions.includes('transition:account_statement') && (
                <TableCell><Skeleton width='100%' /></TableCell>
              )}
            </TableRow>
          ))}
          {(data || previousData)?.fetchAccountStatements?.accountStatements?.map((statement: any) => (
            <TableRow
              hover
              key={statement.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
            >
              <TableCell component="th" scope="row">
                <MuiLink component={Link} to={`/clients/${statement.user.id}`}>{statement.user.firstName} {statement.user.lastName}</MuiLink>
              </TableCell>
              <TableCell component="th" scope="row">
                {statement.user.organization.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {/* {statement.account.type} */}
                <Badge label={statement.account.type} />
              </TableCell>
              <TableCell component="th" scope="row">
                {statement.state}
              </TableCell>
              {permissions.includes('transition:account_statement') && (
                <TableCell align='right'><IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentStatement(statement);
                    handleClick(e);
                  }}
                  disabled={transitions.filter((x: any) => x.from.includes(statement.state)).length === 0}
                ><MoreVertIcon /></IconButton></TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchAccountStatements?.totalCount ?? 0) / pageSize)}
        page={page}
        perPage={pageSize}
        onChangePerPage={setPageSize}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      <Menu
        anchorEl={anchorEl}
        id="statements-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {transitions.map((transition: any) => (
          transition.from.includes(currentStatement.state) && (
            <MenuItem key={transition.name} onClick={async (e) => {
              await transitionStatement({ variables: { input: { accountStatementId: currentStatement.id, transition: transition.name } } });
              refetch();
              statementsRefresh();
            }}>
              {t(`statementsReview:statementTransitions.${transition.name}`)}
            </MenuItem>
          )
        ))}
      </Menu>
    </Card>
  );
};
