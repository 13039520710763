import { useContext, useState } from 'react';
import { CreateModelPortfolioVisual } from './createModelPortfolio.visual';
import { UserContext } from '../../../../../providers/userContextProvider';
import { WorkflowContext } from '../../workflowCompletion';
import { GenericState } from '../../../../5-page/modelPortfolios/modelPortfolios';
import { TranslatedString } from '../../../../../interfaces/shared';

export interface AssetClass {
  id: string;
  key: string;
  name: string;
}

export interface AssetClassBranch {
  id: string;
  targetPercentage: number | undefined;
  assetClass: AssetClass;
  color: 'supportOne' | 'supportTwo' | 'supportThree' | 'supportFour' | 'supportFive' | 'supportSix';
}

export interface PortfolioTemplate {
  id: string;
  assetClassBranches: AssetClassBranch[];
  createdAt: string;
  name: string;
  state: GenericState;
  translatedName: TranslatedString;
  updatedAt: string;
  translatedTemplateDescription: TranslatedString;
}

interface ModelPortfolioData {
  translatedName: {
    en: string;
  };
  organizationId: string;
  creationType?: 'FROM_TEMPLATE' | 'FROM_SCRATCH';
  portfolioTemplate?: PortfolioTemplate;
}

export interface CreateModelPortfolioOptions {
  choosePortfolioType: {
    enabled: boolean;
    required: 'ERROR' | 'NOT_REQUIRED';
    canGoBack: boolean;
    title: TranslatedString;
    subtitle: TranslatedString;
    options: {
      label: TranslatedString;
      description: TranslatedString;
      value: string;
    }[];
  };
  selectTemplate: {
    enabled: boolean;
    required: 'ERROR' | 'NOT_REQUIRED';
    canGoBack: boolean;
    label: TranslatedString;
    description: TranslatedString;
    templateSelector: {
      enabled: boolean;
      required: 'ERROR' | 'NOT_REQUIRED';
      label: TranslatedString;
    };
    assetClassAllocation: {
      enabled: boolean;
      required: 'ERROR' | 'NOT_REQUIRED';
      label: TranslatedString;
    };
  };
  createTemplate: {
    enabled: boolean;
    canGoBack: boolean;
    required: 'ERROR' | 'NOT_REQUIRED';
    label: TranslatedString;
    description: TranslatedString;
    templateName: {
      enabled: boolean;
      required: 'ERROR' | 'NOT_REQUIRED';
      label: TranslatedString;
    };
    templateDescription: {
      enabled: boolean;
      required: 'ERROR' | 'NOT_REQUIRED';
      label: TranslatedString;
    };
    assetClassAllocationEditor: {
      enabled: boolean;
      required: 'ERROR' | 'NOT_REQUIRED';
      label: TranslatedString;
      assetClassAllocation: {
        enabled: boolean;
        required: 'ERROR' | 'NOT_REQUIRED';
        label: TranslatedString;
      };
      targetPercentage: {
        enabled: boolean;
        required: 'ERROR' | 'NOT_REQUIRED';
        label: TranslatedString;
      };
    };
    buttonSaveForLater: {
      enabled: boolean;
      label: TranslatedString;
    };
  };
}

interface CreateModelPortfolioProps {
  options: CreateModelPortfolioOptions;
  userId: string;
  onNext: () => void;
  stepLoading: boolean;
  workflowCompletion?: {
    workflow?: {
      id: string;
      name: string;
    };
    id: string;
    objectId: string;
    objectType: string;
  };
  onBack: () => void;
}

export const CreateModelPortfolio = ({
  options,
  userId,
  onNext,
  stepLoading,
  workflowCompletion,
  onBack,
}: CreateModelPortfolioProps) => {
  const { activeOrganization } = useContext(UserContext);
  const {
    workflowData,
    setWorkflowData,
    updateWorkflowContext,
    navigateToSegment,
  } = useContext(WorkflowContext);

  const [modelPortfolioData, setModelPortfolioData] = useState<ModelPortfolioData>({
    translatedName: {
      en: '',
    },
    organizationId: activeOrganization?.id || '',
    creationType: workflowData.modelPortfolioDetails?.creationType,
    portfolioTemplate: workflowData.modelPortfolioDetails?.portfolioTemplate,
  });

  const handleContinue = async (portfolio: ModelPortfolioData) => {
    try {
      const updatedWorkflowData = {
        ...workflowData,
        modelPortfolioDetails: {
          ...workflowData.modelPortfolioDetails,
          ...portfolio,
        },
      };

      setWorkflowData(updatedWorkflowData);

      if (updateWorkflowContext) {
        await updateWorkflowContext();
      }

      onNext();
    } catch (error) {
      // Error is handled in the parent component
    }
  };

  const handleUpdateModelPortfolio = (updates: Partial<ModelPortfolioData>) => {
    setModelPortfolioData((prev) => {
      const updatedData = {
        ...prev,
        ...updates,
      };

      if (updates.portfolioTemplate) {
        const updatedWorkflowData = {
          ...workflowData,
          modelPortfolioDetails: {
            ...workflowData.modelPortfolioDetails,
            portfolioTemplate: updates.portfolioTemplate,
          },
        };
        setWorkflowData(updatedWorkflowData);
      }

      return updatedData;
    });
  };

  return (
    <CreateModelPortfolioVisual
      options={options}
      modelPortfolioData={modelPortfolioData}
      updateModelPortfolio={handleUpdateModelPortfolio}
      continueFunc={handleContinue}
      loading={stepLoading}
      workflowCompletion={workflowCompletion}
      workflowData={workflowData}
      setWorkflowData={setWorkflowData}
      onBack={onBack}
      navigateToSegment={navigateToSegment || (() => {})}
    />
  );
};
